import React from "react";
import { Div, Button } from "atomize";
import PropTypes from "prop-types";

InlineCheckboxButtons.propTypes = {
    activeBg: PropTypes.string,
    activeTextColor: PropTypes.string,
    elements: PropTypes.array,
    handleClick: PropTypes.func,
    inactiveBg: PropTypes.string,
    p: PropTypes.any,
    selectedElem: PropTypes.any,
    setValueInsteadIndex: PropTypes.bool,
};

InlineCheckboxButtons.defaultProps = {
    inactiveBg: "gray_light",
    activeBg: "gunmetal",
    activeTextColor: "white",
    selectedElem: null,
    elements: [],
    handleClick: null,
    setValueInsteadIndex: false,
    p: null,
};

function InlineCheckboxButtons({
    elements,
    selectedElem,
    inactiveBg,
    activeBg,
    handleClick,
    setValueInsteadIndex,
    activeTextColor,
    p,
}) {
    return (
        <Div d="flex" p={p} m={{ y: "1rem" }} overflow="auto" id="inline_checkbox">
            {elements.map((elem, index) => {
                return (
                    <Button
                        style={{ whiteSpace: "nowrap" }}
                        id={"checkbox_" + index}
                        key={index}
                        p={{ x: "1rem" }}
                        m={{ r: "0.75rem" }}
                        h="3.5rem"
                        bg={selectedElem === index ? activeBg : inactiveBg}
                        rounded="20px"
                        textWeight={selectedElem === index && "bold"}
                        textSize="subheader"
                        textColor={selectedElem === index ? activeTextColor : "text"}
                        onClick={() => selectedElem !== index && handleClick(setValueInsteadIndex ? elem : index)}
                    >
                        {elem}
                    </Button>
                );
            })}
        </Div>
    );
}

export default InlineCheckboxButtons;
