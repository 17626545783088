import { Button, Col, Div, Input, Row, Text, Switch, Label, Tag } from "atomize";
import React, { useContext, useEffect, useState } from "react";
import apiService from "../../services/api.service";
import { toast } from "react-toastify";
import { authContext } from "../../providers/auth.provider";
import { productsContext } from "../../providers/products.provider";
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faLeaf, faPiggyBank, faSeedling } from "@fortawesome/free-solid-svg-icons";
import Paragraph from "../paragraph/Paragraph";
import theme from "../../appearance/theme";
import { addProductImage, checkArchivedProducts, insertprodInternalID } from "../../services/firebase.service";
import { getRemoteValue } from "../../services/func.utils";

function InsertNewProductModal(props) {
    const [formStep, setFormStep] = useState(Object.keys(props.defaultData).length > 0 ? 4 : 0);
    const [prodName, setProdName] = useState(props.defaultData.prodName ?? "");
    const [prodInternalID, setProdInternalID] = useState(props.defaultData.prodInternalID ?? "");
    const [prodCategory, setProdCategory] = useState(
        props.defaultData.prodCategory ?? {
            name: "",
            id: null,
        }
    );
    const [prodPrice, setProdPrice] = useState(props.defaultData.prodPrice ?? "");
    const [prodContent, setProdContent] = useState(props.defaultData.prodContent ?? "");
    const [categoryList, setCategoryList] = useState(null);
    const [fastSell, setFastSell] = useState(true);

    const { setLog, supplierData, setArchivedProducts, archivedProducts } = useContext(authContext);
    const { editFunctions, mergedProdProps, productsEntries, setProductInfoSelected } = useContext(productsContext);
    const { photo, takePhoto, resetPhoto } = usePhotoGallery();

    const categorySupplier =
        categoryList &&
        productsEntries?.categories &&
        categoryList?.filter((category) => productsEntries?.categories?.includes(category.type));
    const categoryNew =
        categoryList &&
        productsEntries?.categories &&
        categoryList?.filter((category) => !productsEntries?.categories?.includes(category.type));

    const handleProdNameChange = (e) => setProdName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    const handleProdInternalIDChange = (e) => setProdInternalID(e.target.value);
    const handleProdPriceChange = (e) => setProdPrice(e.target.value);

    useEffect(() => {
        getCategories();
    }, []);

    const handleInsertNewProduct = async () => {
        if (Object.keys(archivedProducts).includes(prodName.toLowerCase().trim())) {
            editFunctions.editProduct(
                { productName: prodName.trim(), fullPrice: prodPrice, type: prodCategory.id, props: prodContent },
                archivedProducts[prodName.toLowerCase()],
                fastSell,
                prodInternalID
            );
            if (photo) addProductImage(photo?.webviewPath, archivedProducts[prodName.toLowerCase().trim()], true);
            const newArchivedProducts = await checkArchivedProducts(
                {
                    productID: archivedProducts[prodName.toLowerCase().trim()],
                    productName: prodName.trim(),
                    supplierID: supplierData.supplierID,
                },
                "restore"
            );
            setArchivedProducts(newArchivedProducts);
        } else
            editFunctions.insertProduct(
                prodName.trim(),
                prodPrice,
                prodCategory,
                prodContent,
                photo?.webviewPath,
                fastSell,
                prodInternalID
            );
    };

    const productExists = () => {
        let exists = false;
        Object.keys(mergedProdProps.productsDisplayed).forEach((category) => {
            mergedProdProps.productsDisplayed[category].forEach((prod) => {
                if (
                    prod.productName.toLowerCase() === prodName.toLowerCase().trim() &&
                    !Object.keys(archivedProducts).includes(prodName.toLowerCase().trim())
                )
                    exists = true;
                if (
                    prod.productName.toLowerCase() === prodName.toLowerCase().trim() &&
                    Object.keys(archivedProducts).includes(prod.productName.toLowerCase().trim())
                )
                    setProductInfoSelected(prod);
            });
        });
        return exists;
    };

    const getCategories = async () => {
        await apiService.api
            .get("/productTypes/?all=1")
            .then((response) => {
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : setCategoryList(response.data);
            })
            .catch((error) => {
                console.error("[API] Get categories ", error);
                toast.error("Ops, non trovo le categorie dei prodotti, Aggiorno la pagina!", {
                    hideProgressBar: false,
                    onClose: () => window.location.reload(false),
                });
            });
    };

    const prodNameInput = (
        <>
            <Text textSize="subheader" p={{ l: "0.5rem" }}>
                <b>Nome</b> del prodotto:
            </Text>
            <Input
                type="text"
                autoComplete="off"
                name="prod_name"
                placeholder="Inserisci qui il nome"
                value={prodName}
                bg="gray_light"
                rounded="20px"
                h="3.5rem"
                p={{ x: "1rem" }}
                m={{ x: "0.5rem" }}
                border="3px solid"
                borderColor="gray_light"
                focusBorderColor="gunmetal"
                onChange={(e) => handleProdNameChange(e)}
            />
        </>
    );

    const prodInternalIDInput = (
        <Div m={{ t: "0.5rem" }}>
            <Text textSize="subheader" p={{ l: "0.5rem" }}>
                <b>Codice</b> del prodotto:
            </Text>
            <Input
                type="text"
                autoComplete="off"
                name="prod_cod"
                placeholder="Inserisci qui il codice"
                value={prodInternalID}
                bg="gray_light"
                rounded="20px"
                h="3.5rem"
                p={{ x: "1rem" }}
                m={{ x: "0.5rem" }}
                border="3px solid"
                borderColor="gray_light"
                focusBorderColor="gunmetal"
                onChange={(e) => handleProdInternalIDChange(e)}
            />
        </Div>
    );

    const prodCategoryInput = (
        <>
            <Text p={{ x: "0.5rem" }} textSize="subheader">
                <b>Categoria</b> del prodotto:
            </Text>
            <Div maxH="300px" style={{ overflowY: "auto" }}>
                <Div p={{ x: "0.5rem" }}>
                    <Text p={{ b: "0.25rem" }}>Seleziona una categoria recente:</Text>
                    {categorySupplier?.map((category, index) => {
                        return (
                            <Tag
                                style={{ whiteSpace: "nowrap" }}
                                key={index}
                                p={{ x: "0.75rem", y: "0.25rem" }}
                                m={{ r: "0.5rem", y: "0.25rem" }}
                                bg={prodCategory.id === category.productTypeID ? "gunmetal" : "gray_light"}
                                rounded="20px"
                                textWeight={prodCategory.id === category.productTypeID && "bold"}
                                textSize="paragraph"
                                textColor={prodCategory.id === category.productTypeID ? "white" : "black"}
                                onClick={() =>
                                    setProdCategory({
                                        name: category.type,
                                        id: category.productTypeID,
                                    })
                                }
                                transition
                            >
                                {category.type}
                            </Tag>
                        );
                    })}
                </Div>
                <hr />
                <Div p={{ x: "0.5rem" }}>
                    <Text p={{ b: "0.25rem" }}>Oppure una fra queste:</Text>
                    {categoryNew?.map((category, index) => {
                        if (
                            !JSON.parse(getRemoteValue("custom_product_types"))[category.productTypeID] ||
                            JSON.parse(getRemoteValue("custom_product_types"))[category.productTypeID]?.includes(
                                supplierData.supplierID
                            )
                        )
                            return (
                                <Tag
                                    style={{ whiteSpace: "nowrap" }}
                                    key={index}
                                    p={{ x: "0.75rem", y: "0.25rem" }}
                                    m={{ r: "0.5rem", y: "0.25rem" }}
                                    bg={prodCategory.id === category.productTypeID ? "gunmetal" : "gray_light"}
                                    rounded="20px"
                                    textWeight={prodCategory.id === category.productTypeID && "bold"}
                                    textSize="paragraph"
                                    textColor={prodCategory.id === category.productTypeID ? "white" : "black"}
                                    onClick={() =>
                                        setProdCategory({
                                            name: category.type,
                                            id: category.productTypeID,
                                        })
                                    }
                                    transition
                                >
                                    {category.type}
                                </Tag>
                            );
                    })}
                </Div>
            </Div>
        </>
    );

    const prodPriceInput = (
        <>
            <Text textSize="subheader" p={{ l: "0.5rem" }}>
                <b>Prezzo</b> del prodotto:
            </Text>
            <Input
                type="number"
                autoComplete="off"
                min="0"
                step="0.1"
                name="prod_price"
                value={prodPrice}
                onChange={(e) => handleProdPriceChange(e)}
                suffix={
                    <Text tag="span" textSize="subheader" pos="absolute" top="0.75rem" right="0.75rem">
                        €
                    </Text>
                }
                placeholder="Quanto costa di listino?"
                bg="gray_light"
                rounded="20px"
                h="3.5rem"
                p={{ x: "1rem" }}
                m={{ x: "0.5rem" }}
                border="3px solid"
                borderColor="gray_light"
                focusBorderColor="gunmetal"
            />
        </>
    );

    const prodContentInput = (
        <Div p={{ x: "0.5rem" }}>
            <Text textSize="subheader">
                <b>Proprietà</b> del prodotto:
            </Text>
            <Text p={{ b: "0.25rem" }}>Seleziona una proprietà del prodotto:</Text>
            {["Nessuna", "Contiene maiale", "Vegetariano", "Vegano"].map((prop, index) => {
                return (
                    <Tag
                        style={{ whiteSpace: "nowrap" }}
                        key={index}
                        p={{ x: "0.75rem", y: "0.25rem" }}
                        m={{ r: "0.5rem", y: "0.25rem" }}
                        bg={prodContent === index ? "gunmetal" : "gray_light"}
                        rounded="20px"
                        textWeight={prodContent === index && "bold"}
                        textSize="paragraph"
                        textColor={prodContent === index ? "white" : "black"}
                        onClick={() => setProdContent(index)}
                        transition
                    >
                        <>
                            <Text m={{ r: "0.25rem" }}>{prop}</Text>
                            {index > 0 && (
                                <FontAwesomeIcon
                                    icon={index === 1 ? faPiggyBank : index === 2 ? faLeaf : index === 3 && faSeedling}
                                    size="1x"
                                    color={prodContent === index ? "gunmetal" : "gray_light"}
                                />
                            )}
                        </>
                    </Tag>
                );
            })}
        </Div>
    );

    const prodSummary = (
        <>
            <Div m={{ x: "0.5rem" }}>
                <Text m={{ b: "0.5rem" }} textWeight="bold" textColor="gunmetal" textSize="title">
                    {prodName}
                </Text>
                <hr style={{ border: "1px solid #E1E4E8", marginTop: "0.5rem" }} />
                <Text textSize="subheader">
                    <Text tag="span" textColor="text_light">
                        Codice prodotto:{" "}
                    </Text>
                    <Text tag="span" textWeight="bold" textColor="gunmetal">
                        {prodInternalID || "-"}
                    </Text>
                </Text>
                <Text textSize="subheader">
                    <Text tag="span" textColor="text_light">
                        Prezzo di listino:{" "}
                    </Text>
                    <Text tag="span" textWeight="bold" textColor="gunmetal">
                        {prodPrice} €
                    </Text>
                </Text>
                {prodCategory && (
                    <Text textSize="subheader">
                        <Text tag="span" textColor="text_light">
                            Categoria:{" "}
                        </Text>
                        <Text tag="span" textWeight="bold" textColor="gunmetal">
                            {prodCategory.name}
                        </Text>
                    </Text>
                )}
                <Text textSize="subheader">
                    <Text tag="span" textColor="text_light">
                        Proprietà:{" "}
                    </Text>
                    <Text tag="span" textWeight="bold" textColor="gunmetal">
                        {prodContent === 0 && "Nessuna"}
                        {prodContent === 1 && "Contiene maiale"}
                        {prodContent === 2 && "Vegetariano"}
                        {prodContent === 3 && "Vegano"}
                    </Text>
                </Text>
                <Label
                    onClick={() => setFastSell(!fastSell)}
                    display="flex"
                    justify="space-between"
                    textSize="subheader"
                    align="center"
                    textWeight="600"
                    m={{ t: "1rem" }}
                >
                    Vendilo subito
                    <Switch shadow="0" checked={fastSell} activeColor="primary" />
                </Label>
                <hr style={{ border: "1px solid #E1E4E8", marginTop: "0.5rem" }} />
                <Paragraph
                    title={
                        <>
                            <Text tag="span" textWeight="normal">
                                Vuoi inserire una{" "}
                            </Text>
                            foto
                            <Text tag="span" textWeight="normal">
                                ?
                            </Text>
                        </>
                    }
                >
                    <Text textColor={theme.colors.text_light} p={{ b: "0.5rem" }}>
                        Sarà più facile vendere il prodotto
                    </Text>
                    <Div
                        pos="relative"
                        bg={!photo && "gunmetal"}
                        bgImg={photo && `'${photo.webviewPath}'`}
                        bgSize={photo && "cover"}
                        bgPos={photo && "center"}
                        m={{ x: "auto", b: "0.5rem" }}
                        d="flex"
                        align="center"
                        justify="center"
                        w="7rem"
                        h="7rem"
                        rounded="25px"
                        p="0.5rem"
                        textColor="white"
                        textAlign="center"
                        onClick={() => takePhoto()}
                    >
                        {!photo ? (
                            <Div>
                                <Text>
                                    <FontAwesomeIcon icon={faCamera} color="white" size="2x" />
                                </Text>
                                Inserisci
                            </Div>
                        ) : (
                            <>
                                <Div
                                    pos="absolute"
                                    bg={theme.colors.primary_light}
                                    left="0"
                                    top="0"
                                    p={{ x: "0.75rem" }}
                                    rounded={theme.rounded["pillAltRev-20px"]}
                                    style={{ backdropFilter: "blur(10px)", whiteSpace: "nowrap" }}
                                    textColor="white"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        resetPhoto();
                                    }}
                                >
                                    Rimuovi
                                </Div>
                                <Div
                                    pos="absolute"
                                    bg={theme.colors.gunmetal}
                                    right="0"
                                    bottom="0"
                                    p={{ x: "0.75rem" }}
                                    rounded={theme.rounded["pillAlt-20px"]}
                                    style={{ backdropFilter: "blur(10px)", whiteSpace: "nowrap" }}
                                    textColor="white"
                                >
                                    Cambia
                                </Div>
                            </>
                        )}
                    </Div>
                </Paragraph>
            </Div>
        </>
    );

    const inputIsValid = (step) => {
        var isValid = false;
        switch (step) {
            case 0:
                if (prodName.trim() !== "")
                    !productExists()
                        ? (isValid = true)
                        : toast.warning("Il prodotto esiste già!", { position: "top-center" });
                //document.getElementsByName("prod_name")[0].focus();
                break;
            case 1:
                prodCategory && prodCategory.name !== "" && (isValid = true);
                break;
            case 2:
                prodPrice > 0 && (isValid = true);
                //document.getElementsByName("prod_price")[0].focus();
                break;
            case 3:
                prodContent !== "" && (isValid = true);
                break;
            default:
                isValid = true;
                break;
        }
        return isValid;
    };

    return (
        <>
            <Div p={{ x: "1rem", t: "0.5rem" }}>
                <Text p={{ l: "0.5rem" }} textSize="title" textWeight="bold">
                    Nuovo prodotto
                </Text>
                <Div m="auto" p={{ t: "1rem" }}>
                    {formStep === 0 ? (
                        <Div>
                            {prodNameInput}
                            {supplierData?.firestore?.needsProdInternalID && prodInternalIDInput}
                        </Div>
                    ) : formStep === 1 ? (
                        prodCategoryInput
                    ) : formStep === 2 ? (
                        prodPriceInput
                    ) : formStep === 3 ? (
                        prodContentInput
                    ) : (
                        prodSummary
                    )}
                    <Row d="flex" justify="center" p={{ t: "0.5rem" }}>
                        <Div
                            w="0.75rem"
                            h="0.75rem"
                            transition
                            bg={formStep === 0 ? "primary" : "white"}
                            border="2px solid"
                            borderColor={formStep === 0 ? "primary" : "gray500"}
                            m="0.5rem 0.25rem"
                            rounded="circle"
                        />
                        <Div
                            w="0.75rem"
                            h="0.75rem"
                            transition
                            bg={formStep === 1 ? "primary" : "white"}
                            border="2px solid"
                            borderColor={formStep === 1 ? "primary" : "gray500"}
                            m="0.5rem 0.25rem"
                            rounded="circle"
                        />
                        <Div
                            w="0.75rem"
                            h="0.75rem"
                            transition
                            bg={formStep === 2 ? "primary" : "white"}
                            border="2px solid"
                            borderColor={formStep === 2 ? "primary" : "gray500"}
                            m="0.5rem 0.25rem"
                            rounded="circle"
                        />
                        <Div
                            w="0.75rem"
                            h="0.75rem"
                            transition
                            bg={formStep === 3 ? "primary" : "white"}
                            border="2px solid"
                            borderColor={formStep === 3 ? "primary" : "gray500"}
                            m="0.5rem 0.25rem"
                            rounded="circle"
                        />
                        <Div
                            w="0.75rem"
                            h="0.75rem"
                            transition
                            bg={formStep === 4 ? "primary" : "white"}
                            border="2px solid"
                            borderColor={formStep === 4 ? "primary" : "gray500"}
                            m="0.5rem 0.25rem"
                            rounded="circle"
                        />
                    </Row>
                </Div>
            </Div>
            <Row m={{ x: "2rem" }}>
                <Col p="0">
                    <Button
                        onClick={() => {
                            formStep > 0 ? setFormStep(formStep - 1) : props.closeModal();
                        }}
                        h="3.5rem"
                        m="auto"
                        bg="white"
                        textColor="gunmetal"
                    >
                        <Text textSize="title">{formStep === 0 ? "Annulla" : "Indietro"}</Text>
                    </Button>
                </Col>
                <Col p="0">
                    <Button
                        onClick={() =>
                            formStep === 4
                                ? handleInsertNewProduct()
                                : inputIsValid(formStep) && setFormStep(formStep + 1)
                        }
                        bg="gunmetal"
                        shadow="3"
                        h="3.5rem"
                        p={{ x: "1.5rem" }}
                        disabled={!inputIsValid(formStep)}
                        rounded="20px"
                        textColor="white"
                        m="auto"
                    >
                        <Text textSize="title">{formStep === 4 ? "CONFERMA" : "AVANTI"}</Text>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default InsertNewProductModal;
