import React from "react"
import { Div } from "atomize"

function Li({ children }) {
    return (
        <Div p={{ b: "0.5rem" }}>
            <li>{children}</li>
        </Div>
    )
}

export default Li
