import React from "react";
import OrdersContainer from "../ordersContainer/OrdersContainer";

function OrdersTab(props) {
    return (
        <>
            <OrdersContainer />
        </>
    );
}

export default OrdersTab;
