import React, { useContext, useState } from "react";
import { Container, Div, Text } from "atomize";
import Product from "../product/Product";
import ProductsCategories from "../productsCategories/ProductsCategories";
import PendingProductsBanner from "../pendingProductsBanner/PendingProductsBanner";
import DateGroupSeparator from "../dateGroupSeparator/DateGroupSeparator";
import FabContainer from "../fabContainer/FabContainer";
import { motion } from "framer-motion";
import { authContext } from "../../providers/auth.provider";
import { productsContext } from "../../providers/products.provider";

function Products(props) {
    const [dragPosition, setDragPosition] = useState(0);

    const { archivedProducts } = useContext(authContext);
    const { mergedProdProps, handleProductsTypeChange } = useContext(productsContext);

    const checkDragPosition = (info) => {
        if (!props.productsType) info.point.x + 90 < dragPosition && handleProductsTypeChange(true);
        else info.point.x - 90 > dragPosition && handleProductsTypeChange(false);
    };
    const filteredFresh = Object.keys(mergedProdProps.online).reduce(
        (acc, y) => ((acc = acc.concat(mergedProdProps.online[y].filter((x) => x.isFresh && x.available > 0))), acc),
        []
    );

    return (
        <>
            <FabContainer productsType={props.productsType} searching={props.searching} pProducts={props.pProducts} />
            <Div d="block" w="100vw">
                <Container>
                    {/*<InfoDiv
                        type="2.1"
                        infoTitle="Ci sono novità! ⚡️"
                        infoBody={
                            <>
                                <Text>
                                    Da ora è possibile <EmphasizedText>modificare i prodotti</EmphasizedText> che hai
                                    creato 😳
                                </Text>
                                <Text>
                                    Basterà toccarne uno e poi, premendo su "Modifica prodotto", potrai modificare ogni
                                    sua caratteristica, anche la foto!
                                </Text>
                            </>
                        }
                        infoAction={<></>}
                    />*/}
                </Container>
                <motion.div
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    onDragStart={(e, info) => setDragPosition(info.point.x)}
                    onDragEnd={(e, info) => info.point.x !== 0 && checkDragPosition(info)}
                >
                    {mergedProdProps.productsDisplayed &&
                        !props.productsType &&
                        mergedProdProps.categories?.map(
                            (category, index) =>
                                category in mergedProdProps.productsDisplayed && (
                                    <Div key={"anchor-" + index} id={"anchor-" + index}>
                                        <Container>
                                            <Text textWeight="bold" textSize="title">
                                                {category.toUpperCase()}
                                            </Text>
                                            {mergedProdProps?.productsDisplayed[category].map(
                                                (value) =>
                                                    category === value.type &&
                                                    !Object.keys(archivedProducts).includes(
                                                        value.productName.toLowerCase()
                                                    ) && (
                                                        <Product
                                                            key={value.productID}
                                                            productInfo={value}
                                                            productsType={props.productsType}
                                                        />
                                                    )
                                            )}
                                        </Container>
                                    </Div>
                                )
                        )}
                    {Object.keys(mergedProdProps.productsDisplayed).length === 0 && !props.productsType && (
                        <Div textAlign="center" m={{ t: "50%" }}>
                            <Container>
                                <Text textSize="heading" textColor="primary">
                                    Tocca sul bottone + per aggiungere dei prodotti!
                                </Text>
                            </Container>
                        </Div>
                    )}
                </motion.div>

                <motion.div
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    onDragStart={(e, info) => setDragPosition(info.point.x)}
                    onDragEnd={(e, info) => info.point.x !== 0 && checkDragPosition(info)}
                >
                    {mergedProdProps.online &&
                        Object.keys(mergedProdProps.online).length > 0 &&
                        mergedProdProps.expiration &&
                        mergedProdProps.categories &&
                        props.productsType &&
                        mergedProdProps.expiration.map((expiration, index) => (
                            <Div key={"anchor-" + index} id={"anchor-" + expiration}>
                                <DateGroupSeparator dateType="products" date={new Date(expiration)} />
                                <Container>
                                    {mergedProdProps.categories.map(
                                        (category, index) =>
                                            category in mergedProdProps.productsDisplayed &&
                                            mergedProdProps.productsDisplayed[category].map(
                                                (product) =>
                                                    product.surplus &&
                                                    expiration === product.surplus.timestamp &&
                                                    product.surplus.quantity > 0 && (
                                                        <Product
                                                            key={`prod-${product.productID}`}
                                                            productInfo={product}
                                                            productsType={props.productsType}
                                                        />
                                                    )
                                            )
                                    )}
                                </Container>
                            </Div>
                        ))}
                    {mergedProdProps.productsDisplayed &&
                        mergedProdProps.expiration &&
                        Object.keys(mergedProdProps.expiration).length === 0 &&
                        filteredFresh.length === 0 &&
                        props.productsType && (
                            <Div textAlign="center" m={{ t: "50%" }}>
                                <Container>
                                    <Text textSize="heading" textColor="primary">
                                        Non ci sono prodotti in vendita al momento!
                                    </Text>
                                </Container>
                            </Div>
                        )}
                </motion.div>
            </Div>
            {Object.keys(mergedProdProps?.unconfirmed).length > 0 && <PendingProductsBanner />}
            {!props.productsType && mergedProdProps.categories && (
                <ProductsCategories
                    searching={props.searching}
                    pProducts={Object.keys(mergedProdProps.unconfirmed).length > 0}
                    categories={mergedProdProps.categories}
                />
            )}
        </>
    );
}

export default Products;
