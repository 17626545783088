import { Div, Button } from "atomize";
import React, { useContext } from "react";
import { motion } from "framer-motion";
import theme from "../../appearance/theme";
import { modalContext } from "../../providers/modal.provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { productsContext } from "../../providers/products.provider";

function FabContainer(props) {
    const Modal = useContext(modalContext);
    const { handleSearchButtonClick } = useContext(productsContext);

    return (
        <>
            <Div
                pos="fixed"
                transition
                bottom={
                    props.pProducts
                        ? !props.productsType
                            ? "calc(env(safe-area-inset-bottom) + 12.75rem)"
                            : "calc(env(safe-area-inset-bottom) + 9.5rem)"
                        : !props.productsType
                        ? "calc(env(safe-area-inset-bottom) + 8.5rem)"
                        : "calc(env(safe-area-inset-bottom) + 5rem)"
                }
                right="0"
                zIndex="1"
                p={{ r: "1rem" }}
            >
                <Button
                    h="3.25rem"
                    w="3.25rem"
                    bg="white"
                    rounded="20px"
                    transition
                    style={{
                        transform: props.searching ? "scale(0)" : "scale(1)",
                    }}
                    shadow="3"
                    hoverShadow="4"
                    onClick={() => {
                        handleSearchButtonClick();
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                        document.getElementsByName("productsSearch")[0].focus();
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} size="2x" color={theme.colors.gunmetal} />
                </Button>
                <motion.div whileTap={{ scale: 0.9 }}>
                    <Button
                        h="3.25rem"
                        w="3.25rem"
                        bg="gunmetal"
                        m={{ t: "0.5rem" }}
                        rounded="20px"
                        transition
                        shadow="3"
                        hoverShadow="4"
                        onClick={() => Modal.open("insertNewProd", {})}
                    >
                        <FontAwesomeIcon icon={faPlus} size="2x" color="white" />
                    </Button>
                </motion.div>
            </Div>
        </>
    );
}

export default FabContainer;
