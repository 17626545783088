import apiService from "./api.service";

/*const config = {
    headers: { 'content-type': 'multipart/form-data' }
}*/

const register = (username, password) => {
    let formData = new FormData(); //formdata object
    formData.append("email", username); //append the values with key, value pair
    formData.append("password", password);

    return apiService.api.post("/register", formData /*, config*/);
    /*.then(response => {
            if (response.data) {
                console.log("registrato e loggato");
            }
            return response;
        });*/
};

const login = (username, password) => {
    let formData = new FormData(); //formdata object
    formData.append("email", username); //append the values with key, value pair
    formData.append("password", password);
    //console.log("url",config.ApiUrl+"/login/");
    return apiService.api.post("/login/", formData /*, config*/);
    /*.then((response) => {
            if (response.data) {
                console.log("loggato");
            }

            return response;
        });
    /*return apiService.axios.request({
        url:config.ApiUrl+"/login/",
        method: 'post',
        data: formData
    })*/
};

const logout = () => {
    return apiService.api.get("/logout");
    /*.then((response) => {
            console.log(response);
            return response;
        });*/
};

const checkRedirectLogin = (responseURL) =>
    apiService.checkRedirectLogin(responseURL);

export default {
    register,
    login,
    logout,
    checkRedirectLogin,
};
