import PropTypes from "prop-types";
import React, { memo } from "react";
import { Button } from "atomize";
import theme from "../../appearance/theme";

SecondaryButton.propTypes = {
    action: PropTypes.func,
    disabled: PropTypes.bool,
    h: PropTypes.string,
    m: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    textColor: PropTypes.string,
    textSize: PropTypes.string,
    textWeight: PropTypes.string,
};

SecondaryButton.defaultProps = {
    m: "auto",
    h: "3.5rem",
    text: null,
    textSize: "body",
    textColor: "white",
    action: null,
    textWeight: null,
    disabled: false,
};

function SecondaryButton({ m, h, textSize, textColor, textWeight, action, text, disabled }) {
    return (
        <Button
            className="btn-secondary"
            m={m}
            p="0"
            h={h}
            bg="transparent"
            w="fit-content"
            textSize={textSize}
            textColor={textColor}
            textWeight={textWeight}
            onClick={action}
            disabled={disabled}
        >
            {text}
        </Button>
    );
}

export default memo(SecondaryButton);
