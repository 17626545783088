import React from "react"
import { Button, Div, Text } from "atomize"

function ToastWithAction({ action, text, actionText }) {
    return (
        <Div d="flex" align="center" justify="space-between">
            <Text>{text}</Text>
            <Button textWeight="bold" bg="background_alt" textColor="text" onClick={action} rounded="15px">
                {actionText}
            </Button>
        </Div>
    )
}

export default ToastWithAction
