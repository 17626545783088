import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./appearance/slider.css";
import "./appearance/datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeProvider, StyleReset } from "atomize";
import App from "./components/app/App";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import * as serviceWorker from "./serviceWorker";
import config from "./settings/config";
import theme from "./appearance/theme";
import ModalProvider from "./providers/modal.provider";
import AuthProvider from "./providers/auth.provider";
import ProductsProvider from "./providers/products.provider";
import OrdersProvider from "./providers/orders.provider";

const debug = process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

// 1. Create a client engine instance
const engine = new Styletron();

document.body.style.backgroundColor = theme.colors.primary_ulight;

// 2. Provide the engine to the app
ReactDOM.render(
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
        <ThemeProvider theme={theme}>
            <StyleReset />
            <ModalProvider>
                <AuthProvider>
                    <ProductsProvider>
                        <OrdersProvider>
                            <App />
                        </OrdersProvider>
                    </ProductsProvider>
                </AuthProvider>
            </ModalProvider>
        </ThemeProvider>
    </StyletronProvider>,
    document.getElementById("root")
);

let manifestUrl;
config.isDeploy ? (manifestUrl = "/manifest.json") : (manifestUrl = "/manifestDev.json");
config.type === "pwa" && document.querySelector("#my-manifest-placeholder").setAttribute("href", manifestUrl);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//navigator.serviceWorker.register('/OneSignalSDKWorker.js');
config.type === "pwa" && serviceWorker.register();
