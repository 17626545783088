import { Div, Text, Row, Col, Button, Modal } from "atomize";
import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { authContext } from "../../providers/auth.provider";
import { modalContext } from "../../providers/modal.provider";
import { productsContext } from "../../providers/products.provider";
import { archiveProduct } from "../../services/firebase.service";
import BoldText from "../boldText/BoldText";
import { ProductImage } from "../product/Product";

function ArchiveProdModal(props) {
    const { setArchivedProducts } = useContext(authContext);
    const { productInfoSelected } = useContext(productsContext);
    const Modal = useContext(modalContext);

    const handleArchiveProducts = async () => {
        const archivedProducts = await archiveProduct(productInfoSelected);
        setArchivedProducts(archivedProducts);
        props.closeModal();
        toast.success("Prodotto eliminato!", { position: "top-center" });
    };

    return (
        <>
            <Div p={{ x: "1rem", t: "0.5rem" }}>
                <Div d="flex" align="center" p={{ b: "0.25rem" }}>
                    <ProductImage productInfo={productInfoSelected} />
                    <Div style={{ flexBasis: "80%" }}>
                        <Text textSize="subheader" textColor="dark" style={{ lineHeight: "25px" }}>
                            {productInfoSelected.productName}
                        </Text>
                        {"fullPrice" in productInfoSelected && (
                            <NumberFormat
                                value={productInfoSelected.fullPrice}
                                displayType={"text"}
                                decimalSeparator={","}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"€"}
                                renderText={(fullPrice) => (
                                    <NumberFormat
                                        value={productInfoSelected.price}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={"€"}
                                        renderText={(price) => (
                                            <Text textSize="subheader">
                                                <Text
                                                    tag="span"
                                                    textColor={
                                                        productInfoSelected.status === undefined ||
                                                        !(
                                                            "fresh" in productInfoSelected &&
                                                            productInfoSelected.fresh.available > 0
                                                        )
                                                            ? "gray"
                                                            : "accent"
                                                    }
                                                >
                                                    {fullPrice}
                                                </Text>
                                                {(productInfoSelected.surplus || productInfoSelected.unconfirmed) &&
                                                    !props.type &&
                                                    " | "}
                                                <Text tag="span" textColor="primary">
                                                    {(productInfoSelected.surplus || productInfoSelected.unconfirmed) &&
                                                        !props.type &&
                                                        price}
                                                </Text>
                                            </Text>
                                        )}
                                    />
                                )}
                            />
                        )}
                    </Div>
                </Div>
            </Div>
            <hr style={{ border: "1px solid #E1E4E8" }} />
            <Div p={{ x: "1rem", t: "0.5rem" }}>
                <Div d="flex" textAlign="center">
                    <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
                        Sei sicuro di voler <BoldText textColor="danger900">eliminare</BoldText> questo prodotto?
                    </Text>
                </Div>
            </Div>
            <Row m={{ t: "1rem", x: "2rem" }}>
                <Col p="0">
                    <Button onClick={() => Modal.open("prodQuantity")} bg="white" h="3.5rem" textColor="gunmetal">
                        <Text textSize="title">Annulla</Text>
                    </Button>
                </Col>
                <Col size="6">
                    <Button
                        onClick={handleArchiveProducts}
                        bg="danger900"
                        shadow="3"
                        w="100%"
                        h="3.5rem"
                        p={{ x: "1.5rem" }}
                        rounded="20px"
                        textColor="white"
                    >
                        <Text textSize="title">Sicuro!</Text>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default ArchiveProdModal;
