import PropTypes from "prop-types";
import React, { useState } from "react";
import { Input, Button, Icon } from "atomize";
import theme from "../../appearance/theme";
import { timeIsValid } from "../../services/func.utils";
import moment from "moment/moment";

TimeInput.propTypes = {
    handleChange: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
};

TimeInput.defaultProps = {
    placeholder: "",
    value: "",
    handleChange: null,
    type: "text",
};

function TimeInput({
    placeholder,
    handleChange,
    handlePaste,
    value,
    type,
    disabled,
    submitAction,
    textTransform,
    isMinValue,
    ...others
}) {
    const adjustInput = () => {
        if (value) {
            let min = others?.min?.split(":");
            let max = others?.max?.split(":");
            let time = value?.split(":");
            min = moment().set({ hour: min[0], minute: min[1] });
            max = moment().set({ hour: max[0], minute: max[1] });
            time = moment().set({ hour: time[0], minute: time[1] });
            if (!moment(time).isBetween(moment(min), moment(max))) handleChange(isMinValue ? others?.min : others?.max);
        } else handleChange(isMinValue ? others?.min : others?.max);
    };

    return (
        <Input
            bg="gray_light"
            m={{ b: "0.5rem", x: "0.5rem" }}
            value={value}
            rounded="20px"
            type="time"
            border="3px solid"
            borderColor={theme.colors.gray_light}
            focusBorderColor={theme.colors.gunmetal}
            textColor={disabled ? theme.colors.text_light : theme.colors.dark}
            textTransform={textTransform}
            p={{ x: "1rem" }}
            h="3.5rem"
            disabled={disabled}
            placeholder={placeholder}
            onBlur={() => adjustInput(value, type)}
            onChange={(e) => {
                handleChange(e.target.value);
            }}
            onKeyPress={(e) => e.key === "Enter" && submitAction && submitAction()}
            {...others}
        />
    );
}

export default TimeInput;
