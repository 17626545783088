import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Div, Text, Row, Col } from "atomize";
import ProductTags from "../productTags/ProductTags";
import moment from "moment";
import { motion } from "framer-motion";
import { modalContext } from "../../providers/modal.provider";
import { productsContext } from "../../providers/products.provider";
import { getProductImageFromFirebase } from "../../services/firebase.service";
import { faLeaf, faPiggyBank, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDate, getImage } from "../../services/func.utils";

export const ProductImage = ({ productInfo }) => {
    const [productImage, setProductImage] = useState(null);

    useEffect(() => {
        getImage("products", productInfo?.productID, setProductImage);
        /*productInfo.productID &&
            getProductImageFromFirebase(productInfo.productID)
                .then((productImage) => productImage && setProductImage(productImage))
                .catch((error) => console.warn(`Error get product ${productInfo?.productID} image: `, error));*/
    }, []);

    let icon = null;
    let text = "";
    if (productInfo?.props?.[0] || productInfo?.prop?.[0]) {
        icon = faPiggyBank;
        text = "Contiene maiale";
    } else if (productInfo?.props?.[1] || productInfo?.prop?.[1]) {
        icon = faLeaf;
        text = "Vegetariano";
    } else if (productInfo?.props?.[2] || productInfo?.prop?.[2]) {
        icon = faSeedling;
        text = "Vegano";
    }

    return (
        <Div
            pos="relative"
            w="3rem"
            h="3rem"
            bg={!productImage && "gunmetal"}
            bgImg={productImage && `'${productImage}'`}
            bgSize={productImage && "cover"}
            bgPos={productImage && "center"}
            rounded="15px"
            m={{ r: "0.5rem" }}
        >
            {icon && (
                <Div
                    pos="absolute"
                    bg="light"
                    left="0"
                    bottom="0"
                    p={{ x: "0.5rem" }}
                    rounded="pill-15px"
                    style={{ backdropFilter: "blur(10px)", whiteSpace: "nowrap" }}
                >
                    <FontAwesomeIcon icon={icon} size="1x" color="dark" />
                </Div>
            )}
        </Div>
    );
};

function Product(props) {
    const Modal = useContext(modalContext);
    const { setProductInfoSelected, productInfoSelected, productsEntries } = useContext(productsContext);

    return (
        <motion.div
            whileTap={{ scale: 0.98 }}
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 0.5 }}
        >
            <Div
                p={{ l: "0rem", b: "0.2rem" }}
                zIndex="0"
                pos="relative"
                onClick={() => {
                    setProductInfoSelected(props.productInfo);
                    Modal.open("prodQuantity");
                }}
            >
                <Div d="flex" align="center" p={{ b: "0.25rem" }}>
                    {Modal.type === "editProd" && productInfoSelected?.productID === props.productInfo?.productID && (
                        <ProductImage productInfo={props.productInfo} />
                    )}
                    {!(
                        Modal.type === "editProd" && productInfoSelected?.productID === props.productInfo?.productID
                    ) && <ProductImage productInfo={props.productInfo} />}
                    <Div style={{ flexBasis: "85%" }}>
                        <Text textSize="subheader" textColor="dark" style={{ lineHeight: "25px" }}>
                            {props.productInfo.productName}
                        </Text>
                        <Div d="flex" align="center" justify="space-between">
                            <Div>
                                {"fullPrice" in props.productInfo && (
                                    <NumberFormat
                                        value={props.productInfo.fullPrice}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={"€"}
                                        renderText={(fullPrice) => (
                                            <NumberFormat
                                                value={
                                                    props.productInfo?.surplus?.price ||
                                                    props.productInfo?.unconfirmed?.price
                                                }
                                                displayType={"text"}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                prefix={"€"}
                                                renderText={(price) => (
                                                    <Text textSize="subheader">
                                                        <Text
                                                            tag="span"
                                                            textColor={
                                                                props.productInfo.status === undefined ||
                                                                !(
                                                                    "fresh" in props.productInfo &&
                                                                    props.productInfo.fresh.available > 0
                                                                )
                                                                    ? "gray"
                                                                    : "accent"
                                                            }
                                                        >
                                                            {fullPrice}
                                                        </Text>
                                                        {(props.productInfo?.surplus?.quantity > 0 ||
                                                            props.productInfo.unconfirmed) &&
                                                            !props.type &&
                                                            " | "}
                                                        <Text
                                                            tag="span"
                                                            textColor={
                                                                props.productInfo?.surplus?.soldAt <= 0.5 ||
                                                                props.productInfo?.unconfirmed?.soldAt <= 0.5
                                                                    ? "primary"
                                                                    : "accent"
                                                            }
                                                        >
                                                            {(props.productInfo?.surplus?.quantity > 0 ||
                                                                props.productInfo.unconfirmed) &&
                                                                !props.type &&
                                                                price}
                                                        </Text>
                                                    </Text>
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </Div>
                            {productsEntries?.internalID[props.productInfo.productID] && (
                                <Text
                                    textSize="paragraph"
                                    textColor="dark"
                                    bg="gunmetal_light"
                                    rounded="circle"
                                    p={{ x: "0.5rem" }}
                                    textAlign="right"
                                >
                                    {productsEntries?.internalID[props.productInfo.productID]}
                                </Text>
                            )}
                        </Div>
                    </Div>
                </Div>
                <Row>
                    <Col size="7">
                        <ProductTags key={"button-" + props.productInfo.productID} productInfo={props.productInfo} />
                    </Col>
                    {!props.productsType && props.productInfo.surplus?.quantity > 0 && (
                        <Col>
                            <Text
                                textSize="paragraph"
                                textColor="light"
                                justify="flex-end"
                                align="center"
                                d="flex"
                                p={{ t: "0.5rem" }}
                            >
                                {getDate(props.productInfo.surplus.timestamp)}
                            </Text>
                        </Col>
                    )}
                </Row>
                <hr style={{ border: "1px solid #E1E4E8" }} />
            </Div>
        </motion.div>
    );
}

export default Product;
