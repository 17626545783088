import React, { useContext, useState } from "react";
import { Div, Text, Row, Col, Button } from "atomize";
import { toast } from "react-toastify";
import apiService from "../../services/api.service";
import config from "../../settings/config";
import { ordersContext } from "../../providers/orders.provider";
import { motion } from "framer-motion";
import { authContext } from "../../providers/auth.provider";
import { productsContext } from "../../providers/products.provider";

const deleteOrderAPI = async (orderID, deleteReason, deleteOrderFromState, closeModal, goToOnlineProducts) => {
    let reason = "OTHER";
    deleteReason <= 1 ? (reason = "PRODUCTS_NOT_AVAILABLE") : (reason = "SUPPLIER_OFFLINE");
    await apiService.api
        .request({
            url: config.ApiUrl + "/orders/" + orderID,
            params: {
                reason: reason,
            },
            method: "delete",
        })
        .then((response) => {
            deleteOrderFromState(orderID, deleteReason);
            toast.success(`Ordine annullato, controlla i prodotti in vendita!`);
            goToOnlineProducts();
            closeModal();
        })
        .catch((error) => {
            console.error("[API] Delete order ", error);
            toast.error("Ops, ordine non annullato, riprova!");
        });
};

function DeleteOrderModal(props) {
    const [deleteReason, setDeleteReason] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [orderProblemStep, setOrderProblemStep] = useState(0);
    const { deleteOrderFromState, selectedOrder, editOrdersFunctions } = useContext(ordersContext);
    const { setTab } = useContext(authContext);
    const { handleProductsTypeChange } = useContext(productsContext);

    return (
        <>
            <Div p={{ x: "1rem", t: "0.5rem" }}>
                <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="title" textWeight="bold">
                    {orderProblemStep === 0
                        ? `Problemi con l'ordine #${selectedOrder}?`
                        : `Annulliamo l'ordine #${selectedOrder}?`}
                </Text>

                <Div p={{ t: "0.5rem", l: "0.5rem" }}>
                    {orderProblemStep === 0 && (
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0 }}
                        >
                            <Text textSize="subheader" p={{ b: "0.5rem" }}>
                                Che tipo di problema c'è?
                            </Text>
                            <Button
                                onClick={() => {
                                    editOrdersFunctions.sendMessageOrder(selectedOrder, true);
                                    props.closeModal();
                                }}
                                bg="gunmetal"
                                shadow="3"
                                h="auto"
                                minH="3.5rem"
                                p={{ x: "1.5rem" }}
                                rounded="20px"
                                textColor="white"
                                m={{ x: "auto", y: "0.25rem" }}
                            >
                                <Text textSize="title">Ho finito alcuni prodotti</Text>
                            </Button>
                            <Button
                                onClick={() => {
                                    setOrderProblemStep(1);
                                    setDeleteReason(0);
                                }}
                                bg="gunmetal"
                                shadow="3"
                                h="auto"
                                minH="3.5rem"
                                p={{ x: "1.5rem" }}
                                rounded="20px"
                                textColor="white"
                                m={{ x: "auto", y: "0.25rem" }}
                            >
                                <Text textSize="title">Ho finito tutti i prodotti</Text>
                            </Button>
                            <Button
                                onClick={() => {
                                    setOrderProblemStep(1);
                                    setDeleteReason(2);
                                }}
                                bg="gunmetal"
                                shadow="3"
                                h="auto"
                                minH="3.5rem"
                                p={{ x: "1.5rem" }}
                                rounded="20px"
                                textColor="white"
                                m={{ x: "auto", y: "0.25rem" }}
                            >
                                <Text textSize="title">Il locale è chiuso</Text>
                            </Button>
                            <Button
                                onClick={() => {
                                    editOrdersFunctions.sendMessageOrder(selectedOrder, true);
                                    props.closeModal();
                                }}
                                bg="gunmetal"
                                shadow="3"
                                h="auto"
                                minH="3.5rem"
                                p={{ x: "1.5rem" }}
                                rounded="20px"
                                textColor="white"
                                m={{ x: "auto", y: "0.25rem" }}
                            >
                                <Text textSize="title">Altro</Text>
                            </Button>
                        </motion.div>
                    )}
                    {orderProblemStep === 1 && (
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0 }}
                        >
                            <Text textSize="subheader">Ok, annulliamo l'ordine, va bene?</Text>
                            <Text
                                p={{ y: "1rem" }}
                                textAlign="center"
                                textWeight="bold"
                                textColor="danger800"
                                textSize="subheader"
                            >
                                Il cliente ha già pagato e verrà rimborsato!
                            </Text>
                        </motion.div>
                    )}
                </Div>
            </Div>
            <Row m={{ x: "2rem" }}>
                <Col p="0">
                    <Button
                        onClick={
                            orderProblemStep
                                ? () => {
                                      setOrderProblemStep(0) && setDeleteReason(null);
                                  }
                                : () => props.closeModal()
                        }
                        bg="white"
                        h="3.5rem"
                        m="auto"
                        textColor="gunmetal"
                    >
                        <Text textSize="title">{orderProblemStep === 0 ? "Nessuno, tutto ok!" : "Indietro"}</Text>
                    </Button>
                </Col>
                {orderProblemStep === 1 && (
                    <Col p="0">
                        <Button
                            onClick={() => {
                                setLoading(true);
                                deleteReason === 1 && editOrdersFunctions.sendMessageOrder(selectedOrder, false);
                                deleteOrderAPI(
                                    selectedOrder,
                                    deleteReason,
                                    deleteOrderFromState,
                                    props.closeModal,
                                    () => {
                                        setTab("products");
                                        handleProductsTypeChange(true);
                                    }
                                )
                                    .then(() => setLoading(false))
                                    .catch((error) => console.error(error));
                            }}
                            bg="gunmetal"
                            shadow="3"
                            h="3.5rem"
                            p={{ x: "1.5rem" }}
                            rounded="20px"
                            disabled={deleteReason === null || isLoading}
                            textColor="white"
                            m={{ x: "auto" }}
                        >
                            <Text textSize="title">Conferma</Text>
                        </Button>
                    </Col>
                )}
            </Row>
        </>
    );
}

export default DeleteOrderModal;
