import React, { useContext, useState } from "react";
import { Div, Text } from "atomize";
import { authContext } from "../../providers/auth.provider";
import theme from "../../appearance/theme";
import Paragraph from "../paragraph/Paragraph";
import RadioBox from "../atoms/RadioBox";
import { setSupplierDataFromFirestore } from "../../services/firebase.service";
import { productsContext } from "../../providers/products.provider";
import { toast } from "react-toastify";

function SettingsCard(props) {
    const { supplierData, setSupplierData } = useContext(authContext);
    const { mergedProdProps } = useContext(productsContext);

    const [value, setValue] = useState(supplierData?.firestore?.usesNewProdQuantityModal ?? 0);

    return (
        <>
            <Text textSize="title" textWeight="bold" textAlign="center" m={{ b: "1rem" }}>
                Impostazioni
            </Text>
            <Paragraph title="Modalità di pubblicazione" expandable={false} expanded={true}>
                <Div m={{ y: "0.5rem" }}>
                    <RadioBox
                        text="Classica, come sempre"
                        checked={!value}
                        handleChange={() => {
                            setValue(false);
                            setSupplierDataFromFirestore(supplierData.supplierID, { usesNewProdQuantityModal: false });
                            setSupplierData((prevState) => ({
                                ...prevState,
                                firestore: { ...supplierData.firestore, usesNewProdQuantityModal: false },
                            }));
                        }}
                    />
                    <RadioBox
                        text="Rapida"
                        checked={value}
                        handleChange={() => {
                            if (Object.keys(mergedProdProps?.unconfirmed).length > 0)
                                toast.info(
                                    "Hai selezionato la quantità di alcuni prodotti, ma non sono ancora pubblicati: pubblicali o toglili per continuare!",
                                    { position: "top-center" }
                                );
                            else {
                                setValue(true);
                                setSupplierDataFromFirestore(supplierData.supplierID, {
                                    usesNewProdQuantityModal: true,
                                });
                                setSupplierData((prevState) => ({
                                    ...prevState,
                                    firestore: { ...supplierData.firestore, usesNewProdQuantityModal: true },
                                }));
                            }
                        }}
                    />
                </Div>
            </Paragraph>
            <Text textSize="caption" textColor={theme.colors.text_light}>
                {!value
                    ? "Si seleziona la quanità, si conferma e si fa la stessa cosa per tutti i prodotti. Infine si sceglie la data per la quale pubblicare tutti i prodotti selezionati"
                    : "Dopo aver selezionato la quantità, si seleziona la data di pubblicazione e si pubblica, senza step successivi."}
            </Text>
        </>
    );
}

export default SettingsCard;
