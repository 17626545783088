import { Div, Container } from "atomize";
import React, { useState, useEffect, useContext } from "react";
import PendingProductsBannerHeader from "../pendingProductsBannerHeader/PendingProductsBannerHeader";
import PendingProduct from "../pendingProduct/PendingProduct";
import { productsContext } from "../../providers/products.provider";

function PendingProductsBanner(props) {
    const [expanded, setExpanded] = useState(false);
    const { editFunctions, mergedProdProps } = useContext(productsContext);

    const handleExpansion = () => setExpanded(!expanded);

    return (
        <>
            <Div h="4rem" pos="relative" />
            <Div
                pos="fixed"
                d="flex"
                w="100%"
                bg="primary_ulight"
                p={{
                    b: "calc(1.5rem + env(safe-area-inset-bottom))",
                    x: "0.5rem",
                }}
                zIndex="51"
                transition
                bottom="3.4rem"
            >
                <PendingProductsBannerHeader
                    expanded={expanded}
                    handleExpansion={handleExpansion}
                    handleProductsTypeChange={props.handleProductsTypeChange}
                />
            </Div>
        </>
    );
}

export default PendingProductsBanner;
