import React, { useRef, useState } from "react";

export const modalContext = React.createContext();

const ModalProvider = ({ children }) => {
    const [isOpen, setOpenState] = useState(false);
    const [type, setType] = useState("");
    const [data, setModalData] = useState(null);
    const [action, setModalAction] = useState(null);
    const [prev, setModalPrev] = useState("");

    const isOpenRef = useRef(isOpen);
    const typeRef = useRef(type);
    const dataRef = useRef(data);
    const prevRef = useRef(prev);

    const refs = {
        isOpenRef: isOpenRef,
        typeRef: typeRef,
        dataRef: dataRef,
        prevRef: prevRef,
    };

    const handleOpen = (type, data, action, prev) => {
        setType(type);
        setModalData(data);
        setModalAction(action);
        setModalPrev(prev);
        setOpenState(true);

        typeRef.current = type;
        dataRef.current = data;
        prevRef.current = prev;
        isOpenRef.current = true;
    };

    const handleClose = () => {
        setOpenState(false);
        setType("");
        setModalData(null);
        setModalAction(null);
        setModalPrev("");

        isOpenRef.current = false;
        typeRef.current = "";
        dataRef.current = null;
        prevRef.current = "";
    };

    return (
        <modalContext.Provider
            value={{
                isOpen,
                type,
                data,
                action,
                prev,
                refs,
                open: handleOpen,
                close: handleClose,
                setModalData,
            }}
        >
            {children}
        </modalContext.Provider>
    );
};

export default ModalProvider;
