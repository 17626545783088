import React from "react";
import ProductsContainer from "../productsContainer/ProductsContainer";

function ProductsTab(props) {
    return (
        <>
            <ProductsContainer />
        </>
    );
}

export default ProductsTab;
