import { Div, Text } from "atomize";
import React, { useState, useContext } from "react";
import Order from "../order/Order";
import DateGroupSeparator from "../dateGroupSeparator/DateGroupSeparator";
import { ordersContext } from "../../providers/orders.provider";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";

function Orders(props) {
    const [dragPosition, setDragPosition] = useState(0);
    const { ordersType, mergedOrdersProps, setOrdersType, getOrdersDelivered } = useContext(ordersContext);

    const orderActiveDeliveryUndefined = mergedOrdersProps?.active?.filter(
        (order) =>
            !order?.deliveryInfo?.infoID && !order.deliveryInfo?.deliveryHour && !order.deliveryInfo?.deliveryHour?.date
    );

    const checkDragPosition = (info) => {
        if (!ordersType) info.point.x + 90 < dragPosition && setOrdersType(true);
        else info.point.x - 90 > dragPosition && setOrdersType(false);
    };

    return (
        <>
            <motion.div
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                onDragStart={(e, info) => setDragPosition(info.point.x)}
                onDragEnd={(e, info) => info.point.x !== 0 && checkDragPosition(info)}
            >
                {!ordersType &&
                    mergedOrdersProps.pickupTimes &&
                    mergedOrdersProps.active &&
                    mergedOrdersProps.pickupTimes.map((datetime, index) => (
                        <Div key={`orders-${index}`}>
                            <DateGroupSeparator dateType="orders" date={datetime} />
                            <Div m={{ x: "1rem" }}>
                                {mergedOrdersProps.active.map(
                                    (order) =>
                                        order.deliveryInfo &&
                                        order.deliveryInfo.deliveryHour &&
                                        order.deliveryInfo.deliveryHour.date &&
                                        datetime === Date.parse(order.deliveryInfo.deliveryHour.date) && (
                                            <Order key={`order-active-${order.orderCod}`} orderInfo={order} />
                                        )
                                )}
                            </Div>
                        </Div>
                    ))}
                {!ordersType && orderActiveDeliveryUndefined?.length > 0 && mergedOrdersProps.active && (
                    <Div key={`orders-others`}>
                        <DateGroupSeparator dateType="orders" />
                        <Div m={{ x: "1rem" }}>
                            {orderActiveDeliveryUndefined.map((order) => (
                                <Order key={`order-active-${order.orderCod}`} orderInfo={order} />
                            ))}
                        </Div>
                    </Div>
                )}
            </motion.div>
            {ordersType && (
                <Div m={{ x: "1rem" }}>
                    <InfiniteScroll
                        dataLength={mergedOrdersProps?.delivered?.orders?.length ?? 10}
                        next={() => getOrdersDelivered(mergedOrdersProps?.delivered?.page)}
                        hasMore={mergedOrdersProps?.delivered?.page > 0}
                        loader={
                            <ContentLoader
                                speed={1}
                                width={320}
                                height={120}
                                viewBox="0 0 330 120"
                                foregroundColor="#D6D6D6"
                                backgroundOpacity={0}
                            >
                                <rect x="7" y="5" rx="20" ry="20" width="320" height="120" />
                            </ContentLoader>
                        }
                    >
                        <motion.div
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            onDragStart={(e, info) => setDragPosition(info.point.x)}
                            onDragEnd={(e, info) => info.point.x !== 0 && checkDragPosition(info)}
                        >
                            {mergedOrdersProps?.delivered?.orders?.map((order) => (
                                <Order key={`order-pickuped-${order.orderCod}`} orderInfo={order} />
                            ))}
                        </motion.div>
                    </InfiniteScroll>
                </Div>
            )}
            <motion.div
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                onDragStart={(e, info) => setDragPosition(info.point.x)}
                onDragEnd={(e, info) => info.point.x !== 0 && checkDragPosition(info)}
            >
                {props.ordersList && props.ordersList.length === 0 && (
                    <Div textAlign="center" m={{ t: "50%", x: "1rem" }}>
                        <Text textSize="heading" textColor="primary">
                            {!ordersType
                                ? "Rilassati, non ci sono ordini da preparare al momento!"
                                : "Qui ci saranno gli ordini ritirati dai clienti, torna più tardi!"}
                        </Text>
                    </Div>
                )}
            </motion.div>
        </>
    );
}

export default Orders;
