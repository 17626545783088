import { Div } from "atomize";
import { motion } from "framer-motion";
import React from "react";

function Card({ children, bg, m, ...others }) {
    return (
        <motion.div
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 0.5 }}
        >
            <Div transition bg={bg} p="1rem" m={m || "1rem"} rounded="xl" shadow="accent" {...others}>
                {children}
            </Div>
        </motion.div>
    );
}

export default Card;
