import { Div, Text } from "atomize";
import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { ordersContext } from "../../providers/orders.provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import theme from "../../appearance/theme";
import { getRemoteValue } from "../../services/func.utils";

function OrderHeader(props) {
    const { ordersType, selectedOrder, ordersDeliveredList } = useContext(ordersContext);

    const getCreationFormattedDate = (creationDate) => {
        let d = creationDate.replace(/^"|"$/g, "");
        const date = new Date(d);
        const today = moment();
        const yesterday = moment().subtract(1, "days");
        if (moment(d).isSame(today, "day"))
            return date.toLocaleString("it-IT", {
                hour: "2-digit",
                minute: "2-digit",
            });
        else if (moment(d).isSame(yesterday, "day")) return "Ieri";
        else {
            if (moment().isSame(d, "year"))
                return date.toLocaleString("it-IT", {
                    month: "numeric",
                    day: "2-digit",
                });
            else
                return date.toLocaleString("it-IT", {
                    month: "numeric",
                    day: "2-digit",
                    year: "2-digit",
                });
        }
    };

    const orderExpired = (pickupDate) => {
        return moment(pickupDate.date + " " + pickupDate.deliveryHourEnd).isBefore();
    };

    return (
        <Div onClick={props.handleClick}>
            <Div d="flex" justify="space-between" align="center">
                <Text textSize="title" textColor="gunmetal" textWeight="bold">
                    Ordine n°{props.orderInfo["orderCod"]}
                </Text>
                <Text textSize="subheader" textColor="gunmetal">
                    {getCreationFormattedDate(props.orderInfo.date)}
                </Text>
            </Div>
            <Div d="flex" justify="space-between" align="center">
                <Text textSize="subheader" textColor="gunmetal" style={{ lineHeight: "24px" }}>
                    {props.orderInfo.deliveryInfo &&
                        props.orderInfo.deliveryInfo.deliveryHour &&
                        "Ritiro: " +
                            props.orderInfo.deliveryInfo.deliveryHour.deliveryHourStart +
                            "-" +
                            props.orderInfo.deliveryInfo.deliveryHour.deliveryHourEnd}
                </Text>
                <Text
                    textWeight="bold"
                    textSize="subheader"
                    textAlign="right"
                    textColor="warning900"
                    style={{ lineHeight: "24px" }}
                >
                    {props.orderInfo?.orderNumByUserPerSupplier <= 1 && "Nuovo cliente!"}
                </Text>
            </Div>
            {ordersDeliveredList?.reviews[props.orderInfo?.orderCod] && JSON.parse(getRemoteValue("ff_reviews")) && (
                <>
                    {ordersDeliveredList?.reviews[props.orderInfo?.orderCod]?.liked && (
                        <Div bg={theme.colors.accent} rounded="20px" p="0.75rem" w="3rem" h="3rem" textAlign="center">
                            <FontAwesomeIcon icon={faThumbsUp} color={theme.colors.white} size="lg" />
                        </Div>
                    )}
                    {!ordersDeliveredList?.reviews[props.orderInfo?.orderCod]?.liked && (
                        <Div bg={theme.colors.primary} rounded="20px" p="0.75rem" w="3rem" h="3rem" textAlign="center">
                            <FontAwesomeIcon icon={faThumbsDown} color={theme.colors.white} size="lg" />
                        </Div>
                    )}
                </>
            )}
            <Div d="flex" justify="space-between" align="center" m={{ t: "1rem" }}>
                <NumberFormat
                    value={
                        props.orderInfo["discount"]
                            ? props.orderInfo["discount"]["subtotal"]
                            : props.orderInfo["totalPrice"]
                    }
                    displayType={"text"}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={"€"}
                    renderText={(price) => (
                        <Text textSize="subheader" textColor="gunmetal" style={{ lineHeight: "24px" }}>
                            Totale:{" "}
                            <Text tag="span" textWeight="bold" textColor="gunmetal">
                                {price}
                            </Text>
                        </Text>
                    )}
                />

                {selectedOrder === props.orderInfo.orderCod ? (
                    <FontAwesomeIcon icon={faChevronUp} size="lg" color="dark" style={{ marginLeft: "0.75rem" }} />
                ) : (
                    <FontAwesomeIcon icon={faChevronDown} size="lg" color="dark" style={{ marginLeft: "0.75rem" }} />
                )}
            </Div>
        </Div>
    );
}

export default OrderHeader;
