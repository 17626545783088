import { Div, Input } from "atomize";
import React, { useContext } from "react";
import { productsContext } from "../../providers/products.provider";
import { faChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../appearance/theme";

//Padre: ProductsFilter
function SearchProducts(props) {
    const { getSearchProducts, searchText, setSearchText, handleSearchButtonClick } = useContext(productsContext);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        getSearchProducts(e.target.value);
    };

    const resetSearch = () => {
        setSearchText("");
        getSearchProducts("");
    };

    return (
        <Div>
            <Input
                type="text"
                name="productsSearch"
                autoComplete="off"
                placeholder="Cerca prodotti"
                p={{ l: "3rem" }}
                rounded="20px"
                border="3px solid"
                borderColor="gunmetal"
                focusBorderColor="gunmetal"
                h="3.5rem"
                shadow="accent"
                value={searchText}
                onChange={(e) => handleSearch(e)}
                prefix={
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        size="2x"
                        color={theme.colors.gunmetal}
                        cursor="pointer"
                        onClick={() => handleSearchButtonClick()}
                        style={{
                            position: "absolute",
                            top: "0.85rem",
                            left: "1rem",
                        }}
                    />
                }
                suffix={
                    <FontAwesomeIcon
                        icon={faTimes}
                        size="2x"
                        color="#db0101"
                        cursor="pointer"
                        onClick={() => resetSearch()}
                        style={{
                            position: "absolute",
                            top: "0.85rem",
                            right: "1rem",
                        }}
                    />
                }
            />
        </Div>
    );
}

export default SearchProducts;
