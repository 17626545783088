import React, { useContext, useEffect, useState } from "react";
import EuroIcon from "@material-ui/icons/Euro";
import { Div, Text, Anchor, Col, Row } from "atomize";
import theme from "../../appearance/theme";
import apiService from "../../services/api.service";
import NumberFormat from "react-number-format";
import Dropdown from "../atomize/Dropdown";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/it";
import axios from "axios"; // without this line it didn't work
import { canHaveCashback } from "../../services/func.utils";
import { authContext } from "../../providers/auth.provider";
import BoldText from "../boldText/BoldText";

moment.locale("it");

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

let cancelToken = null;

function EarnReportCard(props) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [reportList, setReportList] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [earnValue, setEarnValue] = useState(0);
    const [earnCashback, setEarnCashback] = useState(0);
    const [earnReportFilter, setEarnReportFilter] = useState("Ieri");
    const [earnReportDate, setEarnReportDate] = useState(moment().subtract(1, "days"));

    const { setLog, supplierData } = useContext(authContext);

    const getLastMonths = () => {
        const date = moment();
        const month1 = moment().subtract(1, "months");
        const month2 = moment().subtract(2, "months");
        setReportList([
            [date.format("YYYY"), date],
            [capitalize(month2.format("MMMM")), month2],
            [capitalize(month1.format("MMMM")), month1],
            [capitalize(date.format("MMMM")), date],
            ["Ieri", date.subtract(1, "days")],
        ]);
    };

    const handleClick = (filter, date) => {
        setEarnReportFilter(filter);
        setEarnReportDate(date);
        setShowDropdown(!showDropdown);
    };

    const formatStartEndDate = () => {
        let firstDay, lastDay;
        const date = new Date();
        if (earnReportFilter === "Ieri") {
            const yesterday = earnReportDate;
            firstDay = yesterday;
            lastDay = yesterday;
        } else if (earnReportFilter === date.getFullYear().toString()) {
            firstDay = earnReportDate.clone().startOf("year");
            lastDay = earnReportDate.clone().endOf("year");
        } else {
            firstDay = earnReportDate.clone().startOf("month");
            lastDay = earnReportDate.clone().endOf("month");
        }
        return [firstDay.format("DD-MM-YYYY"), lastDay.format("DD-MM-YYYY")];
    };

    const getReport = async () => {
        const [startDate, endDate] = formatStartEndDate();
        if (cancelToken) {
            cancelToken.cancel("CANCELED");
        }
        cancelToken = axios.CancelToken.source();
        await apiService.api
            .get("/report/", {
                params: { startDate: startDate, endDate: endDate },
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : setReportData(response.data);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    setEarnValue(0);
                    setEarnCashback(0);
                    toast.error("C'è stato un problema con l' aggiornamento dei guadagni");
                    console.error("Report sold ", error);
                }
            });
    };

    useEffect(() => {
        getLastMonths();
    }, []);

    useEffect(() => {
        if (reportData) {
            reportData.sumTotalIncome ? setEarnValue(reportData.sumTotalIncome) : setEarnValue(0);
            reportData.cashback?.sum_cashback ? setEarnCashback(reportData.cashback.sum_cashback) : setEarnCashback(0);
        }
    }, [reportData]);

    useEffect(() => {
        earnReportFilter && getReport();
    }, [earnReportFilter]);

    const dateList = (
        <Div p={{ x: "0.5rem", y: "0.5rem" }}>
            {reportList &&
                reportList.map((value, index) => (
                    <Anchor
                        key={index}
                        d="block"
                        p={{ y: "0.5rem" }}
                        m={{ x: "0.5rem" }}
                        style={{ borderBottom: "1px solid lightgray" }}
                        textColor="dark"
                        onClick={() => handleClick(value[0], value[1])}
                    >
                        {value[0]}
                    </Anchor>
                ))}
        </Div>
    );

    return (
        <>
            <Row align="center">
                <Col>
                    <Div d="flex" justify="center" align="center">
                        <Text p={{ x: "0.5rem" }} textSize="title" textAlign="center" textWeight="bold">
                            Guadagni
                        </Text>
                        <EuroIcon fontSize="large" style={{ color: theme.colors.primary }} />
                    </Div>
                </Col>
                <Col d="flex" justify="center">
                    <Dropdown
                        w="fit-content"
                        direction="topright"
                        isOpen={showDropdown}
                        onClick={() => setShowDropdown(!showDropdown)}
                        menu={dateList}
                    >
                        {earnReportFilter}
                    </Dropdown>
                </Col>
            </Row>
            {canHaveCashback(supplierData) && (
                <Row m={{ t: "1rem" }}>
                    <Col size="5" textAlign="left">
                        <Text textSize="subheader">Vendite</Text>
                        {
                            <NumberFormat
                                value={earnValue}
                                displayType={"text"}
                                decimalSeparator={","}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"€"}
                                renderText={(earn) => (
                                    <Text textColor="gray" textSize="title">
                                        {earn}
                                    </Text>
                                )}
                            />
                        }
                    </Col>
                    <Col textAlign="left">
                        <Text textSize="subheader">Premio Squiseat</Text>
                        {
                            <NumberFormat
                                value={earnCashback}
                                displayType={"text"}
                                decimalSeparator={","}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"€"}
                                renderText={(earn) => (
                                    <Text textColor="gray" textSize="title">
                                        {earn}
                                    </Text>
                                )}
                            />
                        }
                    </Col>
                </Row>
            )}
            <Text m={{ t: "0.25rem" }} textAlign="center" textSize="subheader">
                Commissione Squiseat: <BoldText>{supplierData.ourMargin * 100}%</BoldText>
            </Text>
            <hr style={{ border: "1px solid #EEEEEE", opacity: "0.7" }} />
            <Div m={{ t: "1rem", l: "0.5rem" }} textAlign="left">
                <Text textWeight="bold" textSize="subheader">
                    TOTALE
                </Text>
                {
                    <NumberFormat
                        value={canHaveCashback(supplierData) ? earnValue + earnCashback : earnValue}
                        displayType={"text"}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"€"}
                        renderText={(earn) => (
                            <Text textColor="dark" textSize="title">
                                {earn}
                            </Text>
                        )}
                    />
                }
            </Div>
        </>
    );
}

export default EarnReportCard;
