import FirebaseService from "./firebase.service";
import { Plugins } from "@capacitor/core";
import { toast } from "react-toastify";
const { PushNotifications } = Plugins;
let config = require("../../src/settings/config");

const initNotification = (supplierData) => {
    if (config.type === "pwa") {
        Notification.requestPermission()
            .then(() => {
                FirebaseService.messaging.getToken();
            })
            .then((token) => {
                token &&
                    FirebaseService.checkSupplierToken(supplierData, token);
            })
            .catch((err) => {
                console.error("[NOTIFICATION] No permission to send push", err);
            });
        FirebaseService.messaging.onMessage((payload) => {
            console.log("Message received, no background", payload);
            if (payload.data.event === "newOrder") {
                console.info(
                    `[NOTIFICATION] Notification received with webapp open for ${payload.data?.event} #${payload.data.orderCod}`
                );
                toast.info("NUOVO ORDINE (#" + payload.data.orderCod + ")!", {
                    onClose: () => {
                        window.location.reload(false);
                    },
                });
            }
            //mettere toast per visualizzare popup con app/browser aperto
        });
    } else if (config.type === "app") {
        PushNotifications.requestPermission()
            .then((result) => {
                if (result.granted) {
                    // Register with Apple / Google to receive push via APNS/FCM
                    console.log(
                        "[NOTIFICATION] Notification permissions GRANTED"
                    );
                    PushNotifications.register();
                } else {
                    console.warn(
                        "[NOTIFICATION] Notification permissions NOT GRANTED"
                    );
                }
            })
            .catch(function (error) {
                console.error("[NOTIFICATION] Error get token: ", error);
            });

        // On succcess, we should be able to receive notifications
        PushNotifications.addListener("registration", (token) => {
            console.info(
                `[NOTIFICATION] Push registration success, token: ${token.value}`
            );
            //FirestoreService.addNewSupplier(supplierID, token.value);
            FirebaseService.checkSupplierToken(supplierData, token.value);
        });

        // Some issue with your setup and push will not work
        PushNotifications.addListener("registrationError", (error) => {
            console.error(
                "[NOTIFICATION] Error on registration: " + JSON.stringify(error)
            );
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
            "pushNotificationReceived",
            (notification) => {
                console.log("pushNotificationReceived", notification);
                if (notification.data?.event === "newOrder") {
                    console.info(
                        `[NOTIFICATION] Notification received with app open for ${notification.data?.event} #${notification.data.orderCod}`
                    );
                    toast.info(
                        "NUOVO ORDINE (#" + notification.data.orderCod + ")!",
                        {
                            onClose: () => {
                                window.location.reload(false);
                            },
                        }
                    );
                } else {
                    console.info(
                        `[NOTIFICATION] Notification received with app open for ${notification.data?.event}`
                    );
                }
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (notification) => {
                console.log("pushNotificationActionPerformed", notification);
                if (notification.notification.data?.event === "newOrder") {
                    console.info(
                        `[NOTIFICATION] Supplier tap notification for ${notification.notification?.data?.event} #${notification.notification.data.orderCod}`
                    );
                } else {
                    console.info(
                        `[NOTIFICATION] Supplier tap notification for  ${notification.notification.data?.event}`
                    );
                }
            }
        );
    }
};

export default {
    initNotification,
};
