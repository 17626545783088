import React from "react";
import { Button, Col, Row, Text } from "atomize";

function UndoToast({ undoAction, text }) {
    return (
        <Row align="center">
            <Col size="8">
                <Text>{text}</Text>
            </Col>
            <Col size="4">
                <Button textWeight="bold" bg="white" textColor="gunmetal" onClick={undoAction} rounded="20px">
                    ANNULLA
                </Button>
            </Col>
        </Row>
    );
}

export default UndoToast;
