import React from "react";
import { Button, Text } from "atomize";

function ProductCategory(props) {
    return (
        <Button bg="primary_ulight" id={"cat-" + props.id} onClick={() => props.handleClick(props.id)}>
            <Text
                textSize="subheader"
                textColor={props.isSelected ? "gunmetal" : "gray"}
                textWeight={props.isSelected && "bold"}
                border={props.isSelected && { b: "3px solid" }}
                borderColor={props.isSelected && "gunmetal"}
            >
                <span style={{ whiteSpace: "nowrap" }}>{props.name.toUpperCase()}</span>
            </Text>
        </Button>
    );
}

export default ProductCategory;
