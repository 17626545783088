import { Div } from "atomize";
import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { ordersContext } from "../../providers/orders.provider";
import OrderBody from "../orderBody/OrderBody";
import OrderHeader from "../orderHeader/OrderHeader";
import moment from "moment";

function Order(props) {
    const [isPickedUp, setPickUpState] = useState(false);

    const { ordersType, selectedOrder, setSelectedOrder } = useContext(ordersContext);
    console.log(props.orderInfo);
    return (
        <motion.div
            whileTap={{ scale: selectedOrder === props.orderInfo.orderCod ? 1 : 0.98 }}
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 0.5 }}
        >
            <Div
                pos="relative"
                border={!ordersType && "3px solid"}
                borderColor={
                    moment(moment()).isSameOrBefore(props.orderInfo?.deliveryInfo?.deliveryHour?.date, "day")
                        ? "gunmetal_blur"
                        : "danger900"
                }
                bg={ordersType ? "gunmetal_blur" : "gray_blur"}
                rounded="20px"
                maxH={isPickedUp ? "0" : "50rem"}
                opacity={isPickedUp ? "0" : "1"}
                transition
                m={{ y: "0.5rem" }}
                p="1rem"
            >
                <OrderHeader
                    key={
                        ordersType
                            ? `order-pickuped-${props.orderInfo.orderCod}-header`
                            : `order-active-${props.orderInfo.orderCod}-header`
                    }
                    orderInfo={props.orderInfo}
                    handleClick={() =>
                        selectedOrder === props.orderInfo.orderCod
                            ? setSelectedOrder(null)
                            : setSelectedOrder(props.orderInfo.orderCod)
                    }
                />
                <OrderBody
                    key={
                        ordersType
                            ? `order-pickuped-${props.orderInfo.orderCod}-body`
                            : `order-active-${props.orderInfo.orderCod}-body`
                    }
                    orderInfo={props.orderInfo}
                    setPickUpState={setPickUpState}
                    openToast={props.openToast}
                    isOpen={selectedOrder === props.orderInfo.orderCod}
                    openDeleteOrderModal={props.openDeleteOrderModal}
                />
            </Div>
        </motion.div>
    );
}

export default Order;
