import React, { useState } from "react";
import { Div } from "atomize";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import theme from "../../appearance/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

Paragraph.defaultProps = {
    text: "",
    expandable: false,
    expanded: false,
    children: null,
    m: "0",
    p: "0",
    textColor: theme.colors.black,
    secondaryAction: null,
    textWeight: "bold",
};

Paragraph.propTypes = {
    children: PropTypes.any,
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    p: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    secondaryAction: PropTypes.any,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textColor: PropTypes.any,
    textWeight: PropTypes.string,
    title: PropTypes.any,
};

function Paragraph({ title, expandable, expanded, children, m, p, textColor, secondaryAction, textWeight }) {
    const [isExpanded, setExpanded] = useState(expanded);

    const handleSetExpanded = () => {
        setExpanded(!isExpanded);
    };

    return (
        <>
            <Div
                d="flex"
                onClick={() => handleSetExpanded()}
                align="center"
                m={m}
                p={p}
                justify={secondaryAction && "space-between"}
                //align={secondaryAction && "center"}
            >
                <Div textSize="subheader" textColor={textColor} textWeight={textWeight}>
                    {title}
                </Div>
                {expandable && (
                    <Div p={{ x: "0.5rem" }}>
                        {isExpanded ? (
                            <FontAwesomeIcon icon={faChevronUp} size="lg" color={textColor} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronDown} size="lg" color={textColor} />
                        )}
                    </Div>
                )}
                {secondaryAction}
            </Div>
            {expandable ? (
                <AnimatePresence>
                    {isExpanded && (
                        <motion.section
                            style={{ overflow: "hidden" }}
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                                open: { opacity: 1, height: "auto" },
                                collapsed: { opacity: 0, height: 0 },
                            }}
                            transition={{
                                duration: 0.3,
                                ease: [0.04, 0.62, 0.23, 0.98],
                            }}
                        >
                            {children}
                        </motion.section>
                    )}
                </AnimatePresence>
            ) : (
                <>{children}</>
            )}
        </>
    );
}

export default Paragraph;
