import React, { useContext, useEffect, useState } from "react";
import { Text, Row, Col } from "atomize";
import { authContext } from "../../providers/auth.provider";
import { getUserSubscribedCount } from "../../services/firebase.service";
import { roundValue } from "../../services/func.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHeart } from "@fortawesome/free-solid-svg-icons";
import theme from "../../appearance/theme";
import BoldText from "../boldText/BoldText";

function SupplierStatsCard(props) {
    const [userSubscribedCount, setUserSubscribedCount] = useState(0);

    const { supplierData } = useContext(authContext);

    useEffect(() => {
        supplierData &&
            getUserSubscribedCount(supplierData.supplierID).then((userSubscribed) =>
                setUserSubscribedCount(userSubscribed)
            );
    }, [supplierData]);

    return (
        <>
            <Text textSize="title" textWeight="bold" textAlign="center">
                Statistiche
            </Text>
            <Row textAlign="center">
                <Col>
                    <Text textSize="subheader">Preferiti</Text>{" "}
                    <FontAwesomeIcon icon={faHeart} size="1x" color={theme.colors.primary} />
                    <Text textSize="subheader" textWeight="bold">
                        {roundValue(userSubscribedCount * 4.66)}
                    </Text>
                </Col>
                <Col>
                    <Text textSize="subheader">Notifiche</Text>
                    <FontAwesomeIcon icon={faBell} size="1x" color={theme.colors.warning} />
                    <Text textSize="subheader" textWeight="bold">
                        {userSubscribedCount}
                    </Text>
                </Col>
            </Row>
            Ecco per quante persone sei tra i <BoldText textColor={theme.colors.primary}>preferiti</BoldText> e quante
            verranno <BoldText textColor={theme.colors.warning}>notificate</BoldText> quando pubblicherai rimanenze!
        </>
    );
}

export default SupplierStatsCard;
