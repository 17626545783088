import { Div, Text } from "atomize";
import React from "react";
import moment from "moment";
import { toast } from "react-toastify";

function DateGroupSeparator(props) {
    const formattedDate = new Date(props.date);
    const today = moment();
    const tomorrow = moment().add(1, "days");

    const isToday = () => {
        return moment(props.date).isSame(today, "day");
    };
    const isTomorrow = () => {
        return moment(props.date).isSame(tomorrow, "day");
    };

    const getDateForProducts = () => {
        if (isToday())
            return (
                "Oggi, " +
                formattedDate.toLocaleString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        else if (isTomorrow())
            return (
                "Domani, " +
                formattedDate.toLocaleString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        else
            return formattedDate.toLocaleString("it-IT", {
                weekday: "long",
                month: "numeric",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            });
    };

    const getDateForOrders = () => {
        if (props.date === undefined) {
            return "Ops, clicca qui che ti spieghiamo";
        } else {
            if (isToday())
                return (
                    "In ritiro oggi " +
                    formattedDate.toLocaleString("it-IT", {
                        month: "2-digit",
                        day: "2-digit",
                    })
                );
            else if (isTomorrow()) return "In ritiro domani";
            else if (moment(moment()).isAfter(props.date))
                return (
                    "Ordini vecchi del " +
                    formattedDate.toLocaleString("it-IT", {
                        month: "2-digit",
                        day: "2-digit",
                    })
                );
            else
                return (
                    "In ritiro " +
                    formattedDate.toLocaleString("it-IT", {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                    })
                );
        }
    };

    return (
        <Div
            bg={moment(moment()).isAfter(props.date, "day") ? "danger900" : "gunmetal"}
            pos="sticky"
            top="calc(3rem + env(safe-area-inset-top))"
            zIndex="1"
            m={{ l: "1rem", b: "0.5rem" }}
            p={{ r: "1.5rem" }}
            rounded="20pxLeft"
        >
            <Text
                textSize="subheader"
                textWeight="bold"
                textAlign="right"
                textColor="white"
                onClick={() =>
                    props.date === undefined &&
                    toast.warning("A questi ordini manca la data di ritiro, ma a breve risolviamo!")
                }
            >
                {props.dateType === "products" ? "Scade: " + getDateForProducts() : getDateForOrders()}
            </Text>
        </Div>
    );
}

export default DateGroupSeparator;
