import React, { useContext, useEffect, useState } from "react";
import { Text, Div } from "atomize";
import { authContext } from "../../providers/auth.provider";
import { getOrdersReviews, getSupplierReview } from "../../services/firebase.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import theme from "../../appearance/theme";

function SupplierReviewCard(props) {
    const [supplierReview, setSupplierReview] = useState({});
    const [topReviews, setTopReviews] = useState([]);

    const { supplierData } = useContext(authContext);

    useEffect(() => {
        if (supplierData) {
            getSupplierReview(supplierData.supplierID).then((supplierReview) => setSupplierReview(supplierReview));
            getOrdersReviews(supplierData.supplierID).then((ordersReviews) => {
                let topReviewsTmp = [];
                Object.values(ordersReviews).every((review) => {
                    review.liked === true && review.note && review.note?.length > 10 && topReviewsTmp.push(review.note);
                    if (topReviewsTmp.length > 2) return false;
                    return true;
                });
                setTopReviews(topReviewsTmp);
            });
        }
    }, [supplierData]);

    return (
        <>
            {supplierReview?.totalReviews > 4 ? (
                <>
                    <Text textSize="title" textWeight="bold" d="flex" justify="center" align="center">
                        <FontAwesomeIcon icon={faStar} size="1x" color={theme.colors.warning} />
                        <Text m={{ l: "0.25rem" }} tag="span" textSize="subheader" textWeight="bold">
                            {supplierReview?.evaluation} ({supplierReview?.totalReviews} recensioni)
                        </Text>
                    </Text>
                    <Div m={{ t: "0.5rem" }} textAlign="center" style={{ fontStyle: "italic" }}>
                        {topReviews?.map((review, index) => (
                            <Text key={index} textSize="subheader">
                                "{review}"
                            </Text>
                        ))}
                    </Div>
                </>
            ) : (
                <>
                    <Text textSize="title" textWeight="bold" d="flex" justify="center" align="center">
                        <FontAwesomeIcon icon={faStar} size="1x" color={theme.colors.warning} />
                        <Text m={{ x: "0.25rem" }} tag="span" textSize="subheader" textWeight="bold">
                            Valutazione locale
                        </Text>
                        <FontAwesomeIcon icon={faStar} size="1x" color={theme.colors.warning} />
                    </Text>
                    <Text m={{ t: "0.5rem" }} textAlign="center">
                        Qui vedrai la valutazione del tuo locale non appena avrai abbastanza recensioni!
                    </Text>
                </>
            )}
        </>
    );
}

export default SupplierReviewCard;
