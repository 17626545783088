import React, { useEffect, useState } from "react";
import { Div } from "atomize";
import ProductsCategory from "../productsCategory/ProductsCategory";

function ProductsCategories(props) {
    const [categories, setCategories] = useState(null);
    const [categorySelected, setCategorySelected] = useState(0);
    const [lastScrollTop, setLastScroll] = useState(0);

    function handleCategoryChange(index) {
        const wrapper = document.getElementById("categories_wrapper");
        const categoryOffset = document.getElementById("cat-" + index).offsetWidth;
        setCategorySelected(index);
        var categoryWrapperOffset = document.getElementById("anchor-" + index).offsetTop - 60;
        window.scrollTo({ top: categoryWrapperOffset, behavior: "smooth" });
        //scrollTo("#anchor-" + index, 60, 0, 600)
        index > categorySelected
            ? wrapper.scrollTo({
                  left: wrapper.scrollLeft + categoryOffset,
                  behavior: "smooth",
              })
            : wrapper.scrollTo({
                  left: wrapper.scrollLeft - categoryOffset,
                  behavior: "smooth",
              });
    }

    useEffect(() => {
        setCategories(props.categories);
        setCategorySelected(0);
    }, [props.categories]);

    useEffect(function setupListener() {
        function handleCategoryScroll() {
            let currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
            const wrapper = document.getElementById("categories_wrapper");
            if (currentScrollPos > lastScrollTop) {
                //scroll down
                if (document.getElementById("anchor-" + (categorySelected + 1)) !== null)
                    var nextCategory = document.getElementById("anchor-" + (categorySelected + 1)).offsetTop;
                if (currentScrollPos > nextCategory) {
                    const categoryOffset = document.getElementById("cat-" + (categorySelected + 1)).offsetWidth;
                    setCategorySelected(categorySelected + 1);
                    wrapper.scrollTo({
                        left: wrapper.scrollLeft + categoryOffset,
                        behavior: "smooth",
                    });
                }
            } else {
                //scroll up
                if (document.getElementById("anchor-" + (categorySelected - 1)) !== null)
                    var prevCategory = document.getElementById("anchor-" + (categorySelected - 1)).offsetTop;
                if (categorySelected > 0 && currentScrollPos < prevCategory) {
                    const categoryOffset = document.getElementById("cat-" + (categorySelected - 1)).offsetWidth;
                    setCategorySelected(categorySelected - 1);
                    wrapper.scrollTo({
                        left: wrapper.scrollLeft - categoryOffset,
                        behavior: "smooth",
                    });
                }
            }
            setLastScroll(currentScrollPos <= 0 ? 0 : currentScrollPos);
        }
        window.addEventListener("scroll", handleCategoryScroll);

        return function cleanupListener() {
            window.removeEventListener("scroll", handleCategoryScroll);
        };
    });

    return (
        <>
            <Div h="3rem" pos={!props.searching ? "relative" : "fixed"} />
            <Div
                pos="fixed"
                d="flex"
                id="categories_wrapper"
                w="100vw"
                zIndex="50"
                bg="primary_ulight"
                border={{ t: "2px solid" }}
                borderColor="#E1E4E8"
                p={{
                    b: "calc(1rem + env(safe-area-inset-bottom))",
                    t: "0.2rem",
                }}
                style={{ transition: "all 0.3s ease-in-out" }}
                bottom={!props.searching ? (props.pProducts ? "8.25rem" : "3.5rem") : "0rem"}
                overflow="scroll"
                transition
            >
                {!(categories === null) &&
                    categories.map((value, index) => (
                        <ProductsCategory
                            key={index}
                            id={index}
                            name={value}
                            handleClick={handleCategoryChange}
                            idSelected={categorySelected}
                            isSelected={index === categorySelected}
                        />
                    ))}
            </Div>
        </>
    );
}
export default ProductsCategories;
