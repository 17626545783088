import { Text } from "atomize";
import { canHaveCashback } from "../../services/func.utils";
import React from "react";
import Card from "./Card";

function EarnInfoDiv({ text, bg, m, supplierData }) {
    if (canHaveCashback(supplierData))
        return (
            <Card bg={bg} shadow="0" m={m || "1rem 0"}>
                <Text textColor="white">{text}</Text>
            </Card>
        );
    else return <></>;
}

export default EarnInfoDiv;
