import { Button, Col, Div, Input, Row, Text, Tag } from "atomize";
import React, { useContext, useEffect, useState } from "react";
import apiService from "../../services/api.service";
import { toast } from "react-toastify";
import { authContext } from "../../providers/auth.provider";
import { productsContext } from "../../providers/products.provider";
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBarcode,
    faCamera,
    faEuroSign,
    faFolderOpen,
    faImage,
    faLeaf,
    faPenFancy,
    faPiggyBank,
    faSeedling,
    faSpellCheck,
    faTasks,
    faTh,
} from "@fortawesome/free-solid-svg-icons";
import Paragraph from "../paragraph/Paragraph";
import theme from "../../appearance/theme";
import { ProductImage } from "../product/Product";
import NumberFormat from "react-number-format";
import { modalContext } from "../../providers/modal.provider";
import { addProductImage } from "../../services/firebase.service";
import { getImage } from "../../services/func.utils";
import SecondaryButton from "../buttons/SecondaryButton";
import BoldText from "../boldText/BoldText";
var levenshtein = require("fast-levenshtein");

function EditProductModal({ closeModal }) {
    const { mergedProdProps, productsEntries, editFunctions, productInfoSelected } = useContext(productsContext);
    const [isLoading, setLoadingState] = useState(false);
    const [whatToChange, setWhatToChange] = useState("");
    const [prodName, setProdName] = useState(productInfoSelected?.productName);
    const [prodInternalID, setProdInternalID] = useState(
        productsEntries?.internalID[productInfoSelected.productID] ?? ""
    );
    const [prodCategory, setProdCategory] = useState({
        name: "",
        id: null,
    });
    const [prodPrice, setProdPrice] = useState(productInfoSelected?.fullPrice);
    const [prodContent, setProdContent] = useState(
        (productInfoSelected?.props?.[0] && 1) ||
            (productInfoSelected?.prop?.[0] && 1) ||
            (productInfoSelected?.props?.[1] && 2) ||
            (productInfoSelected?.prop?.[1] && 2) ||
            (productInfoSelected?.props?.[2] && 3) ||
            (productInfoSelected?.prop?.[2] && 3) ||
            0
    );
    const [categoryList, setCategoryList] = useState(null);
    const [productImage, setProductImage] = useState({ url: null, toBeDeleted: false });

    const { photo, takePhoto, resetPhoto } = usePhotoGallery();
    const { setLog, supplierData } = useContext(authContext);
    const Modal = useContext(modalContext);

    const categorySupplier =
        categoryList &&
        productsEntries?.categories &&
        categoryList?.filter((x) => productsEntries?.categories?.includes(x.type));
    const categoryNew =
        categoryList &&
        productsEntries?.categories &&
        categoryList?.filter((x) => !productsEntries?.categories?.includes(x.type));

    const handleProdNameChange = (e) => setProdName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    const handleProdInternalIDChange = (e) => setProdInternalID(e.target.value);
    const handleProdPriceChange = (e) => setProdPrice(e.target.value);

    useEffect(() => {
        !categoryList && getCategories();
        getImage("products", productInfoSelected.productID, (productImage) =>
            setProductImage({ url: productImage, toBeDeleted: false })
        );
        /*productInfoSelected.productID &&
            getProductImageFromFirebase(productInfoSelected.productID)
                .then((productImage) => productImage && setProductImage({ url: productImage, toBeDeleted: false }))
                .catch((error) => console.warn(`Error get product ${productInfoSelected?.productID} image: `, error));*/
    }, []);

    const getCategories = async () => {
        await apiService.api
            .get("/productTypes/?all=1")
            .then((response) => {
                if (apiService.checkRedirectLogin(response.request.responseURL)) setLog(false);
                else {
                    setCategoryList(response.data);
                    setProdCategory({
                        name: productInfoSelected?.type,
                        id: response.data.find((categoryInfo) => categoryInfo.type === productInfoSelected?.type)
                            .productTypeID,
                    });
                }
            })
            .catch((error) => {
                console.error("[API] Get categories ", error);
                toast.error("Ops, non trovo le categorie dei prodotti, aggiorno la pagina!", {
                    hideProgressBar: false,
                    onClose: () => window.location.reload(false),
                });
            });
    };

    const resetProductInfo = () => {
        if (whatToChange) {
            setWhatToChange("");
            setProdName(productInfoSelected?.productName);
            setProdCategory({
                name: productInfoSelected?.type,
                id: categoryList?.find((categoryInfo) => categoryInfo.type === productInfoSelected?.type).productTypeID,
            });
            setProdPrice(productInfoSelected.fullPrice);
            setProdContent(
                (productInfoSelected?.props[0] && 1) ||
                    (productInfoSelected?.props[1] && 2) ||
                    (productInfoSelected?.props[2] && 3) ||
                    0
            );
        } else Modal.open("prodQuantity");
    };

    const productExists = () => {
        let exists = false;
        Object.keys(mergedProdProps.productsDisplayed).forEach((category) => {
            mergedProdProps.productsDisplayed[category].forEach((prod) => {
                prod.productName.toLowerCase() === prodName.toLowerCase() &&
                    prodName.toLowerCase() !== productInfoSelected?.productName.toLowerCase() &&
                    (exists = true);
            });
        });
        return exists;
    };

    const handleEditConfirm = async (confirmAction) => {
        setLoadingState(true);
        switch (whatToChange) {
            case "nome":
                if (levenshtein.get(productInfoSelected.productName.toLowerCase(), prodName.toLowerCase()) < 4) {
                    prodName !== productInfoSelected?.productName &&
                        editFunctions.editProduct({ productName: prodName }, productInfoSelected?.productID);
                    setWhatToChange("");
                } else {
                    setWhatToChange("askForNewProd");
                }
                setLoadingState(false);
                break;
            case "codice":
                prodInternalID !== productsEntries?.internalID[productInfoSelected.productID] &&
                    editFunctions.editProduct(
                        productInfoSelected,
                        productInfoSelected?.productID,
                        null,
                        prodInternalID
                    );
                setWhatToChange("");
                setLoadingState(false);
                break;
            case "categoria":
                prodCategory.name !== productInfoSelected?.type &&
                    editFunctions.editProduct({ type: prodCategory.id }, productInfoSelected?.productID);
                setWhatToChange("");
                setLoadingState(false);
                break;
            case "prezzo":
                prodPrice !== productInfoSelected?.fullPrice &&
                    editFunctions.editProduct({ fullPrice: prodPrice }, productInfoSelected?.productID);
                setWhatToChange("");
                setLoadingState(false);
                break;
            case "proprietà":
                let propsProd = [];
                for (var i = 0; i < 3; i++) propsProd.push(prodContent === i + 1);
                if (JSON.stringify(propsProd) !== JSON.stringify(productInfoSelected?.props)) {
                    propsProd = [];
                    prodContent === 1 && propsProd.push("ContainsPork");
                    prodContent === 2 && propsProd.push("IsVegetarian");
                    prodContent === 3 && propsProd.push("IsVegan");
                    editFunctions.editProduct({ props: propsProd }, productInfoSelected?.productID);
                }
                setWhatToChange("");
                setLoadingState(false);
                break;
            case "foto":
                if (photo)
                    addProductImage(photo?.webviewPath, productInfoSelected?.productID, true, () => {
                        setWhatToChange("");
                        setLoadingState(false);
                    });
                else {
                    setWhatToChange("");
                    setLoadingState(false);
                }
                break;
            case "askForNewProd":
                if (confirmAction === "edit") {
                    prodName !== productInfoSelected?.productName &&
                        editFunctions.editProduct({ productName: prodName }, productInfoSelected?.productID);
                    setWhatToChange("");
                    setLoadingState(false);
                } else {
                    Modal.open("insertNewProd", {
                        prodName: prodName,
                        prodInternalID: prodInternalID,
                        prodCategory: prodCategory,
                        prodPrice: prodPrice,
                        prodContent: prodContent,
                    });
                }
            default:
                break;
        }
    };

    const inputIsValid = () => {
        var isValid = false;
        switch (whatToChange) {
            case "nome":
                if (prodName.trim() !== "")
                    !productExists()
                        ? (isValid = true)
                        : toast.warning("Il prodotto esiste già!", { position: "top-center" });
                //document.getElementsByName("prod_name")[0].focus();
                break;
            case "categoria":
                prodCategory && prodCategory.name !== "" && (isValid = true);
                break;
            case "prezzo":
                prodPrice > 0 && (isValid = true);
                //document.getElementsByName("prod_price")[0].focus();
                break;
            case "proprietà":
                prodContent !== "" && (isValid = true);
                break;
            case "foto":
                prodContent !== "" && (isValid = true);
                break;
            default:
                isValid = true;
                break;
        }
        return isValid && !isLoading;
    };

    const ProdNameInput = ({ readOnly }) => {
        return (
            <>
                <Text textSize="subheader" p={{ l: "0.5rem" }}>
                    <b>Nome</b> del prodotto:
                </Text>
                <Input
                    type="text"
                    autoComplete="off"
                    name="prod_name"
                    placeholder="Inserisci qui il nome"
                    value={prodName}
                    bg="gray_light"
                    rounded="20px"
                    h="3.5rem"
                    p={{ x: "1rem" }}
                    m={{ x: "0.5rem" }}
                    border="3px solid"
                    borderColor="gray_light"
                    focusBorderColor={readOnly ? "text_light" : "gunmetal"}
                    readOnly={readOnly}
                    onChange={(e) => handleProdNameChange(e)}
                />
            </>
        );
    };

    const prodInternalIDInput = (
        <>
            <Text textSize="subheader" p={{ l: "0.5rem" }}>
                <b>Codice</b> del prodotto:
            </Text>
            <Input
                type="text"
                autoComplete="off"
                name="prod_cod"
                placeholder="Inserisci qui il codice"
                value={prodInternalID}
                bg="gray_light"
                rounded="20px"
                h="3.5rem"
                p={{ x: "1rem" }}
                m={{ x: "0.5rem" }}
                border="3px solid"
                borderColor="gray_light"
                focusBorderColor="gunmetal"
                onChange={(e) => handleProdInternalIDChange(e)}
            />
        </>
    );

    const ProdCategoryInput = () => {
        return (
            <>
                <Text textSize="subheader" p={{ l: "0.5rem" }}>
                    <b>Categoria</b> del prodotto:
                </Text>
                <Div p={{ x: "0.5rem" }}>
                    <Text p={{ b: "0.25rem" }}>Seleziona una categoria recente:</Text>
                    {categorySupplier?.map((category, index) => {
                        return (
                            <Tag
                                style={{ whiteSpace: "nowrap" }}
                                key={index}
                                p={{ x: "0.75rem", y: "0.25rem" }}
                                m={{ r: "0.5rem", y: "0.25rem" }}
                                bg={prodCategory.id === category.productTypeID ? "gunmetal" : "gray_light"}
                                rounded="20px"
                                textWeight={prodCategory.id === category.productTypeID && "bold"}
                                textSize="paragraph"
                                textColor={prodCategory.id === category.productTypeID ? "white" : "black"}
                                onClick={() =>
                                    setProdCategory({
                                        name: category.type,
                                        id: category.productTypeID,
                                    })
                                }
                                transition
                            >
                                {category.type}
                            </Tag>
                        );
                    })}
                </Div>
                <hr />
                <Div p={{ x: "0.5rem" }}>
                    <Text p={{ b: "0.25rem" }}>Oppure una fra queste:</Text>
                    {categoryNew?.map((category, index) => {
                        return (
                            <Tag
                                style={{ whiteSpace: "nowrap" }}
                                key={index}
                                p={{ x: "0.75rem", y: "0.25rem" }}
                                m={{ r: "0.5rem", y: "0.25rem" }}
                                bg={prodCategory.id === category.productTypeID ? "gunmetal" : "gray_light"}
                                rounded="20px"
                                textWeight={prodCategory.id === category.productTypeID && "bold"}
                                textSize="paragraph"
                                textColor={prodCategory.id === category.productTypeID ? "white" : "black"}
                                onClick={() =>
                                    setProdCategory({
                                        name: category.type,
                                        id: category.productTypeID,
                                    })
                                }
                                transition
                            >
                                {category.type}
                            </Tag>
                        );
                    })}
                </Div>
            </>
        );
    };

    const prodPriceInput = (
        <>
            <Text textSize="subheader" p={{ l: "0.5rem" }}>
                <b>Prezzo</b> del prodotto:
            </Text>
            <Input
                type="number"
                autoComplete="off"
                min="0"
                step="0.1"
                name="prod_price"
                value={prodPrice}
                onChange={(e) => handleProdPriceChange(e)}
                suffix={
                    <Text tag="span" textSize="subheader" pos="absolute" top="0.75rem" right="0.75rem">
                        €
                    </Text>
                }
                placeholder="Quanto costa di listino?"
                bg="gray_light"
                rounded="20px"
                h="3.5rem"
                p={{ x: "1rem" }}
                m={{ x: "0.5rem" }}
                border="3px solid"
                borderColor="gray_light"
                focusBorderColor="gunmetal"
            />
        </>
    );

    const ProdContentInput = () => {
        return (
            <Div p={{ x: "0.5rem" }}>
                <Text textSize="subheader">
                    <b>Proprietà</b> del prodotto:
                </Text>
                <Text p={{ b: "0.25rem" }}>Seleziona una proprietà del prodotto:</Text>
                {["Nessuna", "Contiene maiale", "Vegetariano", "Vegano"].map((prop, index) => {
                    return (
                        <Tag
                            style={{ whiteSpace: "nowrap" }}
                            key={index}
                            p={{ x: "0.75rem", y: "0.25rem" }}
                            m={{ r: "0.5rem", y: "0.25rem" }}
                            bg={prodContent === index ? "gunmetal" : "gray_light"}
                            rounded="20px"
                            textWeight={prodContent === index && "bold"}
                            textSize="paragraph"
                            textColor={prodContent === index ? "white" : "black"}
                            onClick={() => setProdContent(index)}
                            transition
                        >
                            <>
                                <Text m={{ r: "0.25rem" }}>{prop}</Text>
                                {index > 0 && (
                                    <FontAwesomeIcon
                                        icon={
                                            index === 1 ? faPiggyBank : index === 2 ? faLeaf : index === 3 && faSeedling
                                        }
                                        size="1x"
                                        color={prodContent === index ? "gunmetal" : "gray_light"}
                                    />
                                )}
                            </>
                        </Tag>
                    );
                })}
            </Div>
        );
    };

    const ProdPhotoInput = () => {
        return (
            <>
                <Text textSize="subheader" p={{ l: "0.5rem" }}>
                    <b>Foto</b> del prodotto:
                </Text>
                <Div
                    pos="relative"
                    bg={(!productImage.url || productImage.toBeDeleted) && !photo && "gunmetal"}
                    bgImg={
                        photo
                            ? `'${photo.webviewPath}'`
                            : productImage.url && !productImage.toBeDeleted && `'${productImage.url}'`
                    }
                    bgSize={((productImage.url && !productImage.toBeDeleted) || photo) && "cover"}
                    bgPos={((productImage.url && !productImage.toBeDeleted) || photo) && "center"}
                    m={{ x: "auto", b: "0.5rem" }}
                    d="flex"
                    align="center"
                    justify="center"
                    w="7rem"
                    h="7rem"
                    rounded="25px"
                    p="0.5rem"
                    textColor="white"
                    textAlign="center"
                    onClick={() => takePhoto()}
                >
                    {(!productImage.url || productImage.toBeDeleted) && !photo ? (
                        <Div>
                            <Text>
                                <FontAwesomeIcon icon={faCamera} color="white" size="2x" />
                            </Text>
                            Inserisci
                        </Div>
                    ) : (
                        <>
                            {!productImage.url && (
                                <Div
                                    pos="absolute"
                                    bg={theme.colors.primary_light}
                                    left="0"
                                    top="0"
                                    p={{ x: "0.75rem" }}
                                    rounded={theme.rounded["pillAltRev-20px"]}
                                    style={{ backdropFilter: "blur(10px)", whiteSpace: "nowrap" }}
                                    textColor="white"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (photo) resetPhoto();
                                        else if (productImage.url)
                                            //non utilizzato al momento
                                            setProductImage((prevState) => ({ ...prevState, toBeDeleted: true }));
                                    }}
                                >
                                    Rimuovi
                                </Div>
                            )}
                            <Div
                                pos="absolute"
                                bg={theme.colors.gunmetal}
                                right="0"
                                bottom="0"
                                p={{ x: "0.75rem" }}
                                rounded={theme.rounded["pillAlt-20px"]}
                                style={{ backdropFilter: "blur(10px)", whiteSpace: "nowrap" }}
                                textColor="white"
                            >
                                Cambia
                            </Div>
                        </>
                    )}
                </Div>
            </>
        );
    };

    const SelectWhatToChange = () => {
        return (
            <Div m={{ x: "0.5rem" }}>
                <Paragraph title="Cosa vorresti modificare?">
                    <Row p={{ t: "0.5rem" }}>
                        <Col size={{ xs: "6", md: "3" }} p={{ b: "0.5rem", x: "0.25rem" }}>
                            <Div
                                d="flex"
                                justify="space-between"
                                align="center"
                                w="100%"
                                rounded="20px"
                                bg={theme.colors.gray_light}
                                p="1rem"
                                onClick={() => setWhatToChange("nome")}
                            >
                                <Text p={{ r: "0.5rem" }} textSize="subheader">
                                    Nome
                                </Text>
                                <FontAwesomeIcon icon={faSpellCheck} size="2x" color="dark" />
                            </Div>
                        </Col>
                        {supplierData?.firestore?.needsProdInternalID && (
                            <Col size={{ xs: "6", md: "3" }} p={{ b: "0.5rem", x: "0.25rem" }}>
                                <Div
                                    d="flex"
                                    justify="space-between"
                                    align="center"
                                    w="100%"
                                    rounded="20px"
                                    bg={theme.colors.gray_light}
                                    p="1rem"
                                    onClick={() => setWhatToChange("codice")}
                                >
                                    <Text p={{ r: "0.5rem" }} textSize="subheader">
                                        Codice
                                    </Text>
                                    <FontAwesomeIcon icon={faBarcode} size="2x" color="dark" />
                                </Div>
                            </Col>
                        )}
                        <Col size={{ xs: "6", md: "3" }} p={{ b: "0.5rem", x: "0.25rem" }}>
                            <Div
                                d="flex"
                                justify="space-between"
                                align="center"
                                w="100%"
                                rounded="20px"
                                bg={theme.colors.gray_light}
                                p="1rem"
                                onClick={() => setWhatToChange("categoria")}
                            >
                                <Text p={{ r: "0.5rem" }} textSize="subheader">
                                    Categoria
                                </Text>
                                <FontAwesomeIcon icon={faTh} size="2x" color="dark" />
                            </Div>
                        </Col>
                        <Col size={{ xs: "6", md: "3" }} p={{ b: "0.5rem", x: "0.25rem" }}>
                            <Div
                                d="flex"
                                justify="space-between"
                                align="center"
                                w="100%"
                                rounded="20px"
                                bg={theme.colors.gray_light}
                                p="1rem"
                                onClick={() => {
                                    productInfoSelected.surplus
                                        ? toast.info("Non posso modificare il prezzo mentre il prodotto è online!", {
                                              position: "top-center",
                                          })
                                        : setWhatToChange("prezzo");
                                }}
                            >
                                <Text
                                    p={{ r: "0.5rem" }}
                                    textSize="subheader"
                                    textColor={productInfoSelected.surplus && theme.colors.text_light}
                                >
                                    Prezzo
                                </Text>
                                <FontAwesomeIcon
                                    icon={faEuroSign}
                                    size="2x"
                                    color={productInfoSelected.surplus ? theme.colors.text_light : "dark"}
                                />
                            </Div>
                        </Col>
                        <Col size={{ xs: "6", md: "3" }} p={{ b: "0.5rem", x: "0.25rem" }}>
                            <Div
                                d="flex"
                                justify="space-between"
                                align="center"
                                w="100%"
                                rounded="20px"
                                bg={theme.colors.gray_light}
                                p="1rem"
                                onClick={() => setWhatToChange("proprietà")}
                            >
                                <Text p={{ r: "0.5rem" }} textSize="subheader">
                                    Proprietà
                                </Text>
                                <FontAwesomeIcon icon={faTasks} size="2x" color="dark" />
                            </Div>
                        </Col>
                        <Col size={{ xs: "6", md: "3" }} p={{ b: "0.5rem", x: "0.25rem" }}>
                            <Div
                                d="flex"
                                justify="space-between"
                                align="center"
                                w="100%"
                                rounded="20px"
                                bg={theme.colors.gray_light}
                                p="1rem"
                                onClick={() => setWhatToChange("foto")}
                            >
                                <Text p={{ r: "0.5rem" }} textSize="subheader">
                                    Foto
                                </Text>
                                <FontAwesomeIcon icon={faImage} size="2x" color="dark" />
                            </Div>
                        </Col>
                    </Row>
                </Paragraph>
            </Div>
        );
    };

    const AskForNewProd = () => {
        return (
            <Div m={{ x: "0.5rem" }}>
                {<ProdNameInput readOnly={true} />}
                <Text textSize="subheader" p={{ t: "0.5rem" }}>
                    Che ne dici di <BoldText textColor={theme.colors.gunmetal}>creare</BoldText> un{" "}
                    <BoldText textColor={theme.colors.gunmetal}>nuovo prodotto</BoldText>? Questa modifica ci sembra
                    importante
                </Text>
                <SecondaryButton
                    text="MODIFICA comunque"
                    textSize="title"
                    textColor={theme.colors.gunmetal}
                    textWeight="bold"
                    action={() => handleEditConfirm("edit")}
                />
            </Div>
        );
    };

    return (
        <>
            <Div p={{ x: "1rem", t: "0.5rem" }}>
                <Text p={{ l: "0.5rem" }} textSize="title" textWeight="bold">
                    Modifica prodotto
                </Text>
                <hr style={{ border: "1px solid #E1E4E8" }} />
                <Text p={{ l: "0.5rem" }} textWeight="bold" textSize="title" textColor={theme.colors.gunmetal}>
                    {productInfoSelected?.type?.toUpperCase()}
                </Text>
                <Div d="flex" align="center" p={{ b: "0.25rem", l: "0.5rem" }}>
                    {whatToChange !== "foto" && <ProductImage productInfo={productInfoSelected} />}
                    {whatToChange === "foto" && <ProductImage productInfo={productInfoSelected} />}
                    <Div style={{ flexBasis: "85%" }}>
                        <Text textSize="subheader" textColor="dark" style={{ lineHeight: "25px" }}>
                            {productInfoSelected.productName}
                        </Text>
                        <Div d="flex" align="center" justify="space-between">
                            <Div>
                                {"fullPrice" in productInfoSelected && (
                                    <NumberFormat
                                        value={productInfoSelected.fullPrice}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={"€"}
                                        renderText={(fullPrice) => (
                                            <NumberFormat
                                                value={productInfoSelected.price}
                                                displayType={"text"}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                prefix={"€"}
                                                renderText={(price) => (
                                                    <Text textSize="subheader">
                                                        <Text
                                                            tag="span"
                                                            textColor={
                                                                productInfoSelected.status === undefined ||
                                                                !(
                                                                    "fresh" in productInfoSelected &&
                                                                    productInfoSelected.fresh.available > 0
                                                                )
                                                                    ? "gray"
                                                                    : "accent"
                                                            }
                                                        >
                                                            {fullPrice}
                                                        </Text>
                                                        {(productInfoSelected.surplus ||
                                                            productInfoSelected.unconfirmed) &&
                                                            " | "}
                                                        <Text tag="span" textColor="primary">
                                                            {(productInfoSelected.surplus ||
                                                                productInfoSelected.unconfirmed) &&
                                                                price}
                                                        </Text>
                                                    </Text>
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </Div>
                            {productsEntries?.internalID[productInfoSelected.productID] && (
                                <Text
                                    textSize="paragraph"
                                    textColor="dark"
                                    bg="gunmetal_light"
                                    rounded="circle"
                                    p={{ x: "0.5rem" }}
                                    textAlign="right"
                                >
                                    {productsEntries?.internalID[productInfoSelected.productID]}
                                </Text>
                            )}
                        </Div>
                    </Div>
                </Div>
                <Div m="auto" p={{ t: "0.25rem" }}>
                    <hr style={{ border: "1px solid #E1E4E8" }} />
                    {whatToChange === "nome" ? (
                        ProdNameInput({ readOnly: false })
                    ) : whatToChange === "codice" ? (
                        prodInternalIDInput
                    ) : whatToChange === "categoria" ? (
                        <ProdCategoryInput />
                    ) : whatToChange === "prezzo" ? (
                        prodPriceInput
                    ) : whatToChange === "proprietà" ? (
                        <ProdContentInput />
                    ) : whatToChange === "foto" ? (
                        <ProdPhotoInput />
                    ) : whatToChange === "askForNewProd" ? (
                        <AskForNewProd />
                    ) : (
                        <SelectWhatToChange />
                    )}
                </Div>
            </Div>
            <Row m={{ x: "2rem", t: "1rem" }}>
                <Col p="0">
                    <Button
                        onClick={whatToChange === "askForNewProd" ? () => setWhatToChange("nome") : resetProductInfo}
                        h="3.5rem"
                        m="auto"
                        bg="white"
                        textColor="gunmetal"
                    >
                        <Text textSize="title">Indietro</Text>
                    </Button>
                </Col>
                {whatToChange && (
                    <Col p="0">
                        <Button
                            onClick={() => handleEditConfirm(whatToChange === "askForNewProd" ? "create" : "edit")}
                            bg="gunmetal"
                            shadow="3"
                            h="3.5rem"
                            p={{ x: "1.5rem" }}
                            isLoading={isLoading}
                            disabled={!inputIsValid()}
                            rounded="20px"
                            textColor="white"
                            m="auto"
                        >
                            <Text textSize="title">{whatToChange === "askForNewProd" ? "CREA" : "CONFERMA"}</Text>
                        </Button>
                    </Col>
                )}
            </Row>
        </>
    );
}

export default EditProductModal;
