import { Div, Text, Button } from "atomize";
import React, { useContext } from "react";
import theme from "../../appearance/theme";
import { authContext } from "../../providers/auth.provider";
import { ordersContext } from "../../providers/orders.provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt, faStore, faUtensils } from "@fortawesome/free-solid-svg-icons";

function AppBottomMenu(props) {
    const { tab, setTab } = useContext(authContext);
    const { activeOrders } = useContext(ordersContext);

    return (
        <>
            <Div h="4rem" pos="relative" />
            <Div
                pos="fixed"
                w="100%"
                zIndex="52"
                bg="white"
                p={{
                    t: "0.25rem",
                    b: "calc(0rem + env(safe-area-inset-bottom))",
                    x: "0.5rem",
                }}
                bottom="0"
                border={{ t: "1px solid" }}
                borderColor={theme.colors.gray_light}
                transition
            >
                <Div d="flex" justify="space-around">
                    <Button w="33.33%" bg="white" h="fit-content" p="0" onClick={() => setTab("home")}>
                        <Div m={{ t: "0.25rem" }}>
                            <Div
                                d="flex"
                                justify="center"
                                align="center"
                                w="fit-content"
                                bg={tab === "home" && theme.colors.primary_blur}
                                rounded="circle"
                                p={{ x: "1.5rem", y: "0.25rem" }}
                                m="auto"
                                transition
                            >
                                <FontAwesomeIcon
                                    icon={faStore}
                                    size="2x"
                                    color={tab === "home" ? theme.colors.primary : theme.colors.gunmetal}
                                />
                            </Div>
                            <Text
                                textWeight={tab === "home" && "bold"}
                                textColor={tab === "home" ? "primary" : "gunmetal"}
                            >
                                Il mio locale
                            </Text>
                        </Div>
                    </Button>
                    <Button w="33.33%" bg="white" h="fit-content" p="0" onClick={() => setTab("products")}>
                        <Div m={{ t: "0.25rem" }}>
                            <Div
                                d="flex"
                                justify="center"
                                align="center"
                                bg={tab === "products" && theme.colors.primary_blur}
                                rounded="circle"
                                p={{ x: "1.5rem", y: "0.25rem" }}
                                transition
                            >
                                <FontAwesomeIcon
                                    icon={faUtensils}
                                    size="2x"
                                    color={tab === "products" ? theme.colors.primary : theme.colors.gunmetal}
                                />
                            </Div>
                            <Text
                                textWeight={tab === "products" && "bold"}
                                textColor={tab === "products" ? "primary" : "gunmetal"}
                            >
                                Prodotti
                            </Text>
                        </Div>
                    </Button>
                    <Button w="33.33%" bg="white" h="fit-content" p="0" onClick={() => setTab("orders")}>
                        <Div m={{ t: "0.25rem" }}>
                            <Div
                                d="flex"
                                justify="center"
                                align="center"
                                bg={tab === "orders" && theme.colors.primary_blur}
                                rounded="circle"
                                p={{ x: "1.5rem", y: "0.25rem" }}
                                transition
                                pos="relative"
                            >
                                {activeOrders > 0 && (
                                    <Div
                                        pos="absolute"
                                        top="0"
                                        right="0.5rem"
                                        w="1.5rem"
                                        h="1.5rem"
                                        bg="warning900"
                                        rounded="circle"
                                        textColor="white"
                                    >
                                        {activeOrders}
                                    </Div>
                                )}
                                <FontAwesomeIcon
                                    icon={faReceipt}
                                    size="2x"
                                    color={tab === "orders" ? theme.colors.primary : theme.colors.gunmetal}
                                />
                            </Div>
                            <Text
                                textWeight={tab === "orders" && "bold"}
                                textColor={tab === "orders" ? "primary" : "gunmetal"}
                            >
                                Ordini
                            </Text>
                        </Div>
                    </Button>
                </Div>
            </Div>
        </>
    );
}

export default AppBottomMenu;
