import { Div } from "atomize";
import React, { useContext } from "react";
import { authContext } from "../../providers/auth.provider";
import ProductsType from "../productsType/ProductsType";
import SearchProducts from "../searchProducts/SearchProducts";

//Padre: ProductsContainer
function ProductsFilter(props) {
    const { setLog } = useContext(authContext);

    return (
        <>
            <Div h={props.searching ? "7rem" : "3.5rem"} transition pos="relative" />
            <Div
                p={{
                    t: props.searching ? "calc(env(safe-area-inset-top) + 4rem)" : "env(safe-area-inset-top)",
                }}
                bg="primary_ulight"
                pos="fixed"
                top="0"
                w="100vw"
                zIndex="2"
                transition
            >
                <ProductsType searching={props.searching} productsType={props.productsType} />
            </Div>
            <Div
                p={{
                    t: props.searching && "calc(0.5rem + env(safe-area-inset-top))",
                    b: "0",
                    x: "0.5rem",
                }}
                bg="primary_ulight"
                pos="fixed"
                w="100%"
                zIndex={props.searching ? "3" : "1"}
                transition
                top={props.searching ? "0" : "calc(env(safe-area-inset-top) - 8rem)"}
            >
                <SearchProducts setLog={setLog} productsType={props.productsType} searching={props.searching} />
            </Div>
        </>
    );
}

export default ProductsFilter;
