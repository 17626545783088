import React, { useContext, useEffect, useState } from "react";
import { Div } from "atomize";
import ProductsTab from "../productsTab/ProductsTab";
import Login from "../authentication/Authentication";
import AppBottomMenu from "../appBottomMenu/AppBottomMenu";
import HomeTab from "../homeTab/HomeTab";
import eruda from "eruda";
import OrdersTab from "../ordersTab/OrdersTab";
import Onboarding from "../onboarding/Onboarding";
import { Plugins, FilesystemDirectory } from "@capacitor/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import config, { cacheFolder } from "../../settings/config";
import smoothscroll from "smoothscroll-polyfill";
import { authContext } from "../../providers/auth.provider";
import SModal from "../sModal/SModal";
import { modalContext } from "../../providers/modal.provider";
import {
    addDeliveryHourToBeConfirmedListener,
    getArchivedProducts,
    getDeliveryHourToBeConfirmed,
    getSupplierDataFromFirestore,
} from "../../services/firebase.service";
import apiService, { getSupplierOpenHourFromAPI } from "../../services/api.service";
import { getDeviceInfo, getItaDayNameFromEng, getRemoteValue } from "../../services/func.utils";
import { compareVersions } from "compare-versions";
import ToastWithAction from "../toast/ToastWithAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import theme from "../../appearance/theme";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import it from "date-fns/locale/it";
const { Filesystem, Storage, PushNotifications } = Plugins;

function App() {
    const [isOnboarded, setOnboarding] = useState(false);

    const { logged, supplierData, setLog, setSupplierData, setTab, setArchivedProducts } = useContext(authContext);
    const Modal = useContext(modalContext);

    useEffect(() => {
        createCacheFolder();
        getOnboardingState();
        !config["isDeploy"] && initConsole();
        handleNotifationOnClick();
        checkAppVersion();

        registerLocale("it", it);
        setDefaultLocale("it");
    }, []);

    useEffect(() => {
        if (supplierData?.supplierID) {
            getArchivedProductsFromFirestore();
            getSupplierOpenHour();
            handleGetSupplierDataFromFirestore();
        }
    }, [supplierData?.supplierID]);

    // kick off the polyfill!
    smoothscroll.polyfill();

    const getOnboardingState = async () => {
        const ret = await Storage.get({ key: "isOnboarded" });
        const isOnboarded = ret.value;
        isOnboarded && setOnboarding(true);
    };

    const handleNotifationOnClick = () => {
        if (config.type === "app") {
            PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
                notification.notification?.data?.event === "newOrder" && setTab("orders");
                notification.notification?.data?.event === "deletedOrder" && setTab("orders");
            });
        }
    };

    const getArchivedProductsFromFirestore = async () => {
        const archivedProducts = await getArchivedProducts(supplierData?.supplierID);
        setArchivedProducts(archivedProducts ?? {});
    };

    const handleGetSupplierDataFromFirestore = async () => {
        const supplierDataFromFirestore = await getSupplierDataFromFirestore(supplierData?.supplierID);
        setSupplierData((prevState) => ({ ...prevState, firestore: supplierDataFromFirestore }));
    };

    function initConsole() {
        let el = document.createElement("div");
        document.body.appendChild(el);

        eruda.init({
            container: el,
            tool: ["console", "elements", "network"],
        });
    }

    const createCacheFolder = async () => {
        Filesystem.mkdir({
            directory: FilesystemDirectory.Cache,
            path: cacheFolder,
        }).catch((error) => console.warn(error));
    };

    const getSupplierOpenHour = async () => {
        const supplierOpenHourFromFirebase = await getDeliveryHourToBeConfirmed(supplierData?.supplierID.toString());
        if (
            !supplierOpenHourFromFirebase ||
            Object.keys(supplierOpenHourFromFirebase).length === 0 ||
            supplierOpenHourFromFirebase?.confirmed
        )
            getSupplierOpenHourFromAPI()
                .then((response) => {
                    if (apiService.checkRedirectLogin(response.request.responseURL)) {
                        setLog(false);
                    } else {
                        let daysAndHours = response.data?.split("|");
                        let days = [];
                        let hours = [];
                        daysAndHours &&
                            daysAndHours.forEach((d) => {
                                days.push(d.split(":")[0].trim());
                                hours.push(d.substring(d.indexOf(":") + 1).trim());
                            });
                        setSupplierData((prevState) => ({
                            ...prevState,
                            supplierOpenHour: { days: days, hours: hours, confirmed: true },
                        }));
                    }
                })
                .catch((error) => {
                    console.error("[API] Get next expiration ", error);
                    toast.error("C'è stato un problema ad ottenere gli orari di ritiro!");
                });
        else {
            let supplierOpenHourTmp = supplierOpenHourFromFirebase?.pickUpHours;
            supplierOpenHourTmp = JSON.parse(supplierOpenHourTmp);
            let days = [];
            let hours = [];
            Object.keys(supplierOpenHourTmp).forEach((day) => {
                days.push(getItaDayNameFromEng(day));
                let dayHours = "";
                supplierOpenHourTmp[day].forEach((hour, index) => {
                    if (index > 0) dayHours += ", ";
                    dayHours += `${hour.deliveryHourStart}-${hour.deliveryHourEnd}`;
                });
                hours.push(dayHours);
            });
            if (days[0] === "Dom") {
                days.push(days.shift());
                hours.push(hours.shift());
            }
            setSupplierData((prevState) => ({
                ...prevState,
                supplierOpenHour: { days: days, hours: hours, confirmed: false },
            }));
            addDeliveryHourToBeConfirmedListener(supplierData?.supplierID.toString(), (confirmed) => {
                setSupplierData((prevState) => ({
                    ...prevState,
                    supplierOpenHour: { ...prevState.supplierOpenHour, confirmed: confirmed },
                }));
                getSupplierOpenHour();
            });
        }
    };

    const checkAppVersion = async () => {
        let deviceInfo = await getDeviceInfo();
        !deviceInfo?.appVersion ||
            (compareVersions(JSON.parse(getRemoteValue("app_version")).recommended, deviceInfo?.appVersion) === 1 &&
                toast(
                    <ToastWithAction
                        text="Nuova versione disponibile!"
                        actionText={
                            <>
                                AGGIORNA{" "}
                                <FontAwesomeIcon
                                    style={{ marginLeft: "0.25rem" }}
                                    icon={faArrowAltCircleDown}
                                    color={theme.colors.green}
                                    size="lg"
                                />
                            </>
                        }
                        action={async () => {
                            toast.dismiss();
                            deviceInfo?.platform === "ios"
                                ? await Plugins.App.openUrl({
                                      url: "https://apps.apple.com/it/app/squiseat-store-per-ristoratori/id1544226301",
                                  })
                                : await Plugins.App.openUrl({
                                      url: "https://play.google.com/store/apps/details?id=com.squiseat.store",
                                  });
                        }}
                    />
                ));
    };

    return (
        <>
            {!isOnboarded && JSON.parse(getRemoteValue("onboarding_enabled")) ? (
                <Onboarding
                    handleOnboard={async () => {
                        setOnboarding(true);
                        await Storage.set({
                            key: "isOnboarded",
                            value: "true",
                        });
                    }}
                />
            ) : (
                <Div
                    className="app_route"
                    bg="primary_ulight"
                    p="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
                >
                    {!(logged === null) && !logged && <Login setLog={setLog} />}
                    {logged && (
                        <>
                            <HomeTab
                                logged={logged}
                                setLog={setLog}
                                supplierData={supplierData}
                                setSupplierData={setSupplierData}
                            />
                            {supplierData && (
                                <>
                                    <ProductsTab />
                                    <OrdersTab />
                                </>
                            )}
                            <AppBottomMenu />
                        </>
                    )}
                </Div>
            )}
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <SModal
                isOpen={Modal.isOpen}
                onClose={() => Modal.close()}
                type={Modal.type}
                prev={Modal.prev}
                data={Modal.data}
                action={Modal.action}
            />
        </>
    );
}

export default App;
