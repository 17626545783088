import React from "react";
import { Div, Tag } from "atomize";

ProductTags.defaultProps = {
    p: { x: "0.75rem", y: "0.25rem" },
};

function ProductTags(props) {
    let state = null;
    if (props.productInfo.unconfirmed || props.productInfo.state === "unconfirmed" || props.productInfo?.quantity > 0)
        state = "unconfirmed";
    else if (props.productInfo.surplus || props.productInfo.state === "online") state = "online";

    let valueSurplus;

    if (state === "online") {
        if (props.productInfo.surplus) valueSurplus = props.productInfo.surplus?.available;
        else valueSurplus = 0;
    } else if (state === "unconfirmed" && props.productInfo.unconfirmed?.quantity > 0) {
        valueSurplus = props.productInfo.unconfirmed?.quantity;
    } else if (props.productInfo?.quantity > 0) valueSurplus = props.productInfo?.quantity;

    const valueTextSurplus =
        valueSurplus === 0 ? "ESAURITO" : valueSurplus > 1 ? valueSurplus + " porzioni" : valueSurplus + " porzione";

    const soldAt =
        props.productInfo?.soldAt || props?.productInfo?.unconfirmed?.soldAt || props?.productInfo?.surplus?.soldAt;

    return (
        <Div d="flex">
            {(props.productInfo?.unconfirmed?.quantity > 0 ||
                props.productInfo?.quantity > 0 ||
                (props.productInfo?.surplus?.available >= 0 &&
                    (props.productInfo?.surplus?.quantity === undefined ||
                        props.productInfo?.surplus?.quantity > 0))) && (
                <Tag
                    bg={
                        state === "online"
                            ? valueSurplus > 0
                                ? soldAt <= 0.5
                                    ? "primary"
                                    : "accent"
                                : "white"
                            : soldAt <= 0.5
                            ? "primary_light"
                            : "accent_light"
                    }
                    textColor={state === "online" && valueSurplus === 0 ? "black" : "white"}
                    p={{ x: "0.75rem", y: "0.25rem" }}
                    m={props.m}
                    textSize="body"
                    rounded="circle"
                    style={{ whiteSpace: "nowrap" }}
                    border={state === "online" && valueSurplus === 0 && "3px solid"}
                    borderColor={state === "online" && valueSurplus === 0 && soldAt <= 0.5 ? "primary" : "accent"}
                >
                    {valueTextSurplus}
                </Tag>
            )}
        </Div>
    );
}

export default ProductTags;
