import { Div } from "atomize";
import React, { useContext } from "react";
import ProductsFilter from "../productsFilter/ProductsFilter";
import Products from "../products/Products";
import { productsContext } from "../../providers/products.provider";
import { authContext } from "../../providers/auth.provider";

function ProductsContainer(props) {
    const { mergedProdProps, searching, productsType } = useContext(productsContext);
    const { tab } = useContext(authContext);

    return (
        <>
            {tab === "products" && (
                <Div>
                    <ProductsFilter searching={searching} productsType={productsType} />
                    {mergedProdProps?.productsDisplayed ? (
                        <Products
                            productsType={productsType}
                            searching={searching}
                            pProducts={Object.keys(mergedProdProps?.unconfirmed).length > 0}
                        />
                    ) : (
                        <Div p={{ l: "1rem" }}>
                            <div className="placeholder"></div>
                            <div className="placeholder"></div>
                            <div className="placeholder"></div>
                            <div className="placeholder"></div>
                            <div className="placeholder"></div>
                        </Div>
                    )}
                </Div>
            )}
        </>
    );
}

export default ProductsContainer;
