import PropTypes from "prop-types";
import React, { memo } from "react";
import { Text } from "atomize";
import theme from "../../appearance/theme";

BoldText.propTypes = {
    action: PropTypes.any,
    children: PropTypes.any,
    textColor: PropTypes.string,
    textDecor: PropTypes.string,
};

BoldText.defaultProps = {
    textColor: theme.colors.black,
    textDecor: "",
    action: null,
};

function BoldText({ children, textColor, action, textDecor, ...others }) {
    return (
        <Text tag="span" textColor={textColor} textWeight="bold" onClick={action} textDecor={textDecor} {...others}>
            {children}
        </Text>
    );
}

export default memo(BoldText);
