import { Div, Text, Button } from "atomize";
import React from "react";
import Ul from "../atoms/Ul";
import Li from "../atoms/Li";
import BoldText from "../boldText/BoldText";

function TermsAndConditionsModal(props) {
    return (
        <>
            <Div p={{ x: "1rem", t: "0.5rem" }}>
                <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="title" textAlign="center" textWeight="bold">
                    Termini e condizioni generali Squiseat srl
                </Text>
                <Div maxH="400px" p={{ x: "0.5rem", t: "0.5rem" }} style={{ overflowY: "auto", overflowX: "hidden" }}>
                    <Text textAlign="center">TRA</Text>
                    <Text>
                        La società cliente, così come identificata in fase di acquisizione dati, i quali sono mostrati
                        all’interno dell’applicazione, nel profilo del cliente stesso,
                    </Text>
                    <Text textAlign="right">(di seguito "Ristorante")</Text>
                    <Text textAlign="center">E</Text>
                    <Text>
                        <BoldText>SQUISEAT S.R.L.</BoldText> con sede legale in Bologna (BO), alla Via San Petronio
                        Vecchio 48/2 (CAP 40125), Codice fiscale e n.iscr. al Registro Imprese 03825131208, in persona
                        del legale pro tempore sig. Alberto Drusiani,
                    </Text>
                    <Text textAlign="right">(di seguito "SQUISEAT")</Text>
                    <Text textAlign="center" textWeight="bold">
                        PREMESSO CHE
                    </Text>
                    <Ul>
                        <Li>
                            Il Ristorante ha manifestato il proprio interesse a ridurre lo spreco alimentare del proprio
                            locale attraverso la collocazione delle eccedenze giornaliere nonché ad allargare la propria
                            rete di clienti e la propria visibilità;
                        </Li>
                        <Li>
                            SQUISEAT è una Startup Italiana che nasce con l’idea di recuperare i prodotti alimentari in
                            eccedenze/rimanenza/scadenza/invenduti che risultano ancora vendibili e di collocarli
                            attraverso prezzi vantaggiosi a consumatori finali riducendo di conseguenza ogni tipo di
                            spreco alimentare.
                        </Li>
                        <Li>
                            Per realizzare tale obiettivo, SQUISEAT si avvale di propri Canali d'Ordine ove raccoglie le
                            offerte giornaliere dei Ristoranti affiliati mettendole a disposizione degli utenti/clienti
                            i quali possono ordinare i beni e concordare il ritiro presso il relativo punto vendita.
                        </Li>
                    </Ul>
                    <Text textAlign="center" textWeight="bold">
                        TUTTO CIÒ PREMESSO
                    </Text>
                    <Text m={{ b: "1rem" }}>
                        conformemente alle precedenti dichiarazioni che costituiscono parte integrante del presente
                        Contratto, ai termini ed alle condizioni qui di seguito stabilite e nel reciproco interesse, le
                        Parti concordano quanto segue:
                    </Text>

                    <BoldText>
                        Art. 1 <BoldText textDecor="underline">DEFINIZIONI</BoldText>
                    </BoldText>
                    <Text>
                        1.1. Nel presente Contratto, i termini e le espressioni che seguono avranno, salvo dove
                        diversamente specificato, i seguenti significati:
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Canale d’ordine”:
                        </Text>{" "}
                        l’applicazione mobile, ed ogni altra piattaforma con cui SQUISEAT mette a disposizione dei
                        Clienti i prodotti indicati dai Ristoranti affiliati e con cui riceve e trasmette i relativi
                        Ordini;
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Cliente”:
                        </Text>{" "}
                        un utente che ha utilizzato un Canale d’Ordine di SQUISEAT per effettuare un Ordine di beni di
                        un Ristorante affiliato;
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Commissione”:
                        </Text>{" "}
                        la remunerazione dovuta a SQUISEAT per gli affari andati a buon fine e addebitata al Ristorante
                        sull’Importo Netto di ciascun Ordine. L’importo della stessa è indicato al punto nr. 5 del
                        presente accordo;
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Importo Netto dell’ordine”:
                        </Text>{" "}
                        la base imponibile al netto dell’ IVA dell’ammontare addebitato dal Ristorante al Cliente per
                        ciascun Ordine;
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Locali”:
                        </Text>{" "}
                        il punto vendita ove il Ristorante provvede alla preparazione dei prodotti alimentari da offrire
                        ai Clienti;
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Ordine”:
                        </Text>{" "}
                        un ordine relativo ai beni del Ristorante ed effettuato da un Cliente mediante i Canali d’Ordine
                        di SQUISEAT. Lo stesso può avvenire esclusivamente con Carta o con altri strumenti elettronici
                        di pagamento e quindi incassato direttamente da SQUISEAT per conto del Ristorante come indicato
                        al punto nr. 6 del presente accordo;
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Parti”:
                        </Text>{" "}
                        Il Ristorante e SQUISEAT;
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Termini e condizioni generali”:
                        </Text>{" "}
                        i presenti termini e condizioni generali di SQUISEAT.
                    </Text>
                    <Text>
                        <Text tag="span" textDecor="underline" style={{ fontStyle: "italic" }}>
                            “Trimestre solare”:
                        </Text>{" "}
                        i seguenti periodi di tempo di ogni anno:
                    </Text>
                    <Ul>
                        <Li>Dal 1 gennaio al 31 marzo;</Li>
                        <Li>Dal 1 aprile al 30 giugno;</Li>
                        <Li>Dal 1 luglio al 30 settembre;</Li>
                        <Li>Dal 1 ottobre al 31 dicembre;</Li>
                    </Ul>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.2 <BoldText textDecor="underline">OGGETTO DEL CONTRATTO</BoldText>
                    </Text>
                    <Text>
                        2.1. Per tutta la durata del presente accordo il Ristorante nomina irrevocabilmente ed autorizza
                        SQUISEAT, che contestualmente accetta, ad agire come proprio mandatario per organizzare in suo
                        nome e per conto la vendita dei prodotti mediante i propri Canali d’ Ordine.
                    </Text>
                    <Text>
                        2.2. SQUISEAT organizza esclusivamente la vendita dei prodotti mediante i propri Canali d'Ordine
                        per conto del Ristorante e non è pertanto in essere alcun rapporto contrattuale tra SQUISEAT e
                        il Cliente in relazione ai prodotti o alla loro vendita. SQUISEAT è esente da responsabilità in
                        relazione ai Prodotti o all’adempimento del contratto concluso tra il Ristorante e il Cliente.
                    </Text>
                    <Text>
                        2.3. SQUISEAT svolgerà la propria attività con piena autonomia di azione, di tempo, di
                        organizzazione, senza alcun vincolo, né di subordinazione né di dipendenza, né di agenzia, né di
                        associazione o società o altro rapporto stabile di collaborazione con il Ristorante. In
                        particolare, si esclude espressamente la sussistenza di un rapporto di agenzia, non avendo
                        SQUISEAT alcun obbligo di promuovere gli affari per conto del Ristorante.
                    </Text>
                    <Text>
                        2.4. SQUISEAT si limita a incassare il prezzo d’acquisto dei Prodotti per conto del Ristorante
                        per come specificato nel punto nr. 6 del presente accordo.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.3 <BoldText textDecor="underline">DOVERI DEL RISTORANTE E ORDINI</BoldText>
                    </Text>
                    <Text>
                        3.1. Il Ristorante riconosce e prende atto che, quando il Cliente effettua un Ordine tramite i
                        Canali d'Ordine, il Ristorante e il Cliente stipulano un contratto per la fornitura di beni e il
                        Ristorante è tenuto ad eseguire tale Ordine in conformità con i dettagli dell'Ordine che ha
                        ricevuto da SQUISEAT.
                    </Text>
                    <Text>
                        3.2. Il Ristorante si impegna a comunicare tempestivamente a mezzo mail (o mediante altra forma
                        di comunicazione) a SQUISEAT:
                        <Ul>
                            <Li>l'orario di apertura e chiusura dei Locali;</Li>
                            <Li>i prodotti giornalieri disponibili alla vendita e i relativi prezzi effettivi;</Li>
                            <Li>la fascia oraria giornaliera da comunicare ai Clienti per il ritiro dei prodotti.</Li>
                        </Ul>
                    </Text>
                    <Text>
                        3.3. Qualora il Ristorante non prepari e consegni un Ordine, prende atto di violare il contratto
                        in essere tra il Ristorante ed il Cliente e che SQUISEAT potrà intraprendere per conto del
                        Ristorante qualsiasi azione che ritenga opportuna (ivi incluso il rimborso al Cliente di
                        qualsiasi importo che SQUISEAT ha eventualmente ricevuto dallo stesso).
                    </Text>
                    <Text>
                        3.4. I beni che fanno parte di qualsiasi Ordine saranno forniti dal Ristorante a cui l'Ordine si
                        riferisce e preparati sotto l’esclusivo controllo del Ristorante e con la ragionevole cura e
                        competenza, e in ogni caso in conformità con il presente accordo.
                    </Text>
                    <Text>
                        3.5. Il Ristorante è tenuto a fornire a ciascun Cliente uno scontrino fiscale (e una fattura con
                        l'indicazione dell'IVA, se applicabile, laddove richiesto) in relazione a ciascun Ordine.
                    </Text>
                    <Text>
                        3.6. Il Ristorante è tenuto, se richiesto, a comunicare al cliente tutti i dettagli aggiornati
                        su qualsiasi allergene presente nei beni messi in vendita tramite i Canali d‘ Ordine. SQUISEAT
                        non sarà ritenuta responsabile per la verifica di tali informazioni per conto del Ristorante.
                    </Text>
                    <Text>
                        3.7. Il Ristorante è tenuto a garantire l’identità e la conformità dei prodotti comunicati
                        quotidianamente a SQUISEAT.
                    </Text>
                    <Text>
                        3.8. I prezzi e gli sconti in relazione ai beni offerti dal Ristorante attraverso i Canali
                        d'Ordine non dovranno essere meno favorevoli di quelli offerti ai Clienti tramite i canali
                        diretti del Ristorante. Per tutta la durata del presente accordo SQUISEAT è pertanto autorizzata
                        a ispezionare i Locali del Ristorante in modo da poter verificare il rispetto di quanto previsto
                        al presente punto.
                    </Text>
                    <Text>
                        3.9. Nel caso di formale reclamo inoltrato da un Cliente relativo a un qualsiasi aspetto di un
                        Ordine, e solo nel caso lo stesso sia di facile e pronta risoluzione, SQUISEAT provvederà
                        direttamente a fornire ove possibile una risoluzione al medesimo. Diversamente, nel caso di
                        reclami di non pronta risoluzione, fornirà i dettagli del reclamo al Ristorante il quale dovrà
                        rispondere tempestivamente al Cliente per garantire una pronta risoluzione dello stesso.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.4 <BoldText textDecor="underline">DOVERI DI SQUISEAT</BoldText>
                    </Text>
                    <Text>
                        4.1. SQUISEAT si impegna ad eseguire l’incarico conferito con correttezza, diligenza e senza
                        esclusiva, inserendo tempestivamente sui propri Canali d'Ordine i prodotti segnalati dal
                        Ristorante.
                    </Text>
                    <Text>
                        4.2. Per tutta la durata del presente accordo l’incarico affidato a SQUISEAT sarà limitato alla
                        sola organizzazione della vendita dei prodotti per conto del Ristorante nonché alla tempestiva
                        trasmissione degli Ordini ricevuti dai Clienti mediante i Canali d' Ordine.
                    </Text>
                    <Text>
                        4.3. SQUISEAT non provvede in alcun modo alla realizzazione, vendita, acquisto, conservazione,
                        preparazione, produzione, lavorazione, marcatura, imballaggio, consegna o gestione dei prodotti.
                        SQUISEAT non si assume nessuna responsabilità nei confronti dei Clienti per l’adempimento degli
                        obblighi contrattuali relativi ai prodotti, comprese la loro realizzazione, vendita, acquisto,
                        conservazione, preparazione, produzione, lavorazione, marcatura, consegna, qualità, ingredienti,
                        allergeni o manipolazione; parimenti, non si assume nessuna responsabilità in merito al rispetto
                        della normativa applicabile, anche relativa alle attività appena elencate.
                    </Text>
                    <Text>
                        4.4. SQUISEAT svolgerà l’incarico affidato liberamente e senza nessun vincolo di stabilità e
                        subordinazione nei confronti del Ristorante e avrà piena libertà di orario, di impiego del suo
                        tempo e scelta di organizzazione.
                    </Text>
                    <Text>
                        4.5. SQUISEAT si impegna altresì a rendere i Canali d'Ordine disponibili e funzionali allo scopo
                        di fornire il Servizio in ogni momento, ma dovrà ritenersi sollevata da qualsiasi responsabilità
                        in ordine ad eventuali malfunzionamenti dei medesimi.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.5 <BoldText textDecor="underline">COMMISSIONI E PAGAMENTI</BoldText>
                    </Text>
                    <Text>
                        5.1. Sugli affari conclusi, eseguiti e andati a buon fine, spetta a SQUISEAT una Commissione
                        specificatamente concordata in fase di acquisizione dati e mostrata all’interno
                        dell’applicazione, nel profilo del Ristorante.
                    </Text>
                    <Text>
                        5.2. Le Parti convengono espressamente che il momento di maturazione della Commissione sarà
                        quello del buon fine dell’affare e quindi del totale pagamento dei beni da parte del Cliente al
                        Ristorante.
                    </Text>
                    <Text>
                        5.3. Al termine di ogni Trimestre di competenza, SQUISEAT fornirà al Ristorante un rendiconto
                        relativo ai rapporti di debito/credito intercorrenti tra le Parti e concernente il Trimestre
                        Solare precedente. Il Rendiconto includerà:
                        <Ul>
                            <Li>
                                l'Importo Netto di tutti gli Ordini in forma aggregata ricevuti da SQUISEAT e trasmessi
                                al Ristorante affiliato per il relativo periodo di competenza,
                            </Li>
                            <Li>
                                gli importi dovuti a SQUISEAT per il relativo periodo di competenza: tali importi sono
                                relativi alle Commissioni maturate a seguito degli Ordini trasmessi al Ristorante e
                                andati a buon fine
                            </Li>
                        </Ul>
                    </Text>
                    <Text>
                        5.4. In caso di disaccordo con quanto illustrato all’interno del rendiconto trimestrale, il
                        Ristorante dovrà dare comunicazione scritta (a mezzo mail o altro mezzo di comunicazione
                        concordato tra le Parti) a SQUISEAT entro 14 giorni dalla consegna dello stesso illustrandone le
                        motivazioni. In caso non venga rilevata alcuna contestazione entro il predetto termine, il
                        rendiconto dovrà considerarsi accettato da parte del Ristorante.
                    </Text>
                    <Text>
                        5.5. Le Commissioni saranno pagate a SQUISEAT salvo diverso accordo intercorso tra le Parti,
                        dopo che l'importo dei beni sia stato totalmente incassato dal Ristorante e dopo emissione di
                        regolare fattura emessa da SQUISEAT - previa approvazione del rendiconto trimestrale- in
                        relazione al compenso maturato e nelle seguenti modalità:
                        <Ul>
                            <Li>o mediante bonifico bancario (IBAN: IT63S0306902478100000017888);</Li>
                            <Li>
                                o mediante trattenuta diretta di SQUISEAT dagli importi dovuti al Ristorante nel mese di
                                competenza.
                            </Li>
                        </Ul>
                    </Text>
                    <Text>
                        5.6. Le Commissioni verranno pagate dal Ristorante entro 14 (quattordici) giorni successivi
                        all’emissione della fattura da parte di SQUISEAT.
                    </Text>
                    <Text>
                        5.7. Gli importi dovuti al Ristorante saranno detenuti da SQUISEAT per conto del Ristorante in
                        un conto designato fino a che non sarà pagabile in favore del Ristorante ai sensi del seguente
                        punto 6.2.
                    </Text>
                    <Text>
                        5.8. La cessazione del presente contratto non interrompe le obbligazioni in corso; in
                        particolare il Ristorante riconoscerà a SQUISEAT la Commissione su tutti gli affari trasmessi o
                        comunque posti in essere nel corso del contratto anche se conclusi ed eseguiti dopo. SQUISEAT
                        pertanto ha diritto alla Commissione anche per gli affari conclusi in seguito ad ordini ricevuti
                        dopo la cessazione del presente contratto, qualora la conclusione dell’affare sia da ricondurre
                        prevalentemente all’attività svolta da SQUISEAT durante il periodo di vigenza del presente
                        contratto, e l’affare sia concluso entro un termine ragionevole dalla cessazione del contratto
                        stesso.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.6{" "}
                        <BoldText textDecor="underline">PAGAMENTI DA PARTE DEI CLIENTI - MANDATO ALL'INCASSO</BoldText>
                    </Text>
                    <Text>
                        6.1. Per tutta la durata del presente accordo Il Ristorante autorizza irrevocabilmente SQUISEAT,
                        ai sensi dell’art. 1723 secondo comma c.c, ad accettare, ricevere e detenere per suo conto gli
                        importi dei pagamenti effettuati dai Clienti con carta o altro strumento elettronico di
                        pagamento.
                    </Text>
                    <Text>
                        6.2. Qualsiasi importo detenuto da SQUISEAT per conto del Ristorante sarà a quest’ultimo
                        corrisposto alla fine del Trimestre Solare di competenza e prima della data di emissione del
                        successivo rendiconto di cui al punto 5.3.
                    </Text>
                    <Text>
                        6.3. Il pagamento di un Cliente relativo ad un Ordine effettuato tramite i Canali d’ Ordine di
                        SQUISEAT avrà effetto liberatorio di qualsiasi obbligo di pagamento nei confronti del Ristorante
                        da parte di quel Cliente per tali importi.
                    </Text>
                    <Text>
                        6.4. SQUISEAT si riserva il diritto di compensare in qualsiasi momento qualsiasi importo che
                        dovrà ricevere dal Ristorante con qualsiasi importo che dovrà invece pagare a quest’ultimo.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.7 <BoldText textDecor="underline">RITIRO DEI PRODOTTI</BoldText>
                    </Text>
                    <Text>
                        7.1. Una volta trasmesso l’ordine da parte di SQUISEAT, il Ristorante è tenuto a preparare i
                        beni richiesti e, nel caso in cui siano differenti da quelli mostrati in app acquisiti durante
                        la fase di registrazione, a comunicare prontamente il nuovo orario di ritiro dei prodotti presso
                        i propri Locali affinché SQUISEAT possa tempestivamente comunicare con i Clienti.
                    </Text>
                    <Text>
                        7.2. I beni ordinati dovranno essere ritirati dal Cliente all’indirizzo e all’orario indicati
                        dal Ristorante. I dettagli su quando e dove i prodotti potranno essere ritirati saranno indicati
                        da SQUISEAT al Cliente mediante i Canali d’ Ordine.
                    </Text>
                    <Text>
                        7.3. Il Ristorante si impegna a utilizzare confezioni proporzionate ed idonee alla consegna.
                    </Text>
                    <Text>
                        7.4. Il Ristorante si impegna a verificare che il numero d'ordine presentato dal cliente
                        corrisponda a quello comunicato da SQUISEAT.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.8 <BoldText textDecor="underline">DURATA E RISOLUZIONE</BoldText>
                    </Text>
                    <Text>
                        8.1. Il presente accordo ha la durata di dodici (12) mesi e produce i suoi effetti una volta
                        sottoscritto da entrambe le Parti.
                    </Text>
                    <Text>
                        8.2. Successivamente, il presente accordo si rinnoverà automaticamente per successivi dodici
                        (12) mesi, salvo disdetta di una delle Parti fatta pervenire all'altra almeno tre (3) mesi prima
                        della scadenza.
                    </Text>
                    <Text>
                        8.3. E’ data facoltà alle parti di recedere, liberamente ed incondizionatamente, dal presente
                        accordo in qualsiasi momento, con un preavviso scritto di 30 (trenta) giorni. Il recesso dovrà
                        essere esercitato con comunicazione mediante lettera raccomandata A.R. ed avrà effetto alla data
                        di ricezione della stessa.
                    </Text>
                    <Text>
                        8.4. Ai sensi dell'art. 1456 del Codice Civile, Le Parti hanno il diritto di risolvere il
                        presente contratto in qualsiasi momento, con effetto immediato e senza preavviso nei seguenti
                        casi:
                        <Ul>
                            <Li>
                                apertura di procedura fallimentare, concordato preventivo o altra procedura concorsuale
                                a carico di una delle Parti;
                            </Li>
                            <Li>
                                assoggettamento di una delle Parti a condanne penali per reati patrimoniali direttamente
                                connessi all’attività svolta.
                            </Li>
                        </Ul>
                    </Text>
                    <Text>
                        8.5. SQUISEAT si riserva inoltre il diritto di risolvere immediatamente il presente accordo con
                        semplice comunicazione scritta (anche a mezzo mail) in caso di violazione di una delle
                        disposizioni presenti nelle seguenti clausole: art. 3 (Doveri del Ristorante e Ordini), art. 5
                        (Commissioni e Pagamenti), art. 7 (Ritiro dei Prodotti), art. 11 (Riservatezza).
                    </Text>
                    <Text>
                        8.6. Allo scioglimento del presente accordo, SQUISEAT rimuoverà tempestivamente il profilo del
                        Ristorante dai Canali d’ Ordine. Ciascuna Parte, inoltre, cesserà prontamente di utilizzare i
                        diritti di proprietà intellettuale dell'altra Parte, sia online che offline (ad esempio,
                        rimozione di eventuali link al Sito Web che potrebbero esistere sul vostro sito web).
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.9 <BoldText textDecor="underline">LEGGE APPLICABILE E GIURISDIZIONE COMPETENTE</BoldText>
                    </Text>
                    <Text>9.1. Le Parti stabiliscono che il presente accordo sarà regolato dalla legge Italiana.</Text>
                    <Text>
                        9.2. Tutte le controversie nascenti dal presente contratto, comprese quelle inerenti la sua
                        esistenza, validità, estinzione, interpretazione, esecuzione e risoluzione, saranno sottoposte
                        ad un preliminare tentativo di mediazione bonario innanzi al Servizio di Mediazione della Camera
                        di Commercio di Bologna.
                    </Text>
                    <Text>
                        9.3. Qualora la mediazione abbia esito negativo, le eventuali controversie insorte saranno di
                        competenza esclusiva della Giurisdizione Italiana presso il Tribunale di Bologna.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.10 <BoldText textDecor="underline">FORZA MAGGIORE</BoldText>
                    </Text>
                    <Text>
                        10.1. Nessuna delle Parti sarà responsabile del proprio inadempimento in relazione alle
                        obbligazioni qui previste qualora sia in grado di provare:
                        <Ul>
                            <Li>che l'inadempimento è stato provocato da un evento al di fuori del suo controllo;</Li>
                            <Li>
                                che non era ragionevole aspettarsi che essa, al momento di sottoscrivere il presente
                                contratto, tenesse in considerazione la possibilità del verificarsi di tale evento e dei
                                suoi effetti sulla sua capacità di adempiere;
                            </Li>
                            <Li>
                                che non era ragionevolmente possibile evitare o porre rimedio a detto evento o
                                quantomeno ai suoi effetti. Per gli effetti di questa clausola, e senza che
                                l’elencazione qui offerta possa considerarsi esaustiva, un “Evento di Forza Maggiore”
                                sarà comprensivo di calamità naturali, incendi, inondazioni, guerre (dichiarate o non
                                dichiarate), insurrezioni civili, epidemie, sommosse, embarghi, sabotaggi, incidenti,
                                vertenze sindacali, scioperi, provvedimenti di qualsiasi autorità pubblica o
                                governativa, sia locali che nazionali, ivi incluse leggi, ordinanze, norme e
                                regolamenti, siano validi o non validi, e qualsiasi altra evenienza similare o
                                differente.
                            </Li>
                        </Ul>
                    </Text>
                    <Text>
                        10.2. Per l’intero periodo in cui l’Evento di Forza Maggiore, o i suoi effetti, permangano, la
                        parte Inadempiente non sarà considerata responsabile per la sua incapacità di eseguire le
                        proprie obbligazioni, la cui esecuzione è impedita dall’Evento di Forza Maggiore, fermo restando
                        che dette obbligazioni verranno adempiute non appena possibile dopo il venire meno dell’Evento
                        di Forza Maggiore.
                    </Text>
                    <Text>
                        10.3. A prescindere da quanto sopra statuito, qualora l'Evento di Forza Maggiore continui per un
                        periodo superiore a tre (3) mesi, la parte adempiente avrà la facoltà, a propria discrezione, di
                        risolvere il presente contratto senza ulteriori responsabilità per alcuna delle Parti.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.11 <BoldText textDecor="underline">RISERVATEZZA</BoldText>
                    </Text>
                    <Text>
                        11.1. Le Parti dovranno mantenere riservato il presente accordo ed il suo contenuto per tutta la
                        durata dello stesso e per i 10 (dieci) anni successivi.
                    </Text>
                    <Text>
                        11.2. È fatto divieto, a ciascuna delle Parti, di rivelare a qualsiasi terza parte e di
                        utilizzare per scopi differenti da quelli specificati nel presente accordo, anche
                        successivamente alla scadenza di quest’ultimo, i segreti commerciali dei quali tale parte possa
                        essere venuta a conoscenza durante l’esecuzione del presente accordo.
                    </Text>
                    <Text>
                        11.3. La presente clausola resterà valida e vincolante anche dopo la risoluzione del contratto e
                        sino a quando persista la natura confidenziale delle informazioni scambiate durante la sua
                        efficacia, informazioni che non siano divenute di dominio pubblico, per un periodo comunque non
                        inferiore a cinque anni.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        Art.12 <BoldText textDecor="underline">VARIE</BoldText>
                    </Text>
                    <Text>
                        12.1. Le Parti avranno cura di osservare tutte le disposizioni di legge anche fiscali e
                        tributarie dettate per la corretta esecuzione del presente contratto.
                    </Text>
                    <Text>
                        12.2. Eventuali modifiche al presente accordo dovranno risultare da atto scritto recante la
                        sottoscrizione delle Parti. Tutti i corrispettivi oggetto del presente accordo saranno soggetti
                        ad IVA di legge ove dovuta.
                    </Text>
                    <Text>
                        12.3. Il presente incarico non può essere ceduto a terzi. Qualsiasi tipo di modifica, aggiunta o
                        eliminazione delle clausole del presente contratto sarà valida ed effettiva solo se autorizzata
                        per iscritto dai rappresentanti di ciascuna delle Parti a ciò debitamente titolati ed
                        autorizzati.
                    </Text>
                    <Text>
                        12.4. Le Parti si autorizzano reciprocamente a trattare e comunicare a terzi i loro dati
                        personali in relazione ad adempimenti connessi al presente rapporto contrattuale (legge 31
                        dicembre 1996, n.675 e successive modificazioni).
                    </Text>
                    <Text>
                        12.5. La modalità di trattamento dei dati del Ristorante è descritta nella Informativa sulla
                        Privacy allegata al presente accordo e ne forma parte integrante e sostanziale (Allegato 2).
                    </Text>
                    <Text>
                        12.6. Nell'adempimento degli obblighi derivanti dal presente accordo, e nel ruolo di titolare
                        del trattamento dei dati, SQUISEAT potrà condividere con il Ristorante dati personali (ad
                        esempio: dettagli personali compresi titoli, nome, cognome, indirizzo e-mail, dettagli
                        dell'Ordine, indirizzo di consegna, numero di telefono). Il Ristorante potrà trattare tali dati
                        personali esclusivamente al fine di soddisfare gli Ordini. il Ristorante si impegna a garantire
                        di agire esclusivamente come responsabile del trattamento dei dati, rispettare in ogni momento
                        la legislazione applicabile in materia di protezione dei dati e a mantenere le misure
                        appropriate per assicurare la tutela dei diritti delle persone a cui i dati personali si
                        riferiscono. Il Ristorante si impegna a predisporre e mantenere misure tecniche e organizzative
                        adeguate al fine di garantire un livello di sicurezza adeguato ai rischi presentati dal
                        trattamento dei dati personali, e si impegna a non trasferire nessun dato personale al di fuori
                        dello SEE o di nominare un soggetto terzo per il trattamento dei dati senza il preventivo
                        consenso scritto di SQUISEAT. Il Ristorante si impegna a seguire tutte le istruzioni fornite da
                        SQUISEAT in relazione a tali dati, che potrebbero includere attività, quali la cancellazione o
                        la restituzione di dati, che permettono a SQUISEAT di rispettare i relativi obblighi ai sensi
                        delle leggi applicabili in materia di protezione dei dati. Il Ristorante non potrà trattare i
                        dati personali per le proprie finalità senza aver ricevuto il preventivo consenso scritto di
                        SQUISEAT. Il Ristorante si impegna altresì a comunicare tempestivamente eventuali violazioni
                        effettive o possibili del presente articolo, di cui venga a conoscenza. Il Ristorante sarà
                        tenuto ad indennizzare SQUISEAT per qualsiasi perdita, danno, controversia o pretesa da parte di
                        terzi da quest’ultima subita in conseguenza di qualsivoglia eventuale violazione del presente
                        art. 12.6. da parte del Ristorante.
                    </Text>
                    <Text>
                        12.7. Il presente accordo è sottoscritto mediante accettazione dei presenti Termini e Condizioni
                        operata in fase di registrazione al servizio.
                    </Text>
                    <Text>
                        12.8. Gli allegati qui di seguito elencati costituiscono parte integrante e sostanziale del
                        presente accordo:
                    </Text>
                    <Text m={{ l: "1rem" }}>Allegato 2: Informativa trattamento dati Ristorante</Text>
                    <Text>Letto, confermato e sottoscritto.</Text>
                    <Text m={{ t: "1rem" }}>
                        Ai fini e per gli effetti dell’art. 1341 c.c. e 1342 c.c., le parti dichiarano di approvare
                        espressamente le clausole da 1 a 12.
                    </Text>

                    <Text m={{ t: "3rem" }} textWeight="bold">
                        Allegato 2
                    </Text>
                    <Text m={{ t: "1rem" }} textWeight="bold" textDecor="underline" textAlign="center">
                        Informativa sul trattamento dei dati personali
                    </Text>
                    <Text>
                        Ai sensi dell’art. 13 del d.lgs. n. 196/2003 (di seguito “Codice”) e dell’art. 13 del
                        Regolamento UE n. 2016/679 (di seguito “GDPR 2016/679”), recante disposizioni a tutela delle
                        persone e di altri soggetti rispetto al trattamento dei dati personali, la società SQUISEAT SRL
                        (di seguito “Titolare”) , con sede legale in Bologna (BO), alla Via San Petronio Vecchio 48/2 -
                        CAP 40125 -, nella sua veste di “Titolare del trattamento”, La informa che i Suoi dati personali
                        raccolti relativamente alla prestazione dei servizi specificatamente indicati all’interno dei
                        Termini e Condizioni Generali e saranno trattati nel rispetto della normativa sopra citata, al
                        fine di garantire i diritti, le libertà fondamentali, nonché la dignità delle persone fisiche,
                        con particolare riferimento alla riservatezza e all’identità personale. La informiamo che
                        qualora le attività a lei prestate prevedano il trattamento di dati personali di terzi nella sua
                        titolarità sarà sua responsabilità assicurare di aver adempiuto a quanto previsto dalla
                        normativa nei riguardi dei soggetti interessati al fine di rendere legittimo il loro
                        trattamento.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        1) Origine, finalità e natura dei dati trattati
                    </Text>
                    <Text>
                        Il trattamento dei Suoi dati personali, da Lei direttamente forniti, è effettuato dal Titolare
                        per l’assolvimento dell’incarico ricevuto e specificatamente descritto all’interno dei Termini e
                        Condizioni Generali ed ha ad oggetto:
                        <Ul>
                            <Li>
                                Dati anagrafici (denominazione, ragione sociale, indirizzo, recapiti per comunicazioni,
                                coordinate bancarie ecc.) necessari alla tenuta della contabilità ed alla intestazione
                                ed emissione delle fatture relative Commissioni maturate da SQUISEAT SRL;
                            </Li>
                            <Li>
                                recapiti del Ristorante e dati relativi a forme di comunicazione anche interattive
                                utilizzabili per lo scambio di informazioni con la Clientela quali indirizzi e-mail,
                                numerazioni telefoniche di rete fissa e mobile, utenze per strumenti di comunicazione
                                via PC, tablet, smartphone che impiegano una connessione dati o internet; Tali dati sono
                                trattati dal SQUISEAT SRL, nel rispetto di quanto indicato dal dal Codice e dal GDPR
                                nonché alla luce Autorizzazione Generale n. 4/2016, della Autorizzazione Generale n.
                                7/2016, al fine esclusivo di adempiere le previsioni nell’incarico ricevuto.
                            </Li>
                        </Ul>
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        2) Comunicazione
                    </Text>
                    <Text>
                        E’ prevista la comunicazione dei Suoi dati personali, effettuate in base agli obblighi previsti
                        dalla legge, per dar seguito alle attività da Lei richieste ovvero connaturate all’incarico
                        ricevuto.
                    </Text>
                    <Text>
                        In relazione a tali scopi, i suoi dati potranno essere comunicati, ricorrendone l’esigenza, ad
                        altre persone fisiche come gli avventori dei Canali d’Ordine di SQUISEAT SRL.
                    </Text>
                    <Text>
                        In riferimento agli aspetti di protezione dei dati personali il Cliente è invitato, ai sensi
                        dell’art. 33 del GDPR a segnalare a SQUISEAT SRL eventuali circostanze o eventi dai quali possa
                        discendere una potenziale “violazione dei dati personali (data breach)” al fine di consentire
                        una immediata valutazione e l’adozione di eventuali azioni volte a contrastare tale evento
                        inviando una comunicazione al Titolare all’indirizzo mail: privacy@squiseat.it.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        3) Trasferimento all’estero
                    </Text>
                    <Text>
                        I Suoi dati personali non verranno trasferiti a persone fisiche o giuridiche site all’estero.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        4) Modalità, logiche del trattamento e tempi di conservazione
                    </Text>
                    <Text>
                        I suoi dati sono raccolti e registrati in modo lecito e secondo correttezza per le finalità
                        sopra indicate nel rispetto dei principi e delle prescrizioni di cui all’art. 11 del Codice e 5
                        c 1 del GDPR.
                    </Text>
                    <Text>
                        Il trattamento dei dati personali avviene mediante strumenti manuali, informatici e telematici
                        con logiche strettamente correlate alle finalità stesse e, comunque, in modo da garantirne la
                        sicurezza e la riservatezza e nel rispetto di quanto previsto dall’art. 32 del GDPR 2016/679 e
                        dall'Allegato B del D.lgs 196/2003 (artt. 33-36 del Codice)
                    </Text>
                    <Text>
                        Nello specifico:
                        <Ul>
                            <Li>
                                i dati anagrafici del Cliente e i dati fiscali di fatturazione saranno conservati in un
                                server di archiviazione elettronico direttamente gestito dal Titolare.
                            </Li>
                            <Li>
                                I dati personali oggetto di trattamento sono custoditi e controllati in modo da ridurre
                                al minimo, mediante l'adozione di idonee e preventive misure di sicurezza, i rischi di
                                distruzione o perdita, anche accidentale, dei dati stessi, di accesso non autorizzato o
                                di trattamento non consentito o non conforme alle finalità della raccolta gli stessi. A
                                tale proposito i dati conferiti saranno protetti con password aggiornate periodicamente
                                e custodite in luogo sicuro secondo i termini di legge.
                            </Li>
                            <Li>
                                I dati personali verranno trattati dal Titolare per tutta la durata dell’incarico
                                assunto.
                            </Li>
                        </Ul>
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        5) Esistenza di un processo decisionale automatizzato, compresa la profilazione
                    </Text>
                    <Text>
                        Il Titolare non adotta alcun processo decisionale automatizzato, compresa la profilazione, di
                        cui all’articolo 22, paragrafi 1 e 4, del Regolamento UE n. 679/2016.
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        6) Diritti dell’interessato
                    </Text>
                    <Text>
                        In ogni momento, Lei potrà esercitare, ai sensi dell’art. 7 del D.Lgs. 196/2003 e degli articoli
                        dal 15 al 22 del Regolamento UE n. 2016/679, il diritto di:
                        <Ul>
                            <Li>chiedere la conferma dell’esistenza o meno di propri dati personali;</Li>
                            <Li>
                                ottenere le indicazioni circa le finalità del trattamento, le categorie dei dati
                                personali, i destinatari o le categorie di destinatari a cui i dati personali sono stati
                                o saranno comunicati e, quando possibile, il periodo di conservazione;
                            </Li>
                            <Li>ottenere la rettifica e la cancellazione dei dati;</Li>
                            <Li>ottenere la limitazione del trattamento;</Li>
                            <Li>
                                ottenere la portabilità dei dati, ossia riceverli da un titolare del trattamento, in un
                                formato strutturato, di uso comune e leggibile da dispositivo automatico, e trasmetterli
                                ad un altro titolare del trattamento senza impedimenti;
                            </Li>
                            <Li>
                                opporsi al trattamento in qualsiasi momento ed anche nel caso di trattamento per
                                finalità di marketing diretto;
                            </Li>
                            <Li>
                                opporsi ad un processo decisionale automatizzato relativo alle persone ﬁsiche, compresa
                                la profilazione.{" "}
                            </Li>
                            <Li>
                                chiedere al titolare del trattamento l’accesso ai dati personali e la rettifica o la
                                cancellazione degli stessi o la limitazione del trattamento che lo riguardano o di
                                opporsi al loro trattamento, oltre al diritto alla portabilità dei dati;
                            </Li>
                            <Li>
                                revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento
                                basata sul consenso prestato prima della revoca;
                            </Li>
                            <Li>proporre reclamo a un’autorità di controllo.</Li>
                            <Text>
                                Può esercitare i Suoi diritti con richiesta scritta inviata a SQUISEAT SRL, Via San
                                Petronio Vecchio 48/2 (CAP 40125) o all’indirizzo mail: privacy@squiseat.it.
                            </Text>
                        </Ul>
                    </Text>

                    <Text m={{ t: "1rem" }} textWeight="bold">
                        7) Titolare del trattamento
                    </Text>
                    <Text m={{ b: "3rem" }}>
                        Titolare del trattamento, ai sensi dell’art. 4 del Codice e del GDPR, è SQUISEAT S.R.L. con sede
                        legale in Bologna (BO), alla Via San Petronio Vecchio 48/2 (CAP 40125), Codice fiscale e n.iscr.
                        al Registro Imprese 03825131208.
                    </Text>
                    <hr style={{ border: "1px solid #E1E4E8" }} />

                    <Text textAlign="center">ESPRESSIONE DEL CONSENSO</Text>
                    <Text>
                        Il/La legale rappresentante del Ristorante prende atto della informativa resa ai sensi dell’art.
                        Ai sensi dell’art. 13 del d.lgs. n. 196/2003 e degli artt. 13-14 del Regolamento UE n. 2016/679
                        e accorda liberamente e volontariamente, ove richiesto, il consenso per le finalità indicate a
                        che i propri dati personali possano essere trattati ed essere oggetto di comunicazione ai
                        soggetti per gli adempimenti connessi all’incarico conferito-
                    </Text>
                </Div>
            </Div>
            <Div m={{ t: "1rem" }}>
                <Button
                    onClick={props.closeModal}
                    bg="gunmetal"
                    shadow="3"
                    h="3.5rem"
                    m="auto"
                    p={{ x: "1.5rem" }}
                    rounded="20px"
                    textColor="white"
                >
                    <Text textSize="title">Ok!</Text>
                </Button>
            </Div>
        </>
    );
}

export default TermsAndConditionsModal;
