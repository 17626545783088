import { Plugins, FilesystemDirectory } from "@capacitor/core";
import moment from "moment";
import config, { cacheFolder } from "../settings/config";
import firebaseService, { getProductImageFromFirebase } from "./firebase.service";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
const { Filesystem, Storage, Device } = Plugins;

export const canHaveCashback = (supplierData) => {
    const today = moment();
    const supplierRegistrationDay = moment(supplierData?.registeredOnAppAt);
    const lastCashbackDay = moment("2021-06-01");

    return supplierRegistrationDay.isBefore(lastCashbackDay) && today.diff(supplierRegistrationDay, "months") < 6;
};

export const saveImageToFilesystem = async (url, path, fileName) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const base64Data = await convertBlobToBase64(blob);

    const savedFile = await Filesystem.writeFile({
        path: `${cacheFolder}/${path}/${fileName}.jpg`,
        data: base64Data,
        directory: FilesystemDirectory.Cache,
        recursive: true,
    });
    return savedFile;
};

export const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
};

export const getImage = (path, imageID, setPhoto) => {
    imageID &&
        Filesystem.readFile({
            directory: FilesystemDirectory.Cache,
            path: `/${cacheFolder}/${path}/${imageID}.jpg`,
        })
            .then(async (readFile) => {
                let fileProps = await Filesystem.stat({
                    directory: FilesystemDirectory.Cache,
                    path: `/${cacheFolder}/${path}/${imageID}.jpg`,
                });
                if (moment().diff(moment.unix(fileProps.ctime / 1000), "days") > 30) {
                    await Filesystem.deleteFile({
                        directory: FilesystemDirectory.Cache,
                        path: `/${cacheFolder}/${path}/${imageID}.jpg`,
                    });
                    throw "old image";
                } else setPhoto(`data:image/jpg;base64,${readFile.data}`);
            })
            .catch(async () => {
                getProductImageFromFirebase(imageID)
                    .then((image) => {
                        if (image) {
                            saveImageToFilesystem(image, path, imageID).catch((error) =>
                                console.error("getImage > saveImageToFilesystem", error)
                            );
                            setPhoto(image);
                        }
                    })
                    .catch((error) => console.info(`Error get ${path}/${imageID}.jpg image:`, error));
            });
};

export const roundValue = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};

export const saveToStorage = async (key, value) => {
    await Storage.set({
        key: key,
        value: JSON.stringify(value),
    });
};

export const loadFromStorage = async (key) => {
    const ret = await Storage.get({ key: key });
    const value = JSON.parse(ret.value);
    return value;
};

export const getRemoteValue = (key) => {
    return firebaseService.remoteConfig.getValue(key)._value;
};

export const getDeviceInfo = async () => {
    return Device.getInfo()
        .then((deviceInfo) => {
            return deviceInfo;
        })
        .catch((error) => console.warn(error));
};

export const initSentry = async () => {
    config["isDeploy"] &&
        Sentry.init({
            dsn: config.sentry.dns,
            autoSessionTracking: true,
            integrations: [
                new Integrations.BrowserTracing(),
                new CaptureConsole({
                    levels: ["error", "info", "warn"],
                }),
            ],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,
        });
};

export const getDate = (date) => {
    const dateformat = new Date(date);
    const today = moment();
    const tomorrow = moment().add(1, "days");
    if (moment(date).isSame(today, "day"))
        return (
            "Oggi, " +
            dateformat.toLocaleString("it-IT", {
                hour: "2-digit",
                minute: "2-digit",
            })
        );
    else if (moment(date).isSame(tomorrow, "day"))
        return (
            "Domani, " +
            dateformat.toLocaleString("it-IT", {
                hour: "2-digit",
                minute: "2-digit",
            })
        );
    else
        return dateformat.toLocaleString("it-IT", {
            weekday: "short",
            month: "numeric",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });
};

export const timeIsValid = (hour) => {
    return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(hour);
};

export const dateIsValid = (date) => {
    return moment(date).isSameOrAfter(moment(), "day");
};

export const getDayNameFromNum = (num) => {
    switch (num) {
        case 0:
            return "Monday";
        case 1:
            return "Tuesday";
        case 2:
            return "Wednesday";
        case 3:
            return "Thursday";
        case 4:
            return "Friday";
        case 5:
            return "Saturday";
        case 6:
            return "Sunday";
    }
};

export const getItaDayNameFromEng = (day) => {
    switch (day) {
        case "Monday":
            return "Lun";
        case "Tuesday":
            return "Mar";
        case "Wednesday":
            return "Mer";
        case "Thursday":
            return "Gio";
        case "Friday":
            return "Ven";
        case "Saturday":
            return "Sab";
        case "Sunday":
            return "Dom";
    }
};
