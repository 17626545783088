import { Button, Col, Div, Row, Text } from "atomize";
import { AnimateSharedLayout, motion } from "framer-motion";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import theme from "../../appearance/theme";
import { productsContext } from "../../providers/products.provider";

//Padre: ProductsFilter
function ProductsType(props) {
    const [onlineProdCount, setOnlineProdCount] = useState(0);
    const { mergedProdProps, handleProductsTypeChange } = useContext(productsContext);

    useEffect(() => {
        mergedProdProps?.online && countOnlineProducts();
    }, [mergedProdProps]);

    const countOnlineProducts = () => {
        let count = 0;
        Object.keys(mergedProdProps.online).forEach((category) => {
            mergedProdProps.online[category].forEach((prod) => {
                count++;
            });
        });
        onlineProdCount !== count && setOnlineProdCount(count);
    };

    return (
        <AnimateSharedLayout>
            <Row p={{ x: "1rem", y: "0" }} m="0" border={{ b: "2px solid" }} borderColor="#E1E4E8" bg="primary_ulight">
                <Col p="0">
                    <Button bg="transparent" rounded="circle" w="100%" onClick={() => handleProductsTypeChange(false)}>
                        <Text
                            p={{ x: "0", t: "0.5rem" }}
                            textSize="subheader"
                            textColor="dark"
                            textWeight={!props.productsType && "bold"}
                            borderColor={!props.productsType && "primary"}
                        >
                            TUTTI
                        </Text>
                    </Button>
                    {!props.productsType && (
                        <motion.div layoutId="pill" transition={{ type: "spring", stiffness: 500, damping: 30 }}>
                            <Div
                                pos="relative"
                                w="4rem"
                                h="0.5rem"
                                m="auto"
                                rounded="t-20px"
                                bg={theme.colors.gunmetal}
                                transition
                            />
                        </motion.div>
                    )}
                </Col>
                <Col p="0">
                    <Button bg="transparent" rounded="circle" w="100%" onClick={() => handleProductsTypeChange(true)}>
                        <>
                            <Div
                                pos="relative"
                                p={{ x: "0", t: "0.5rem" }}
                                textSize="subheader"
                                textColor="dark"
                                textWeight={props.productsType && "bold"}
                                borderColor={props.productsType && "primary"}
                            >
                                IN VENDITA
                                {onlineProdCount > 0 && (
                                    <Div
                                        pos="absolute"
                                        top="0.25rem"
                                        right="-1.5rem"
                                        d="flex"
                                        justify="center"
                                        align="center"
                                        w="1.5rem"
                                        h="1.5rem"
                                        bg="primary"
                                        rounded="circle"
                                        textColor="white"
                                    >
                                        {onlineProdCount}
                                    </Div>
                                )}
                            </Div>
                        </>
                    </Button>
                    {props.productsType && (
                        <motion.div layoutId="pill" transition={{ type: "spring", stiffness: 500, damping: 30 }}>
                            <Div
                                pos="relative"
                                w="4rem"
                                h="0.5rem"
                                m="auto"
                                rounded="t-20px"
                                bg={theme.colors.gunmetal}
                                transition
                            />
                        </motion.div>
                    )}
                </Col>
            </Row>
        </AnimateSharedLayout>
    );
}

export default ProductsType;
