var theme = {
    colors: {
        primary: "#E83D56",
        primary_lighter: "#EA4961",
        primary_light: "#ED6579",
        primary_ulight: "#EFF8F7",
        accent: "#52B3A1",
        accent_light: "#8FCEC3",
        gunmetal: "#224552",
        gunmetal_lighter: "#224552",
        gunmetal_light: "#75B0C5",
        gray_light: "#E9E9E9",
        text_light: "#727272",
        primary_blur: "rgba(232,61,86, 0.2)",
        accent_blur: "rgba(82, 179, 161, 0.3)",
        gunmetal_blur: "rgba(34,69,82, 0.4)",
        gray_blur: "rgba(114, 114, 114, 0.1)",
        white: "#FFFFFF",
        error: "#ff0033",
        warning: "#EC7D10",
        yellow: "#FFFD82",
        green: "#23825F",
    },
    fontFamily: {
        primary: "Poppins, sans-serif",
    },
    shadows: {
        "up-3": "0px -1px 1px -1px rgba(8, 11, 14, 0.06), 0 -6px 6px -1px rgba(8, 11, 14, 0.1)",
        "top-3":
            "rgba(8, 11, 14, 0.06) 0px -1px 1px -1px, rgba(8, 11, 14, 0.1) 0px -4px 6px -1px, rgba(8, 11, 14, 0.1) 0px 4px 6px -1px",
        accent: " 0px 3px 20px 0px rgba(82,179,161,0.15)",
    },
    rounded: {
        upOnly: "12px 12px 0 0",
        "20px": "20px",
        "25px": "25px",
        "15px": "15px",
        "20pxLeft": "20px 0 0 20px",
        "t-20px": "20px 20px 0 0",
        "pill-15px": "0 15px 0 15px",
        "pillAlt-20px": "20px 0 40px 20px",
        "pillAltRev-20px": "40px 20px 20px 0",
    },
    transition: {
        fast: "all 0.2s ease-in-out",
    },
};

document.body.style.backgroundColor = theme.colors.primary_ulight;

module.exports = theme;
