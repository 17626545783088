import { Div, Text } from "atomize";
import NumberFormat from "react-number-format";
import React, { useContext } from "react";
import { productsContext } from "../../providers/products.provider";
import { modalContext } from "../../providers/modal.provider";
import { ProductImage } from "../product/Product";
import ProductTags from "../productTags/ProductTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import theme from "../../appearance/theme";
import { authContext } from "../../providers/auth.provider";

//Padre: PendingProductsBanner
function PendingProduct(props) {
    const { setProductInfoSelected, productsEntries } = useContext(productsContext);
    const { supplierData } = useContext(authContext);
    const Modal = useContext(modalContext);

    return (
        <Div
            p={{ l: "0rem", b: "0.2rem" }}
            zIndex="0"
            pos="relative"
            onClick={() => {
                let unconfirmedProductInfo = Object.assign({}, props.productInfo, {
                    unconfirmed: {
                        quantity: props.productInfo.quantity,
                        soldAt: props.productInfo.soldAt,
                        priceID: props.productInfo.priceID,
                    },
                });
                delete unconfirmedProductInfo.quantity;
                delete unconfirmedProductInfo.soldAt;
                delete unconfirmedProductInfo.priceID;
                //Per eguagliare l'oggetto passato come selected allo stesso oggetto che viene passato selected quando si seleziona un prodotto dalla lista prodotti

                setProductInfoSelected(unconfirmedProductInfo);
                Modal.open("prodQuantity", null, null, "availabilitiesExpiration");
            }}
        >
            <Div d="flex" align="center" p={{ b: "0.25rem" }}>
                {supplierData.firestore?.needsProdInternalID ? (
                    <Div
                        pos="relative"
                        w="4rem"
                        h="4rem"
                        bg="primary"
                        rounded="20px"
                        m={{ r: "0.5rem" }}
                        d="flex"
                        align="center"
                        justify="center"
                        textAlign="center"
                    >
                        <Text
                            textWeight="bold"
                            textSize="subheader"
                            style={{
                                lineHeight: "18px",
                            }}
                            textColor="white"
                        >
                            {props.productInfo?.quantity}
                            <br />
                            <Text tag="span" textSize="caption" textWeight="normal">
                                {props.productInfo?.quantity > 1 ? "porzioni" : "porzione"}
                            </Text>
                        </Text>
                    </Div>
                ) : (
                    <ProductImage productInfo={props.productInfo} />
                )}
                <Div style={{ flexBasis: "85%" }}>
                    <Text textSize="subheader" textColor="dark" style={{ lineHeight: "25px" }}>
                        {props.productInfo.productName}
                    </Text>
                    <Div d="flex" justify="space-between" align="center">
                        {"fullPrice" in props.productInfo && (
                            <NumberFormat
                                value={props.productInfo.fullPrice}
                                displayType={"text"}
                                decimalSeparator={","}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"€"}
                                renderText={(fullPrice) => (
                                    <NumberFormat
                                        value={props.productInfo.price}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={"€"}
                                        renderText={(price) => (
                                            <Text textSize="subheader">
                                                <Text
                                                    tag="span"
                                                    textColor={
                                                        props.productInfo.status === undefined ||
                                                        !(
                                                            "fresh" in props.productInfo &&
                                                            props.productInfo.fresh.available > 0
                                                        )
                                                            ? "gray"
                                                            : "accent"
                                                    }
                                                >
                                                    {fullPrice}
                                                </Text>
                                                {props.productInfo?.quantity > 0 && " | "}
                                                <Text tag="span" textColor="primary">
                                                    {props.productInfo?.quantity > 0 && !props.type && price}
                                                </Text>
                                            </Text>
                                        )}
                                    />
                                )}
                            />
                        )}
                        {supplierData.firestore?.needsProdInternalID ? (
                            productsEntries.internalID[props.productInfo.productID] && (
                                <Text
                                    textSize="paragraph"
                                    textColor="dark"
                                    bg="gunmetal_light"
                                    rounded="circle"
                                    p={{ x: "0.5rem" }}
                                    textAlign="right"
                                >
                                    {productsEntries.internalID[props.productInfo.productID]}
                                </Text>
                            )
                        ) : (
                            <ProductTags
                                key={"button-" + props.productInfo.productID}
                                productInfo={props.productInfo}
                                m="0"
                            />
                        )}
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}

export default PendingProduct;
