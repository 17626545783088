import { Div, Text, Button, Col, Row } from "atomize";
import React, { useContext } from "react";
import Ul from "../atoms/Ul";
import Li from "../atoms/Li";
import BoldText from "../boldText/BoldText";
import UndoToast from "../toast/UndoToast";
import { toast } from "react-toastify";
import ApiService from "../../services/api.service";
import { authContext } from "../../providers/auth.provider";

function DeleteAccountModal(props) {
    const { setLog } = useContext(authContext);
    let undoToast = false;
    const setUndoState = (val) => {
        undoToast = val;
    };

    const sendMessage = () => {
        ApiService.sendMessageToCustomerGroup(null)
            .then((response) => {
                if (ApiService.checkRedirectLogin(response.request.responseURL)) setLog(false);
            })
            .catch((error) => {
                console.error("[API] Send message to customer group ", error);
                toast.error("Sembra esserci stato un problema con la richiesta, RIPROVA!");
            });
    };

    return (
        <>
            <Div p={{ x: "1rem", t: "0.5rem" }}>
                <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="title" textWeight="bold">
                    Vuoi davvero andartene?
                </Text>
                <Div p={{ t: "0.5rem", l: "0.5rem" }}>
                    <Text textSize="subheader">
                        Ci dispiacerebbe un sacco, ma nel caso tu sia convinto puoi premere il bottone qua sotto.
                    </Text>
                    <BoldText textSize="subheader">Sei sicuro?</BoldText>
                </Div>
            </Div>
            <Row m={{ x: "2rem", t: "0.5rem" }}>
                <Col p="0">
                    <Button onClick={() => props.closeModal()} bg="white" h="3.5rem" m="auto" textColor="gunmetal">
                        <Text textSize="title">Annulla</Text>
                    </Button>
                </Col>
                <Col p="0">
                    <Button
                        onClick={() => {
                            toast(
                                <UndoToast
                                    text="Ok, ti contatteremo a breve!"
                                    undoAction={() => {
                                        setUndoState(true);
                                        toast.dismiss();
                                    }}
                                />,
                                {
                                    hideProgressBar: false,
                                    onClose: () => {
                                        !undoToast && sendMessage();
                                        setUndoState(false);
                                    },
                                }
                            );
                            props.closeModal();
                        }}
                        bg="gunmetal"
                        shadow="3"
                        h="3.5rem"
                        m="auto"
                        p={{ x: "1.5rem" }}
                        rounded="20px"
                        textColor="white"
                    >
                        <Text textSize="title">Sicuro</Text>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default DeleteAccountModal;
