import { Plugins, CameraResultType } from "@capacitor/core";
import { useState } from "react";
const { Camera } = Plugins;

export const usePhotoGallery = () => {
    const [photo, setPhotos] = useState(null);

    const takePhoto = async () => {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            quality: 100,
            promptLabelHeader: "",
            promptLabelCancel: "Annulla",
            promptLabelPhoto: "Ho già una foto, la cerco...",
            promptLabelPicture: "Scatto una nuova foto!",
            correctOrientation: false,
        });
        const fileName = new Date().getTime() + ".jpeg";
        const newPhoto = {
            filepath: fileName,
            webviewPath: photo.webPath,
        };
        setPhotos(newPhoto);
    };

    const resetPhoto = () => {
        setPhotos(null);
    };

    return {
        takePhoto,
        resetPhoto,
        photo: photo,
    };
};

export const getFileBlob = (path, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", path);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
        console.log(xhr.response);
        const url = URL.createObjectURL(xhr.response);
        console.log(url);
        callback(xhr.response);
    });
    xhr.send();
};
