import { Button, Col, Div, Row, Text } from "atomize";
import { AnimateSharedLayout, motion } from "framer-motion";
import React, { useContext } from "react";
import theme from "../../appearance/theme";
import { ordersContext } from "../../providers/orders.provider";

function OrdersFilter(props) {
    const { ordersType, setOrdersType } = useContext(ordersContext);
    return (
        <AnimateSharedLayout>
            <Row p={{ x: "1rem", y: "0" }} m="0" border={{ b: "2px solid" }} borderColor="#E1E4E8" bg="primary_ulight">
                <Col p="0">
                    <Button bg="transparent" rounded="circle" w="100%" onClick={() => setOrdersType(false)}>
                        <Text
                            p={{ x: "0", t: "0.5rem" }}
                            textSize="subheader"
                            textColor="dark"
                            textWeight={!ordersType && "bold"}
                            borderColor={!ordersType && "primary"}
                        >
                            ATTIVI
                        </Text>
                    </Button>
                    {!ordersType && (
                        <motion.div layoutId="pill" transition={{ type: "spring", stiffness: 500, damping: 30 }}>
                            <Div
                                pos="relative"
                                w="4rem"
                                h="0.5rem"
                                m="auto"
                                rounded="t-20px"
                                bg={theme.colors.gunmetal}
                                transition
                            />
                        </motion.div>
                    )}
                </Col>
                <Col p="0">
                    <Button bg="transparent" rounded="circle" w="100%" onClick={() => setOrdersType(true)}>
                        <Text
                            p={{ x: "0", t: "0.5rem" }}
                            textSize="subheader"
                            textColor="dark"
                            textWeight={ordersType && "bold"}
                            borderColor={ordersType && "primary"}
                        >
                            RITIRATI
                        </Text>
                    </Button>
                    {ordersType && (
                        <motion.div layoutId="pill" transition={{ type: "spring", stiffness: 500, damping: 30 }}>
                            <Div
                                pos="relative"
                                w="4rem"
                                h="0.5rem"
                                m="auto"
                                rounded="t-20px"
                                bg={theme.colors.gunmetal}
                                transition
                            />
                        </motion.div>
                    )}
                </Col>
            </Row>
        </AnimateSharedLayout>
    );
}

export default OrdersFilter;
