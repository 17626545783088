var is_deploy = true;
var type = "pwa";

var base_url_dashboard = is_deploy
    ? "https://store.squiseat.com"
    : "https://dev-store.squiseat.com/";
var base_Api_url_dashboard = is_deploy
    ? "https://squiseat.com/SquisEatWeb"
    : "https://dev.squiseat.com/SquisEatWeb";

const chatID_Costumer_locali = -1001337249874;

const axiosHeaders = {
    baseURL: base_Api_url_dashboard,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
};

var firebaseConfigAppLocali = {
    apiKey: "AIzaSyAwGQh8BStJEoJp3RZwP1RIMMtRFjzP5BQ",
    authDomain: "dashboard-ristoratori-deploy.firebaseapp.com",
    databaseURL: "https://dashboard-ristoratori-deploy.firebaseio.com",
    projectId: "dashboard-ristoratori-deploy",
    storageBucket: "dashboard-ristoratori-deploy.appspot.com",
    messagingSenderId: "784040116191",
    appId: "1:784040116191:web:b585e86201ae73aea00e6a",
    measurementId: "G-0YNZ4Q2C75",
};

var firebaseConfigAppClienti = {
    apiKey: "AIzaSyA_PxcA-qo_rVN2y3gwilO9d_u-7lrDFfM",
    authDomain: "app-clienti-deploy.firebaseapp.com",
    databaseURL: "https://app-clienti-deploy.firebaseio.com",
    projectId: "app-clienti-deploy",
    storageBucket: "app-clienti-deploy.appspot.com",
    messagingSenderId: "582237867011",
    appId: "1:582237867011:web:592a30b3c5c658f1fed236",
    measurementId: "G-NQWLBHPGVX",
};

var sentry = {
    dns: "https://d7105e8c8430433f9603cfd1ba5d79b3@o504322.ingest.sentry.io/5592906",
};

const CACHE_FOLDER = "CACHED_IMG";

var config = {
    isDeploy: is_deploy,
    ApiUrl: base_Api_url_dashboard,
    HomeUrl: base_url_dashboard,
    axiosHeaders: axiosHeaders,
    firebaseConfig: {
        firebaseConfigAppLocali: firebaseConfigAppLocali,
        firebaseConfigAppClienti: firebaseConfigAppClienti,
    },
    chatIDCostumer: chatID_Costumer_locali,
    type: type,
    sentry: sentry,
    cacheFolder: CACHE_FOLDER,
};

module.exports = config;
