import { Button, Div, Text } from "atomize";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import React, { useContext, useEffect, useState } from "react";
import theme from "../../appearance/theme";
import { modalContext } from "../../providers/modal.provider";
import { productsContext } from "../../providers/products.provider";
import BoldText from "../boldText/BoldText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

function PendingProductsBannerHeader(props) {
    const [count, setCount] = useState(null);
    const Modal = useContext(modalContext);
    const { mergedProdProps } = useContext(productsContext);

    useEffect(() => {
        let newCount = 0;
        mergedProdProps?.unconfirmed &&
            Object.keys(mergedProdProps?.unconfirmed).map(
                (category) => (newCount += mergedProdProps?.unconfirmed[category].length)
            );
        setCount(newCount);
    }, [props]);

    return (
        <Div
            zIndex="3"
            w="100%"
            d="flex"
            justify="center"
            align="center"
            h="4rem"
            bg="primary"
            rounded="20px"
            shadow="top-3"
            onClick={() => Modal.open("availabilitiesExpiration")}
        >
            <FontAwesomeIcon icon={faCalendarCheck} size="2x" color={theme.colors.white} />
            <Text p={{ l: "0.5rem" }} textColor="white" textSize="title" textAlign="center">
                Pubblica:{" "}
                <BoldText>
                    {count} {count === 1 ? "prodotto" : "prodotti"}
                </BoldText>
            </Text>
        </Div>
    );
}

export default PendingProductsBannerHeader;
