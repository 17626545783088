import { Div, Text, Button, Row, Col, Container } from "atomize";
import React, { useState, useEffect, useContext } from "react";
import NumberFormat from "react-number-format";
import ProductTags from "../productTags/ProductTags";
import { canHaveCashback, getDate } from "../../services/func.utils";
import theme from "../../appearance/theme";
import { authContext } from "../../providers/auth.provider";
import { productsContext } from "../../providers/products.provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faChevronDown,
    faChevronUp,
    faExclamationCircle,
    faMinus,
    faPen,
    faPlus,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { modalContext } from "../../providers/modal.provider";
import { ProductImage } from "../product/Product";
import moment from "moment";
import { toast } from "react-toastify";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import BoldText from "../boldText/BoldText";
import InputRange from "react-input-range";
import Card from "../atoms/Card";
import DatePicker from "react-datepicker";
import { sendMessageToCustomerGroup } from "../../services/api.service";

function NewProductQuantityModal(props) {
    const [quantity, setQuantity] = useState(0);
    const [startQuantity, setStartQuantity] = useState(0);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [coinAnimation, setCoinAnimation] = useState({
        negative: false,
        positive: false,
    });
    const [productDiscount, setProductDiscount] = useState(50);
    const [startProductDiscount, setStartProductDiscount] = useState(50);
    const [actionShown, setActionShowState] = useState(false);
    const [step, setStep] = useState(0);

    const {
        supplierData,
        expiringDate,
        setExpiringDate,
        expirations,
        expiration,
        loadingExpirations,
        getNextExpiration,
        setExpiration,
    } = useContext(authContext);
    const { editFunctions, productInfoSelected: productInfo, productsEntries } = useContext(productsContext);
    const Modal = useContext(modalContext);

    useEffect(() => {
        if (props.isOpen) manageSurplusQuantityAndDiscount();
        else {
            const timeout = setTimeout(function () {
                manageSurplusQuantityAndDiscount();
            }, 400);
            clearTimeout(timeout);
        }
    }, [props.isOpen]);

    useEffect(() => {
        getNextExpiration();
    }, [expiringDate]);

    useEffect(() => {
        quantity === 0 && !productInfo?.surplus && !productInfo?.unconfirmed
            ? setButtonDisable(true)
            : setButtonDisable(false);
    }, [quantity]);

    let tagsProductInfo = null;

    productInfo &&
        (tagsProductInfo = Object.assign({}, productInfo, {
            unconfirmed: {
                quantity: quantity,
                soldAt: 1 - productDiscount / 100,
            },
            status: "unconfirmed",
        }));
    productInfo?.surplus &&
        (tagsProductInfo = Object.assign({}, productInfo, {
            surplus: {
                available: quantity,
                soldAt: 1 - productDiscount / 100,
            },
            status: "online",
        }));

    const handleEditFunctions = () => {
        if (startQuantity !== quantity || startProductDiscount !== productDiscount) {
            setButtonDisable(true);
            if (productInfo?.surplus) {
                if (startQuantity !== quantity)
                    editFunctions.editAvailability(productInfo, { quantity: quantity }, "surplus");
                if (startProductDiscount !== productDiscount) {
                    /*editFunctions.editAvailability(productInfo, { soldAt: 1 - productDiscount / 100 }, "surplus");
                    edit = true;*/
                    editFunctions.expireAvailability(productInfo?.surplus?.availabilityID).then(() => {
                        editFunctions
                            .addUnconfirmed(productInfo, quantity, 1 - productDiscount / 100)
                            .then(() => {
                                sendMessageToCustomerGroup(
                                    null,
                                    `${supplierData.supplierName} ha provato a cambiare il discount di ${
                                        productInfo.productName
                                    } a ${1 - productDiscount / 100}, quantity=${quantity} con scadenza ${
                                        productInfo?.surplus?.expiringDate
                                    }, ${productInfo?.surplus?.expiringTime}`
                                ).catch((error) => console.error(error));
                                const d = new Date();
                                const zoneOffset = -d.getTimezoneOffset() / 60;
                                const date = new Date(
                                    Date.parse(
                                        [productInfo?.surplus?.expiringDate, productInfo?.surplus?.expiringTime].join(
                                            "T"
                                        ) +
                                            "+0" +
                                            zoneOffset +
                                            ":00"
                                    )
                                );
                                const expirationDate = date.toLocaleString("es-CL", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                });
                                const expirationTime = date.toLocaleString("it-IT", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                                editFunctions.publishAvailability(expirationDate, expirationTime).catch((error) => {
                                    console.error(error);
                                    sendMessageToCustomerGroup(
                                        null,
                                        `${supplierData.supplierName} ha provato a cambiare il discount di ${
                                            productInfo.productName
                                        } a ${1 - productDiscount / 100}, quantity=${quantity} con scadenza ${
                                            productInfo?.surplus?.expiringDate
                                        }, ${productInfo?.surplus?.expiringTime}`
                                    );
                                });
                            })
                            .catch((error) => {
                                console.error(error);
                                sendMessageToCustomerGroup(
                                    null,
                                    `${supplierData.supplierName} ha provato a cambiare il discount di ${
                                        productInfo.productName
                                    } a ${1 - productDiscount / 100}, quantity=${quantity} con scadenza ${
                                        productInfo?.surplus?.expiringDate
                                    }, ${productInfo?.surplus?.expiringTime}`
                                ).catch((error) => console.error(error));
                            });
                    });
                }
            } else {
                editFunctions.addUnconfirmed(productInfo, quantity, 1 - productDiscount / 100).then(async () => {
                    const d = new Date();
                    const zoneOffset = -d.getTimezoneOffset() / 60;
                    const date = new Date(
                        Date.parse(
                            expirations[expiringDate][expiration].expiringDateTime.replace(" ", "T") +
                                "+0" +
                                zoneOffset +
                                ":00"
                        )
                    );
                    const expirationDate = date.toLocaleString("es-CL", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    });
                    const expirationTime = date.toLocaleString("it-IT", {
                        hour: "2-digit",
                        minute: "2-digit",
                    });
                    editFunctions
                        .publishAvailability(expirationDate, expirationTime)
                        .then(() => props.closeModal())
                        .catch((error) => {
                            editFunctions.deleteUnconfirmed(productInfo);
                            toast.error("Non sono riuscito a pubblicare il prodotto, riprova!");
                            console.error(error);
                        });
                });
            }
            setStartQuantity(quantity);
            props.closeModal();
        } else props.closeModal();
    };

    const manageSurplusQuantityAndDiscount = () => {
        if (productInfo?.surplus || productInfo?.unconfirmed) {
            if (productInfo?.surplus) {
                setQuantity(productInfo.surplus.available);
                setStartQuantity(productInfo.surplus.available);
                setProductDiscount(100 - productInfo?.surplus?.soldAt * 100);
                setStartProductDiscount(100 - productInfo?.surplus?.soldAt * 100);
            } else if (productInfo?.unconfirmed) {
                setQuantity(productInfo.unconfirmed.quantity);
                setStartQuantity(productInfo.unconfirmed.quantity);
                setProductDiscount(100 - productInfo?.unconfirmed?.soldAt * 100);
                setStartProductDiscount(100 - productInfo?.surplus?.soldAt * 100);
            }
        } else {
            setQuantity(0);
            setStartQuantity(0);
            setProductDiscount(50);
            setStartProductDiscount(50);
        }
    };

    const getFormattedDate = (date) => {
        const d = new Date();
        const zoneOffset = -d.getTimezoneOffset() / 60;
        const formattedDate = new Date(Date.parse(date.replace(" ", "T") + "+0" + zoneOffset + ":00"));
        const today = moment();
        const tomorrow = moment().add(1, "days");
        if (moment(date).isSame(today, "day"))
            return (
                "Oggi, alle " +
                formattedDate.toLocaleString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        else if (moment(date).isSame(tomorrow, "day"))
            return (
                "Domani, alle " +
                formattedDate.toLocaleString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        else
            return (
                "alle " +
                formattedDate.toLocaleString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
    };

    const getQuantityButtons = () => {
        return (
            <>
                <Text p={{ t: "0.25rem" }} textAlign="center" textSize="display1" textWeight="bold">
                    {productInfo?.surplus ? "IN VENDITA" : "VENDI"}
                </Text>
                <Text m={{ y: "0.5rem" }} textAlign="center" textWeight="bold" textSize="subheader">
                    {productInfo?.surplus ? "Porzioni:" : "Quante porzioni?"}
                </Text>
                <Div d="flex" align="center" w="fit-content" m={{ x: "auto", b: "1rem" }}>
                    <Button
                        bg={quantity === 0 ? "light" : productDiscount === 50 ? "primary" : "accent"}
                        h="3rem"
                        w="3rem"
                        m={{ x: "1rem" }}
                        rounded="circle"
                        textColor="white"
                        onClick={() => {
                            if (quantity > 0) {
                                setQuantity(quantity - 1);
                                canHaveCashback(supplierData) &&
                                    setCoinAnimation({
                                        ...coinAnimation,
                                        negative: true,
                                    });
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faMinus} size="2x" color="white" />
                    </Button>
                    <Text textSize="display1" m={{ x: "1rem" }}>
                        {quantity}
                    </Text>
                    <Button
                        pos="relative"
                        zIndex="0"
                        bg={productDiscount === 50 ? "primary" : "accent"}
                        h="3rem"
                        w="3rem"
                        m={{ x: "1rem" }}
                        rounded="circle"
                        textColor="white"
                        onClick={() => {
                            setQuantity(quantity + 1);
                            canHaveCashback(supplierData) &&
                                setCoinAnimation({
                                    ...coinAnimation,
                                    positive: true,
                                });
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} size="2x" color="white" />
                    </Button>
                </Div>

                {supplierData.firestore?.sellsOverstock && (
                    <>
                        <Text m={{ b: "0.25rem" }} textAlign="center" textSize="subheader">
                            sconto:{" "}
                            <BoldText textColor={productDiscount === 50 ? "primary" : "accent"}>
                                {productDiscount >= 50 ? "RIMANENZA" : "OUTLET"}
                            </BoldText>
                        </Text>
                        <Div
                            m={{ t: "0.5rem", b: "4rem", x: "3rem" }}
                            className={productDiscount >= 50 ? "surplus" : "overstock"}
                        >
                            <InputRange
                                minValue={20}
                                maxValue={50}
                                step={10}
                                value={productDiscount}
                                formatLabel={(discount) => `${discount}%`}
                                onChange={(newDiscount) => setProductDiscount(newDiscount)}
                            />
                        </Div>
                    </>
                )}

                {productInfo?.surplus && (
                    <Text m={{ t: "1rem", b: "0.25rem" }} textAlign="center" textSize="subheader">
                        Fino a <BoldText>{getDate(productInfo.surplus.timestamp)}</BoldText>
                    </Text>
                )}
            </>
        );
    };

    return (
        productInfo !== null && (
            <>
                <>
                    <Div p={{ x: "1rem" }}>
                        <Div d="flex" align="center" p={{ b: "0.25rem" }}>
                            <ProductImage productInfo={productInfo} />
                            <Div style={{ flexBasis: "85%" }}>
                                <Text textSize="subheader" textColor="dark" style={{ lineHeight: "25px" }}>
                                    {productInfo.productName}
                                </Text>
                                <Div d="flex" align="center" justify="space-between">
                                    <Div>
                                        {"fullPrice" in productInfo && (
                                            <NumberFormat
                                                value={productInfo.fullPrice}
                                                displayType={"text"}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                prefix={"€"}
                                                renderText={(fullPrice) => (
                                                    <NumberFormat
                                                        value={productInfo.fullPrice * (1 - productDiscount / 100)}
                                                        displayType={"text"}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        prefix={"€"}
                                                        renderText={(price) => (
                                                            <Text textSize="subheader">
                                                                <Text
                                                                    tag="span"
                                                                    textColor={
                                                                        productInfo.status === undefined ||
                                                                        !(
                                                                            "fresh" in productInfo &&
                                                                            productInfo.fresh.available > 0
                                                                        )
                                                                            ? "gray"
                                                                            : "accent"
                                                                    }
                                                                >
                                                                    {fullPrice}
                                                                </Text>
                                                                {(productInfo.surplus ||
                                                                    productInfo.unconfirmed ||
                                                                    quantity > 0) &&
                                                                    !props.type &&
                                                                    " | "}
                                                                <Text
                                                                    tag="span"
                                                                    textColor={
                                                                        productDiscount >= 50 ? "primary" : "accent"
                                                                    }
                                                                >
                                                                    {(productInfo.surplus ||
                                                                        productInfo.unconfirmed ||
                                                                        quantity > 0) &&
                                                                        !props.type &&
                                                                        price}
                                                                </Text>
                                                            </Text>
                                                        )}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Div>
                                    {productsEntries?.internalID[productInfo.productID] && (
                                        <Text
                                            textSize="paragraph"
                                            textColor="dark"
                                            bg="gunmetal_light"
                                            rounded="circle"
                                            p={{ x: "0.5rem" }}
                                            textAlign="right"
                                        >
                                            {productsEntries?.internalID[productInfo.productID]}
                                        </Text>
                                    )}
                                    {!productsEntries?.internalID[productInfo.productID] && quantity === 0 && (
                                        <Col>
                                            <Text
                                                textSize="paragraph"
                                                textColor="gray"
                                                d="flex"
                                                justify="flex-end"
                                                align="center"
                                                onClick={() => setActionShowState(!actionShown)}
                                            >
                                                {actionShown ? "NASCONDI" : "MODIFICA"}
                                                <FontAwesomeIcon
                                                    style={{ paddingLeft: "0.25rem" }}
                                                    icon={actionShown ? faChevronUp : faChevronDown}
                                                    size="1x"
                                                    color="gray"
                                                />
                                            </Text>
                                        </Col>
                                    )}
                                </Div>
                            </Div>
                        </Div>
                        <Row align="center">
                            <Col size="7">
                                <ProductTags
                                    key={"button-" + productInfo.productID}
                                    productInfo={tagsProductInfo || productInfo}
                                    soldAt={1 - productDiscount / 100}
                                />
                            </Col>
                            {(productsEntries?.internalID[productInfo.productID] || quantity > 0) && (
                                <Col>
                                    <Text
                                        textSize="paragraph"
                                        textColor="gray"
                                        d="flex"
                                        justify="flex-end"
                                        align="center"
                                        onClick={() => setActionShowState(!actionShown)}
                                    >
                                        {actionShown ? "NASCONDI" : "MODIFICA"}
                                        <FontAwesomeIcon
                                            style={{ paddingLeft: "0.25rem" }}
                                            icon={actionShown ? faChevronUp : faChevronDown}
                                            size="1x"
                                            color="gray"
                                        />
                                    </Text>
                                </Col>
                            )}
                        </Row>
                    </Div>
                    {actionShown && (
                        <Div d="flex" align="center" justify="center" p={{ t: "0.25rem" }}>
                            <Div
                                w="fit-content"
                                m={{ x: "0.5rem" }}
                                bg="warning800"
                                rounded="circle"
                                textColor="white"
                                p={{ x: "0.75rem", y: "0.25rem" }}
                                onClick={() => Modal.open("editProd")}
                            >
                                <FontAwesomeIcon icon={faPen} size="1x" color="white" /> Modifica
                            </Div>
                            {!productInfo.unconfirmed && !productInfo.surplus && (
                                <Div
                                    w="fit-content"
                                    m={{ x: "0.5rem" }}
                                    bg="danger900"
                                    rounded="circle"
                                    textColor="white"
                                    p={{ x: "0.75rem", y: "0.25rem" }}
                                    onClick={() => Modal.open("archiveProd")}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} size="1x" color="white" /> Elimina
                                </Div>
                            )}
                        </Div>
                    )}
                    <hr style={{ border: "1px solid #E1E4E8" }} />
                    <Container>
                        <>
                            {step === 0 && getQuantityButtons()}
                            {step > 0 && (
                                <Div textAlign="center">
                                    <Text p={{ y: "0.5rem" }} textAlign="center" textWeight="bold" textSize="subheader">
                                        Fino a quando?
                                    </Text>
                                    <DatePicker
                                        className="datepicker"
                                        locale="it"
                                        minDate={new Date()}
                                        selected={new Date(expiringDate)}
                                        popperPlacement="top"
                                        onChange={(date) => {
                                            setExpiringDate(moment(date).format("YYYY-MM-DD"));
                                        }}
                                        customInput={
                                            <Div
                                                d="flex"
                                                justify="center"
                                                align="center"
                                                p={{ x: "1.5rem", y: "1rem" }}
                                                w="fit-content"
                                                m="auto"
                                                bg="gray_light"
                                                rounded="20px"
                                            >
                                                <Text textSize="title" m={{ r: "1rem" }}>
                                                    {moment(expiringDate).format("DD/MM/YYYY")}
                                                </Text>
                                                <FontAwesomeIcon
                                                    icon={faCalendarAlt}
                                                    size="2x"
                                                    color={theme.colors.gunmetal}
                                                />
                                            </Div>
                                        }
                                    />
                                    <Div p={{ b: "1rem" }}>
                                        {!(expirations === null) && (
                                            <Row align="center">
                                                <Col size="2">
                                                    <Button
                                                        bg="transparent"
                                                        h="3rem"
                                                        w="3rem"
                                                        rounded="circle"
                                                        textColor={expiration > 0 ? "primary" : "light"}
                                                        onClick={() => {
                                                            expiration > 0 && setExpiration(expiration - 1);
                                                        }}
                                                    >
                                                        {expiration > 0 && <ArrowBackIosRoundedIcon fontSize="large" />}
                                                    </Button>
                                                </Col>
                                                <Col size="8" textAlign="center">
                                                    <Text p={{ t: "0.25rem" }} textSize="title">
                                                        {loadingExpirations
                                                            ? "..."
                                                            : expirations[expiringDate] &&
                                                              expirations[expiringDate][expiration]
                                                            ? getFormattedDate(
                                                                  expirations[expiringDate][expiration].expiringDateTime
                                                              )
                                                            : "Nessuna fascia di ritiro disponibile"}
                                                    </Text>
                                                </Col>
                                                <Col size="2">
                                                    <Button
                                                        bg="transparent"
                                                        h="3rem"
                                                        w="3rem"
                                                        rounded="circle"
                                                        textColor={
                                                            expirations[expiringDate] &&
                                                            expirations[expiringDate][expiration + 1]
                                                                ? "primary"
                                                                : "light"
                                                        }
                                                        onClick={() =>
                                                            expirations[expiringDate] &&
                                                            expirations[expiringDate][expiration + 1] &&
                                                            setExpiration(expiration + 1)
                                                        }
                                                    >
                                                        {expirations[expiringDate] &&
                                                            expirations[expiringDate][expiration + 1] && (
                                                                <ArrowForwardIosRoundedIcon fontSize="large" />
                                                            )}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                        {supplierData?.supplierOpenHour?.confirmed === false && (
                                            <Card bg={theme.colors.warning} m={{ t: "1rem" }} textAlign="center">
                                                <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
                                                <Text textAlign="left">
                                                    Questi orari sono precedenti alla tua richiesta di modifica! Attendi
                                                    la conferma da parte del team Squiseat se vuoi utilizzare gli orari
                                                    che hai richiesto
                                                </Text>
                                            </Card>
                                        )}
                                    </Div>
                                </Div>
                            )}
                        </>
                    </Container>
                </>
                <Row m={{ t: "0.5rem", x: "2rem" }}>
                    <Col p="0">
                        <Button
                            onClick={() => (step === 1 ? setStep(0) : props.closeModal())}
                            bg="white"
                            h="3.5rem"
                            m="auto"
                            textColor="gunmetal"
                        >
                            <Text textSize="title">Indietro</Text>
                        </Button>
                    </Col>
                    <Col p="0">
                        <Button
                            onClick={() => {
                                step === 0 && !productInfo?.surplus ? setStep(1) : handleEditFunctions();
                            }}
                            disabled={
                                buttonDisable ||
                                loadingExpirations ||
                                !expirations[expiringDate] ||
                                !expirations[expiringDate][expiration]
                            }
                            bg="gunmetal"
                            shadow="3"
                            h="3.5rem"
                            p={{ x: "2rem" }}
                            rounded="20px"
                            textColor="white"
                            m={{ x: "auto" }}
                        >
                            <Text textSize="title">{step === 0 && !productInfo?.surplus ? "Avanti" : "OK"}</Text>
                        </Button>
                    </Col>
                </Row>
            </>
        )
    );
}

export default NewProductQuantityModal;
