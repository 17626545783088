import React, { useContext } from "react";
import { Div, Text, Row, Col, Button } from "atomize";
import { motion } from "framer-motion";
import { authContext } from "../../providers/auth.provider";
import { modalContext } from "../../providers/modal.provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import theme from "../../appearance/theme";

function SupplierOpenHourCard(props) {
    const { supplierData } = useContext(authContext);
    const Modal = useContext(modalContext);

    return (
        <>
            <Text textSize="title" textWeight="bold" textAlign="center" m={{ b: "1rem" }}>
                Orari di ritiro
            </Text>
            {supplierData?.supplierOpenHour?.days?.length > 0 ? (
                <>
                    {supplierData?.supplierOpenHour?.days.map((day, index) => (
                        <React.Fragment key={index}>
                            {supplierData?.supplierOpenHour?.hours[index] && (
                                <Row key={index}>
                                    <Col size="4">
                                        <Text textSize="subheader" textWeight="bold">
                                            {day}:
                                        </Text>
                                    </Col>
                                    <Col size="8">
                                        <Text textSize="subheader">{supplierData?.supplierOpenHour?.hours[index]}</Text>
                                    </Col>
                                </Row>
                            )}
                        </React.Fragment>
                    ))}
                    {supplierData?.supplierOpenHour?.confirmed === false && (
                        <Div d="flex" align="center" m={{ y: "1rem" }}>
                            <FontAwesomeIcon icon={faExclamationCircle} color={theme.colors.warning} size="2x" />
                            <Text m={{ l: "0.5rem" }}>Questi orari saranno confermati a breve!</Text>
                        </Div>
                    )}
                </>
            ) : (
                <Text textSize="paragraph">Nessun orario di ritiro impostato!</Text>
            )}
            <motion.div whileTap={{ scale: 0.9 }}>
                <Button
                    m={{ x: "auto", t: "1rem" }}
                    onClick={() => {
                        Modal.open("editOpenHour");
                        /*toast(
                            <UndoToast
                                text="Ok, ti contatteremo a breve!"
                                undoAction={() => {
                                    setUndoState(true);
                                    toast.dismiss();
                                }}
                            />,
                            {
                                hideProgressBar: false,
                                onClose: () => {
                                    !undoToast && props.sendMessage();
                                    setUndoState(false);
                                },
                            }
                        );*/
                    }}
                    h="auto"
                    minH="3.5rem"
                    bg="gunmetal"
                    textColor="white"
                    textSize="subheader"
                    rounded="20px"
                    border="0"
                    p={{ x: "1.5rem" }}
                    shadow="3"
                    hoverShadow="4"
                >
                    Vorrei cambiare questi orari!
                </Button>
            </motion.div>
        </>
    );
}

export default SupplierOpenHourCard;
