import { Div } from "atomize";
import React, { useContext } from "react";
import { authContext } from "../../providers/auth.provider";
import { ordersContext } from "../../providers/orders.provider";
import Orders from "../orders/Orders";
import OrdersFilter from "../ordersFilter/OrdersFilter";

function OrdersContainer(props) {
    const { ordersEntries, ordersType } = useContext(ordersContext);
    const { tab } = useContext(authContext);

    return (
        <>
            {tab === "orders" && (
                <Div p={{ t: "env(safe-area-inset-top" }}>
                    <OrdersFilter />
                    <Orders ordersList={ordersType ? ordersEntries.delivered.orders : ordersEntries.active} />
                </Div>
            )}
        </>
    );
}

export default OrdersContainer;
