import { Div, Text, Button, Row, Col } from "atomize";
import React, { useState, useEffect, useContext } from "react";
import { useAnimation } from "framer-motion";
import EarnInfoDiv from "../atoms/EarnInfoDiv";
import NumberFormat from "react-number-format";
import Coin from "../atoms/Coin";
import ProductTags from "../productTags/ProductTags";
import { canHaveCashback, getDate } from "../../services/func.utils";
import theme from "../../appearance/theme";
import { authContext } from "../../providers/auth.provider";
import { productsContext } from "../../providers/products.provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faMinus, faPen, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { modalContext } from "../../providers/modal.provider";
import { ProductImage } from "../product/Product";
import BoldText from "../boldText/BoldText";

function ProductQuantityModal(props) {
    const [quantity, setQuantity] = useState(0);
    const [startQuantity, setStartQuantity] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [coinAnimation, setCoinAnimation] = useState({
        negative: false,
        positive: false,
    });
    const [actionShown, setActionShowState] = useState(false);

    const { supplierData } = useContext(authContext);
    const { editFunctions, productInfoSelected: productInfo, productsEntries } = useContext(productsContext);
    const Modal = useContext(modalContext);

    let tagsProductInfo = null;

    productInfo &&
        (tagsProductInfo = Object.assign({}, productInfo, {
            unconfirmed: {
                quantity: quantity,
                soldAt: 0.5,
            },
            status: "unconfirmed",
        }));
    productInfo?.surplus &&
        (tagsProductInfo = Object.assign({}, productInfo, {
            surplus: {
                available: quantity,
                soldAt: productInfo.surplus?.soldAt,
            },
            status: "online",
        }));

    const controls = useAnimation();
    const negativeControls = useAnimation();

    const handleEditFunctions = (e) => {
        e.target.disabled = true;
        setButtonDisable(true);
        let edit = false;
        if (!(startQuantity === quantity)) {
            if (productInfo?.surplus) {
                editFunctions.editAvailability(productInfo, { quantity: quantity }, "surplus");
                edit = true;
            } else if (productInfo?.unconfirmed) {
                if (quantity > 0) {
                    editFunctions.editUnconfirmed(productInfo, { quantity: quantity });
                    edit = true;
                } else {
                    editFunctions.deleteUnconfirmed(productInfo);
                    edit = true;
                }
            }
            if (!productInfo.surplus && !edit) {
                editFunctions.addUnconfirmed(productInfo, quantity, 0.5);
            }
        }
        setStartQuantity(quantity);
        props.prev ? Modal.open(props.prev) : props.closeModal();
    };

    const manageSurplusQuantity = () => {
        if (productInfo?.surplus || productInfo?.unconfirmed) {
            if (productInfo?.surplus) {
                setQuantity(productInfo.surplus.available);
                setStartQuantity(productInfo.surplus.available);
            } else if (productInfo?.unconfirmed) {
                setQuantity(productInfo.unconfirmed.quantity);
                setStartQuantity(productInfo.unconfirmed.quantity);
            }
        } else {
            setQuantity(0);
            setStartQuantity(0);
        }
    };

    useEffect(() => {
        setButtonDisable(false);
        if (props.isOpen) manageSurplusQuantity();
        else {
            const timeout = setTimeout(function () {
                manageSurplusQuantity();
            }, 400);
            clearTimeout(timeout);
        }
    }, [props.isOpen]);

    return (
        productInfo !== null && (
            <>
                <>
                    <Div p={{ x: "1rem", t: "0.5rem" }}>
                        <Div d="flex" align="center" p={{ b: "0.25rem" }}>
                            <ProductImage productInfo={productInfo} />
                            <Div style={{ flexBasis: "85%" }}>
                                <Text textSize="subheader" textColor="dark" style={{ lineHeight: "25px" }}>
                                    {productInfo.productName}
                                </Text>
                                <Div d="flex" align="center" justify="space-between">
                                    <Div>
                                        {"fullPrice" in productInfo && (
                                            <NumberFormat
                                                value={productInfo.fullPrice}
                                                displayType={"text"}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                prefix={"€"}
                                                renderText={(fullPrice) => (
                                                    <NumberFormat
                                                        value={productInfo.price}
                                                        displayType={"text"}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        prefix={"€"}
                                                        renderText={(price) => (
                                                            <Text textSize="subheader">
                                                                <Text
                                                                    tag="span"
                                                                    textColor={
                                                                        productInfo.status === undefined ||
                                                                        !(
                                                                            "fresh" in productInfo &&
                                                                            productInfo.fresh.available > 0
                                                                        )
                                                                            ? "gray"
                                                                            : "accent"
                                                                    }
                                                                >
                                                                    {fullPrice}
                                                                </Text>
                                                                {(productInfo.surplus ||
                                                                    productInfo.unconfirmed ||
                                                                    quantity > 0) &&
                                                                    !props.type &&
                                                                    " | "}
                                                                <Text
                                                                    tag="span"
                                                                    textColor={
                                                                        tagsProductInfo.surplus?.soldAt <= 0.5 ||
                                                                        tagsProductInfo.unconfirmed?.soldAt <= 0.5
                                                                            ? "primary"
                                                                            : "accent"
                                                                    }
                                                                >
                                                                    {(productInfo.surplus ||
                                                                        productInfo.unconfirmed ||
                                                                        quantity > 0) &&
                                                                        !props.type &&
                                                                        price}
                                                                </Text>
                                                            </Text>
                                                        )}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Div>
                                    {productsEntries?.internalID[productInfo.productID] && (
                                        <Text
                                            textSize="paragraph"
                                            textColor="dark"
                                            bg="gunmetal_light"
                                            rounded="circle"
                                            p={{ x: "0.5rem" }}
                                            textAlign="right"
                                        >
                                            {productsEntries?.internalID[productInfo.productID]}
                                        </Text>
                                    )}
                                    {!productsEntries?.internalID[productInfo.productID] && quantity === 0 && (
                                        <Col>
                                            <Text
                                                textSize="paragraph"
                                                textColor="gray"
                                                d="flex"
                                                justify="flex-end"
                                                align="center"
                                                onClick={() => setActionShowState(!actionShown)}
                                            >
                                                {actionShown ? "NASCONDI" : "MODIFICA"}
                                                <FontAwesomeIcon
                                                    style={{ paddingLeft: "0.25rem" }}
                                                    icon={actionShown ? faChevronUp : faChevronDown}
                                                    size="1x"
                                                    color="gray"
                                                />
                                            </Text>
                                        </Col>
                                    )}
                                </Div>
                            </Div>
                        </Div>
                        <Row align="center">
                            <Col size="7">
                                <ProductTags
                                    key={"button-" + productInfo.productID}
                                    productInfo={tagsProductInfo || productInfo}
                                />
                            </Col>
                            {(productsEntries?.internalID[productInfo.productID] || quantity > 0) && (
                                <Col>
                                    <Text
                                        textSize="paragraph"
                                        textColor="gray"
                                        d="flex"
                                        justify="flex-end"
                                        align="center"
                                        onClick={() => setActionShowState(!actionShown)}
                                    >
                                        {actionShown ? "NASCONDI" : "MODIFICA"}
                                        <FontAwesomeIcon
                                            style={{ paddingLeft: "0.25rem" }}
                                            icon={actionShown ? faChevronUp : faChevronDown}
                                            size="1x"
                                            color="gray"
                                        />
                                    </Text>
                                </Col>
                            )}
                        </Row>
                    </Div>
                    {actionShown && (
                        <Div d="flex" align="center" justify="center" p={{ t: "0.25rem" }}>
                            <Div
                                w="fit-content"
                                m={{ x: "0.5rem" }}
                                bg="warning800"
                                rounded="circle"
                                textColor="white"
                                p={{ x: "0.75rem", y: "0.25rem" }}
                                onClick={() => Modal.open("editProd")}
                            >
                                <FontAwesomeIcon icon={faPen} size="1x" color="white" /> Modifica
                            </Div>
                            {!productInfo.unconfirmed && !productInfo.surplus && (
                                <Div
                                    w="fit-content"
                                    m={{ x: "0.5rem" }}
                                    bg="danger900"
                                    rounded="circle"
                                    textColor="white"
                                    p={{ x: "0.75rem", y: "0.25rem" }}
                                    onClick={() => Modal.open("archiveProd")}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} size="1x" color="white" /> Elimina
                                </Div>
                            )}
                        </Div>
                    )}
                    <hr style={{ border: "1px solid #E1E4E8" }} />
                    <Div p={{ x: "1rem" }}>
                        <Text p={{ t: "0.25rem" }} textAlign="center" textSize="display1" textWeight="bold">
                            {productInfo?.surplus ? "IN VENDITA" : "VENDI"}
                        </Text>
                        <Text
                            m={{ t: "0.5rem", b: "0.25rem" }}
                            textAlign="center"
                            textWeight="bold"
                            textSize="subheader"
                        >
                            {productInfo?.surplus ? "Porzioni:" : "Quante porzioni?"}
                        </Text>
                        <Div d="flex" justify="center" p={{ y: "1rem" }}>
                            <Row>
                                <Col>
                                    <Div pos="relative" top="-42px">
                                        <Coin
                                            type="negative"
                                            key={quantity}
                                            prodPrice={productInfo.price}
                                            controls={negativeControls}
                                            position="absolute"
                                            zIndex="0"
                                        />
                                    </Div>
                                    <Button
                                        bg={
                                            quantity === 0
                                                ? "light"
                                                : tagsProductInfo.surplus?.soldAt <= 0.5 ||
                                                  tagsProductInfo.unconfirmed?.soldAt <= 0.5
                                                ? "primary"
                                                : "accent"
                                        }
                                        h="3rem"
                                        w="3rem"
                                        rounded="circle"
                                        textColor="white"
                                        onClick={() => {
                                            if (quantity > 0) {
                                                setQuantity(quantity - 1);
                                                canHaveCashback(supplierData) &&
                                                    setCoinAnimation({
                                                        ...coinAnimation,
                                                        negative: true,
                                                    });
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMinus} size="2x" color="white" />
                                    </Button>
                                </Col>
                                <Col textAlign="center">
                                    <Text p={{ t: "0.25rem" }} w="2rem" textSize="display1">
                                        {quantity}
                                    </Text>
                                </Col>
                                <Col pos="relative">
                                    <Coin
                                        key={quantity}
                                        prodPrice={productInfo.price}
                                        controls={controls}
                                        position="absolute"
                                        zIndex="0"
                                    />
                                    <Button
                                        pos="relative"
                                        zIndex="0"
                                        bg={
                                            tagsProductInfo.surplus?.soldAt <= 0.5 ||
                                            tagsProductInfo.unconfirmed?.soldAt <= 0.5
                                                ? "primary"
                                                : "accent"
                                        }
                                        h="3rem"
                                        w="3rem"
                                        rounded="circle"
                                        textColor="white"
                                        onClick={() => {
                                            setQuantity(quantity + 1);
                                            canHaveCashback(supplierData) &&
                                                setCoinAnimation({
                                                    ...coinAnimation,
                                                    positive: true,
                                                });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} size="2x" color="white" />
                                    </Button>
                                </Col>
                            </Row>
                        </Div>
                        {quantity > 0 ? (
                            <NumberFormat
                                value={(productInfo.price * quantity) / 10}
                                displayType={"text"}
                                decimalSeparator={","}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"€"}
                                renderText={(earn) => {
                                    const text = (
                                        <Text tag="span" textWeight="bold">
                                            {earn}
                                        </Text>
                                    );
                                    return (
                                        <EarnInfoDiv
                                            bg="warning700"
                                            text={
                                                <>
                                                    <span>Vai, guadagnerai almeno </span>
                                                    {text}
                                                    <span>!</span>
                                                </>
                                            }
                                            m="0"
                                            supplierData={supplierData}
                                        />
                                    );
                                }}
                            />
                        ) : (
                            <EarnInfoDiv
                                bg="info700"
                                text="Aumenta le porzioni e guadagna!"
                                m="0"
                                supplierData={supplierData}
                            />
                        )}

                        {productInfo?.surplus && (
                            <Text m={{ t: "1rem", b: "0.25rem" }} textAlign="center" textSize="subheader">
                                Fino a <BoldText>{getDate(productInfo.surplus.timestamp)}</BoldText>
                            </Text>
                        )}
                    </Div>
                </>

                <Row m={{ t: "0.5rem", x: "2rem" }}>
                    <Col p="0">
                        <Button
                            onClick={() => (props.prev ? Modal.open(props.prev) : props.closeModal())}
                            bg="white"
                            h="3.5rem"
                            m="auto"
                            textColor="gunmetal"
                        >
                            <Text textSize="title">Indietro</Text>
                        </Button>
                    </Col>
                    <Col p="0">
                        <Button
                            onClick={handleEditFunctions}
                            disabled={buttonDisable}
                            bg="gunmetal"
                            shadow="3"
                            h="3.5rem"
                            p={{ x: "2rem" }}
                            rounded="20px"
                            textColor="white"
                            m={{ x: "auto" }}
                        >
                            <Text textSize="title">OK</Text>
                        </Button>
                    </Col>
                </Row>
            </>
        )
    );
}

export default ProductQuantityModal;
