import { Div } from "atomize";
import React from "react";

function IosNotchHeader(props) {
    return (
        <Div
            pos="fixed"
            w="100vw"
            zIndex="0"
            bg="primary_ulight"
            textColor="primary_ulight"
            h="env(safe-area-inset-top)"
            top="0"
            m={{ b: "env(safe-area-inset-top)" }}
        />
    );
}

export default IosNotchHeader;
