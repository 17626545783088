import React from "react";
import { motion } from "framer-motion";
import { Div, Text } from "atomize";
import NumberFormat from "react-number-format";

function Coin({ prodPrice, controls, zIndex, bottom, left, position, type }) {
    const style = {
        opacity: 0,
        position: position,
        transform: "translateY(0)",
        zIndex: zIndex,
        bottom: bottom,
        left: left,
    };

    return (
        <motion.div style={style} animate={controls}>
            <Div
                pos="absolute"
                zIndex="4"
                m="0.5rem"
                d="flex"
                align="center"
                justify="center"
                w="2rem"
                h="2rem"
                bg={(type === "negative" && "info700") || "warning600"}
                border="4px solid"
                borderColor={(type === "negative" && "info500") || "warning500"}
                textColor={(type === "negative" && "info400") || "warning400"}
                textWeight="bold"
                rounded="circle"
            >
                I
            </Div>
            <NumberFormat
                value={prodPrice / 10}
                displayType={"text"}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={(type === "negative" && "-") || "+"}
                suffix={"€"}
                renderText={(price) => (
                    <Text m={{ l: "2.75rem", t: "0.75rem" }}>{price}</Text>
                )}
            />
        </motion.div>
    );
}

export default Coin;
