import React, { useContext, useEffect, useState } from "react";
import { authContext } from "./auth.provider";
import apiService from "../services/api.service";
import { toast } from "react-toastify";
import moment from "moment";
import { Plugins, AppState } from "@capacitor/core";
import UndoToast from "../components/toast/UndoToast";
import { productsContext } from "./products.provider";
import { getOrdersReviews } from "../services/firebase.service";
const { App } = Plugins;

export const ordersContext = React.createContext();

const OrdersProvider = ({ children }) => {
    const [ordersType, setOrdersType] = useState(false);
    const [activeOrders, setActiveOrders] = useState(0);
    const [ordersActiveList, setOrdersActiveList] = useState(null);
    const [ordersDeliveredList, setOrdersDeliveredList] = useState({ orders: null, reviews: {}, page: 1 });
    const [pickTimeList, setPickTimeList] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const { logged, setLog, supplierData } = useContext(authContext);
    const { editFunctions, mergedProdProps } = useContext(productsContext);

    useEffect(() => {
        //handleNotifationOnClick();
        if (logged) {
            setHandlerApp();
            getOrdersActive();
        }
        /*return function cleanup () {// compontent unmount
            (sseOrders) && sseOrders.close()
        }*/
    }, [logged]);

    useEffect(() => {
        supplierData?.supplierID &&
            getOrdersReviews(supplierData.supplierID).then(
                (orderIDs) => orderIDs && setOrdersDeliveredList((prevState) => ({ ...prevState, reviews: orderIDs }))
            );
    }, [supplierData]);

    useEffect(() => {
        if (logged && ordersType && ordersDeliveredList.orders === null) getOrdersDelivered(1);
    }, [logged, ordersType]);

    useEffect(() => {
        !ordersType && ordersActiveList && getPickTimeList();
    }, [ordersActiveList, ordersDeliveredList]);

    const deleteOrderFromState = (orderID, deleteReason) => {
        //deleteReason !== 1
        ordersActiveList.forEach((order) => {
            order.orderCod === orderID &&
                order?.menu &&
                order.menu.forEach((product) => {
                    mergedProdProps.productsDisplayed[product.type].forEach(
                        (availability) =>
                            product.availabilityID === availability.surplus?.availabilityID &&
                            editFunctions.editAvailability(availability, { quantity: product.available }, "surplus")
                    );
                });
        });
        /*: mergedProdProps.categories.forEach(
                  (category) =>
                      category in mergedProdProps.productsDisplayed &&
                      mergedProdProps.productsDisplayed[category].forEach((product) => {
                          product.surplus && editFunctions.editAvailability(product, 0, "surplus");
                      })
              );*/
        setOrdersActiveList(ordersActiveList.filter((order) => order.orderCod !== orderID));
        setActiveOrders(ordersActiveList.length - 1);
    };

    /*useEffect(()=>{
        !(supplierData === null) && setSeeOrders(new EventSource(`${config.ApiUrl}/events?channel=supplierID_${supplierData.supplierID}` , { withCredentials : true } ))
    },[supplierData])*/

    /*useEffect(()=>{
        function updateOrders(e){
            (e.data) && addOrderToActive(JSON.parse(e.data))
        }
        (sseOrders) && sseOrders.addEventListener("newOrder",updateOrders);
        return function cleanup () {// compontent unmount
            (sseOrders) && sseOrders.removeEventListener("newOrder",updateOrders);
        }
    })*/

    const setHandlerApp = () => {
        App.addListener("appStateChange", (state: AppState) => {
            console.log("App state changed. Is active?", state.isActive);
            if (state.isActive) {
                getOrdersActive();
                //getOrdersDelivered();
            }
        });
    };

    const getOrdersActive = async () => {
        await apiService.api
            .get("/orders/", {
                params: { isPaid: 1, isDelivered: 0 },
            })
            .then((response) => {
                if (apiService.checkRedirectLogin(response.request.responseURL)) setLog(false);
                else {
                    setOrdersActiveList(response.data.reverse());
                    setActiveOrders(response.data?.length);
                }
            })
            .catch((error) => {
                console.error("[API] Get orders active ", error);
                toast.error("Ops, non trovo gli ordini attivi, Aggiorno la pagina!", {
                    hideProgressBar: false,
                    onClose: () => getOrdersActive(),
                });
            });
    };

    const getOrdersDelivered = async (page) => {
        page > 0 &&
            (await apiService.api
                .get("/orders/", {
                    params: { isDelivered: 1, limit_request: 10, order_desc: 1, page: page },
                })
                .then((response) => {
                    apiService.checkRedirectLogin(response.request.responseURL)
                        ? setLog(false)
                        : setOrdersDeliveredList((prevState) => ({
                              ...prevState,
                              orders:
                                  page === 1
                                      ? response?.data.reverse()
                                      : [...prevState.orders, ...response?.data.reverse()],
                              page: response?.data?.length < 10 ? -1 : page + 1,
                          }));
                })
                .catch((error) => {
                    console.error("[API] Get orders delivered", error);
                    toast.error("Ops, non trovo gli ordini ritirati, Aggiorno la pagina!", {
                        hideProgressBar: false,
                        onClose: () => getOrdersDelivered(1),
                    });
                }));
    };

    const setOrderDelivered = async (orderID) => {
        await apiService.api
            .post("/orders/" + orderID, {
                isDelivered: true,
            })
            .then((response) => {
                moveOrderToDelivered(orderID);
                //const list = data.map(x => { return (x.type) });
                //apiService.checkRedirectLogin(response.request.responseURL) ? setLog(false) : setOrdersDeliveredList(response.data);
            })
            .catch((error) => {
                console.error("[API] Set order delivered ", error);
                toast.error("Ops, non sono riuscito a segnare l'ordine come ritirato, Aggiorno la pagina!", {
                    hideProgressBar: false,
                    onClose: () => window.location.reload(false),
                });
            });
    };

    /*const insertToDelivered = (orderInfo) => {
        let tmpUnconfirmed = productsUnconfirmed;
        (newProductInfo["type"] in tmpUnconfirmed) ? (tmpUnconfirmed[newProductInfo["type"]].push(newProductInfo)) : (tmpUnconfirmed[newProductInfo["type"]] = [newProductInfo]);
        setProductsUnconfirmed({ ...tmpUnconfirmed });
    }*/

    const updateOrderActive = (newOrderInfo) => {
        let tmpActive = ordersActiveList;
        Object.keys(ordersActiveList).forEach((order, index) => {
            order.orderCod === newOrderInfo.orderCod && (tmpActive[index] = newOrderInfo);
        });
        setOrdersActiveList([...tmpActive]);
    };

    const moveOrderToDelivered = (orderID) => {
        const orderInfo = ordersActiveList.find((item) => item.orderCod === orderID);
        orderInfo["isDelivered"] = true;
        setOrdersActiveList((prevOrdersActiveList) => prevOrdersActiveList.filter((item) => item.orderCod !== orderID));
        setActiveOrders(ordersActiveList.length - 1);
        ordersDeliveredList.orders
            ? setOrdersDeliveredList((prevState) => ({ ...prevState, orders: [orderInfo, ...prevState.orders] }))
            : getOrdersDelivered(1);
    };

    const addOrderToActive = (orderData) => {
        const orderInfo = ordersActiveList && ordersActiveList.find((item) => item.orderCod === orderData.orderCod);
        ordersActiveList &&
            (orderInfo ? updateOrderActive(orderData) : setOrdersActiveList([orderData, ...ordersActiveList]));
    };

    const sendMessageOrder = (message, displayToast) => {
        let undoToast = false;
        displayToast
            ? toast(
                  <UndoToast
                      text="Ti contattiamo a breve!"
                      undoAction={() => {
                          undoToast = true;
                          toast.dismiss();
                      }}
                  />,
                  {
                      hideProgressBar: false,
                      onClose: () => {
                          !undoToast &&
                              apiService
                                  .sendMessageToCustomerGroup(message)
                                  .then((response) => {
                                      if (apiService.checkRedirectLogin(response.request.responseURL)) setLog(false);
                                  })
                                  .catch((error) => {
                                      console.error("[API] Send message orders ", error);
                                      toast.error("Sembra esserci stato un problema con l'inoltro della richiesta");
                                  });
                          undoToast = false;
                      },
                  }
              )
            : apiService
                  .sendMessageToCustomerGroup(message)
                  .then((response) => {
                      if (apiService.checkRedirectLogin(response.request.responseURL)) setLog(false);
                  })
                  .catch((error) => {
                      console.error("[API] Send message orders ", error);
                  });
    };

    const getPickTimeList = () => {
        let tmpListP = [];
        ordersEntries.active.forEach((order, index) => {
            let datum =
                order.deliveryInfo.deliveryHour &&
                order.deliveryInfo.deliveryHour.date &&
                Date.parse(order.deliveryInfo.deliveryHour.date);
            datum && !tmpListP.includes(datum) && tmpListP.push(datum);
        });
        tmpListP.sort();
        setPickTimeList(tmpListP);
    };

    const ordersEntries = {
        active: ordersActiveList,
        delivered: ordersDeliveredList,
    };

    const editOrdersFunctions = {
        setDelivered: setOrderDelivered,
        sendMessageOrder: sendMessageOrder,
    };

    const mergedOrdersProps = Object.assign({}, ordersEntries, {
        pickupTimes: pickTimeList,
    });

    return (
        <ordersContext.Provider
            value={{
                ordersEntries,
                mergedOrdersProps,
                editOrdersFunctions,
                ordersType,
                activeOrders,
                selectedOrder,
                ordersDeliveredList,
                setActiveOrders,
                setOrdersType,
                deleteOrderFromState,
                setSelectedOrder,
                getOrdersDelivered,
            }}
        >
            {children}
        </ordersContext.Provider>
    );
};

export default OrdersProvider;
