import React, { memo } from "react"
import { Label, Radiobox } from "atomize"

function RadioBox({ text, checked, handleChange }) {
    return (
        <Label align="center" textSize="paragraph" textColor="text" textWeight={checked && "bold"} m={{ b: "0.5rem" }}>
            <Radiobox
                onChange={handleChange}
                checked={checked}
                inactiveColor="gray"
                activeColor="gunmetal"
                size="2rem"
            />
            {text}
        </Label>
    )
}

export default memo(RadioBox)
